<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <!-- 구분명 수정과함께 전표정보 용어도 수정 -->
        <!-- <strong class="tit_text">인프라정산서 전표정보</strong> -->
        <strong class="tit_text">전표정보</strong>
      </div>
      <div class="pos_right row_pack">
        <!-- <button type="button" class="btn_medium btn_secondary"><span class="ico_account ico_download_w" @click="downloadTemplate"></span>템플릿 다운로드</button> -->
        <!-- <a class="btn_medium btn_secondary" href="/assets/files/Template.xlsx" download>
           <span class="ico_account ico_download_w"></span>템플릿 다운로드
        </a> -->
        <!-- <button type="button" class="btn_medium btn_secondary" @click="$emit('uploadExcel')"><span class="ico_account ico_upload_w"></span>전표 정보 업로드</button> -->
        <!-- <button type="button" class="btn_medium btn_secondary" @click="uploadExcel"><span class="ico_account ico_upload_w"></span>전표 정보 업로드</button> -->
        <input :id="_uid" type="file" hidden @change="onFileChange" />
        <label :for="_uid" class="btn_medium btn_secondary"
          ><span class="ico_account ico_upload_w" />전표 정보 업로드</label
        >
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <!-- <th>기안번호</th>
            <td>{{ voucher.voucherCid }}</td> -->
            <th>지급처</th>
            <td colspan="3">
              {{ voucher.erpPartnerName }}
            </td>
          </tr>
          <tr>
            <th>전표일자</th>
            <td>
              <DatePicker
                :dateString.sync="voucher.voucherDate"
                @dateString="listenToOnChangeDayComplete"
              />
            </td>
            <th>증빙일자</th>
            <td>
              <DatePicker :dateString.sync="voucher.proofDate" />
            </td>
          </tr>
          <tr>
            <th>저장상태로 전표 생성</th>
            <td>
              <div class="box_choice">
                <div class="item_choice">
                  <!-- disabled 상태시 disabled 클래스 추가 -->
                  <input
                    id="voucherSaveModeYn"
                    v-model="voucher.voucherSaveModeYn"
                    type="checkbox"
                    class="inp_choice"
                    name="voucherSaveModeYn"
                    :value="true"
                  />
                  <label class="lab_choice" for="voucherSaveModeYn">
                    <span class="ico_account ico_check" />저장상태로 전표 생성
                  </label>
                </div>
              </div>
            </td>
            <th>전표생성일</th>
            <td>-</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tbl_comm tbl_list tbl_list_sm8">
      <table>
        <colgroup>
          <col />
          <!-- No. -->
          <col style="width: 124px" />
          <!-- 계정과목 -->
          <col style="width: 124px" />
          <!-- 세목 -->
          <col style="width: 124px" />
          <!-- 부서 -->
          <col />
          <!-- 바코드 -->
          <col />
          <!-- 자산명 -->
          <col style="width: 124px" />
          <!-- 프로젝트코드 -->
          <col style="width: 124px" />
          <!-- 구분 -->
          <col />
          <!-- 수량 -->
          <col />
          <!-- 금액 -->
          <col style="width: 84px" />
          <!-- 삭제 -->
        </colgroup>
        <thead>
          <tr>
            <th>No.</th>
            <th>계정과목</th>
            <th>세목</th>
            <th>부서</th>
            <th>바코드</th>
            <th>자산명</th>
            <th>프로젝트코드</th>
            <th>구분</th>
            <th>수량</th>
            <th>금액</th>
            <th>
              <!-- <button type="button" class="btn_small btn_secondary" @click="addVoucherItem">추가</button> -->
            </th>
          </tr>
        </thead>
        <tbody>
          <InfraVoucherListLine
            v-for="(item, index) in voucher.voucherItems"
            :key="index + '_uid'"
            :index="index"
            :rowData="item"
            @addVoucherItem="addVoucherItem"
            @removeVoucherLine="removeVoucherLine"
            @onClickPopAccount="(index) => $emit('onClickPopAccount', index)"
            @onClickPopTax="(index) => $emit('onClickPopTax', index)"
            @onClickPopDept="(index) => $emit('onClickPopDept', index)"
            @onClickSearchProjectCode="$emit('onClickSearchProjectCode', index)"
            @onClickPopAssetsGubun="(index) => $emit('onClickPopAssetsGubun', index)"
          />
        </tbody>
      </table>
    </div>
    <div class="tbl_foot">
      <div class="area_total">
        <dl>
          <dt>총 전표금액</dt>
          <dd>
            {{ totalVoucher | currency }}<span class="util_total">{{ voucher.currency }}</span>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import CommInpSearch from "@/_approval/components/common/CommInpSearch";
import InfraVoucherListLine from "./InfraVoucherListLine";
// import VoucherInfoModel from './VoucherInfoModel'
import VoucherItemsModel from "../../VoucherItemsModel";
// import { default as CommFlatpickrMixin } from '@/_approval/mixins/common/commFlatpickr.js';
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer";
import ApiService from "@/services/ApiService";
import { invalidFiles } from "@/_approval/const/const.js";
import { getMoneyToNumber } from "@/utils/stringUtils";

import DatePicker from "@/components/common/calendar/DatePicker";

export default {
  name: "InfraVoucher",
  components: { CommInpSearch, InfraVoucherListLine, DatePicker },
  mixins: [CommLayerMixin], //CommFlatpickrMixin
  props: {
    voucher: Object,
    deptInfo: Object,

    isAddApprover: Boolean,
  },
  computed: {
    totalVoucher() {
      let totalResult = null;
      this.voucher.voucherItems.forEach((voucherItem) => {
        totalResult += getMoneyToNumber(voucherItem.debit) * getMoneyToNumber(voucherItem.amount);
      });
      return totalResult;
    },
  },
  mounted() {
    // voucherItem 두개 추가 (인프라정산서일시 화면노출 X / 지출전표일시 화면노출 O)
    // const debit = this.voucher.voucherItems.filter(item => item.accountCode === '1113000000');
    // const credit = this.voucher.voucherItems.filter(item => item.accountCode === '2101020000');
    // this.setEssentialVoucherItem(debit);
    // this.setEssentialVoucherItem(credit);
  },
  methods: {
    // setEssentialVoucherItem() {
    // const debit = new VoucherItemsModel();
    // debit.serviceCode = 'S999999';
    // const credit = new VoucherItemsModel();

    // this.voucher.push(debit);
    // this.voucher.push(credit);
    // },
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    addVoucherItem() {
      const voucher = new VoucherItemsModel();
      const num = this.voucher.voucherItems.length + 1;
      voucher.orderNum = num;
      voucher.crdr = "DR";
      voucher.origin = false;
      // 인프라 전표의 첫번째 데이터는 삭제가 불가하여, 첫번째데이터의 부서코드를 가져옵니다.
      voucher.deptCode = this.voucher.voucherItems[0].deptCode;
      voucher.deptName = this.voucher.voucherItems[0].deptName;

      // if(this.voucher.voucherItems) {
      //   this.voucher.voucherItems = Object.assign(this.voucher.voucherItems, voucher);
      // } else {
      //   this.voucher.voucherItems = Object.assign({}, voucher);
      // }
      if (this.voucher.voucherItems.length == 1) {
        this.voucher.voucherItems.push(voucher);
        return;
      }

      const idx = this.voucher.voucherItems.length - 2;
      this.voucher.voucherItems.splice(1, 0, voucher);
    },
    removeVoucherLine(idx) {
      if (idx == null) {
        return;
      }
      this.voucher.voucherItems.splice(idx, 1);
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.checkValidFile(files, e.target);
    },
    async checkValidFile(files, elInput) {
      var file;
      var strExtUpload;

      for (var i = 0; i < files.length; ++i) {
        file = files[i];

        strExtUpload = "." + file.name.split(".").pop();

        if (!this.getIsValidFileExt(strExtUpload, invalidFiles.INVALID_FILE_EXTENSIONS)) {
          this._showLayer({
            content: "업로드 불가능한 확장자입니다.",
            type: "alert",
          });
          elInput.value = "";
          return;
        }
      }

      // this.uploadExcel( files );
      try {
        let formData = new FormData();
        for (let i = 0; i < files.length; i += 1) {
          formData.append("file", files[i]);
        }
        const config = {
          contentType: "multipart/form-data",
        };
        const result = await ApiService.shared.post(
          this.$apiPath.SETTLEMENT_EXCEL,
          formData,
          config,
        );

        // const voucher = result.data.reverse();
        const voucher = result.data;

        this.voucher.voucherItems = [];
        // console.log(this.voucher.voucherItems);
        for (var i = 0; i < voucher.length; i++) {
          //this.voucher.voucherItems.unshift(voucher[i]);
          const obj = new VoucherItemsModel();
          obj.setData(voucher[i]);
          obj.deptCode = this.deptInfo.deptCode || "";
          obj.deptName = this.deptInfo.deptName || "";
          obj.crdr = "DR";
          if (i == 0) {
            obj.origin = true;
          } else {
            obj.origin = false;
          }
          this.voucher.voucherItems.push(obj);
        }

        // this.voucher.voucherItems = result.data.map(item => {
        //     const obj = new VoucherItemsModel();
        //     obj.setData(item);
        //     console.log(obj);
        //     return obj;
        // });
        //  this.voucher.voucherItems = result.data;
      } catch (e) {
        this._showLayer({ content: "파일 업로드 실패했습니다", type: "alert" });
      }
    },
    getIsValidFileExt(strExtUpload, arrExtCheck) {
      var strExtCheck;

      for (var j = 0; j < arrExtCheck.length; j++) {
        strExtCheck = arrExtCheck[j];

        if (strExtUpload.toLowerCase() === strExtCheck.toLowerCase()) {
          return false;
        }
      }

      return true;
    },
    listenToOnChangeDayComplete() {
      this.$emit("onChangeVoucherDate");
    },
    downloadTemplate() {},
    // async setEssentialVoucherItem(){

    // },
    // async getDeptList(userData) {
    //   let path = `${ this.$apiPath.VOUCHER_ORG_DEPARTMENT }`;
    // }
  },
};
</script>
<style scoped>
.head_tbl .pos_right .btn_medium {
  margin-right: 5px !important;
}
</style>
