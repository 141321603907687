<template>
  <tr :class="{ tr_select: isSelected }">
    <!--  @click="$emit('onClickPurchase', rowData)"<td>
      <div class="box_choice type_alone">
        <div class="item_choice">
          <input type="checkbox" :id="rowData.requestCid" class="inp_choice" name="projectCode" v-model="checkedNamesSync" :value="rowData">
          <label class="lab_choice" :for="rowData.requestCid">
            <span class="ico_account ico_check"></span>
          </label>
        </div>
      </div>
    </td> -->
    <td v-if="isRadioMode">
      <div class="box_choice type_alone">
        <div class="item_choice">
          <!-- disabled 상태시 disabled 클래스 추가 -->
          <input
            :id="rowData.requestCid"
            v-model="selectedPurchaseSync"
            type="radio"
            class="inp_choice"
            name="selectedPurchase"
            :value="rowData"
          />
          <label class="lab_choice" :for="rowData.requestCid">
            <span class="ico_account ico_rdo" />
          </label>
        </div>
      </div>
    </td>
    <!-- <td>{{ rowData.regDate | dateStringFormat  }}</td> -->
    <td>{{ rowData.regDate }}</td>
    <td>{{ rowData.requestCid }}</td>
    <td class="td_ellip">
      {{ rowData.title }}
    </td>
    <td class="td_ellip">
      {{ rowData.itemTitle }}
    </td>
    <td class="align_right">{{ rowData.price | currency }} {{ rowData.currency }}</td>
    <td>{{ rowData.loginId }}({{ rowData.requestPersonName }})</td>
    <!-- <td>{{ rowData.date }}</td>
    <td>{{ rowData.requestCid }}</td>
    <td class="td_ellip">{{ rowData.title }}</td>
    <td class="td_ellip">{{ rowData.catagorys }}</td>
    <td>{{ rowData.price }}</td>
    <td>{{ rowData.managerNm }}</td> -->
  </tr>
</template>

<script>
export default {
  name: "PopPurchaseRequestLine",
  props: {
    isRadioMode: Boolean,
    rowData: Object,
    selectedPurchase: Object,
    // checkedNames: Array
  },
  computed: {
    selectedPurchaseSync: {
      get() {
        return this.selectedPurchase;
      },
      set(value) {
        this.$emit("update:selectedPurchase", value);
      },
    },
    isSelected() {
      if (!this.selectedPurchase || !this.rowData) return;
      return this.selectedPurchase.requestCid == this.rowData.requestCid;
    },
    //   checkedNamesSync: {
    //     get() {
    //       return this.checkedNames;
    //     },
    //     set(value) {
    //       this.$emit("update:checkedNames", value);
    //     }
    //   },
  },
};
</script>
