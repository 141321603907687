<template>
  <div class="layer_body">
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 180px" />
          <col style="width: 258px" />
          <col style="width: 180px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>검수ID</th>
            <td>{{ inspectData.calculateNum }}</td>
            <th>검수일</th>
            <td>{{ inspectDetail.inspectCompleteDate | date }}</td>
          </tr>
          <tr>
            <th>금번검수금액</th>
            <td>
              {{ inspectData.inspectPrice | currency }}
              {{ inspectDetail.currencyCode }}
            </td>
            <th>검수상태</th>
            <td>{{ stateCodeName(inspectData.stateCode) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="blank_asset3">
      <div class="tbl_comm tbl_list tbl_scroll">
        <div
          class="inner_scroll"
          style="height: calc(100vh - 451px); max-height: 294px; min-height: 49px"
        >
          <table>
            <colgroup>
              <col style="width: 142px" />
              <!-- 일시 -->
              <col style="width: 150px" />
              <!-- 상태 -->
              <col style="width: 184px" />
              <!-- 의견 -->
              <col style="width: 178px" />
              <!-- 담당자 -->
              <col />
              <!-- 첨부파일 -->
            </colgroup>
            <thead>
              <tr>
                <th style="width: 142px">일시</th>
                <th style="width: 150px">상태</th>
                <th style="width: 184px">의견</th>
                <th style="width: 178px">담당자</th>
                <th style="width: 226px">첨부파일</th>
              </tr>
            </thead>
            <tbody>
              <PopInspectHistoryListLine
                v-for="item in inspectDetail.contractInspectHistoryList"
                :key="item.inspectId"
                :rowData="item"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopInspectHistoryListLine from "./PopInspectHistoryListLine";
import inspectStatusList from "@/_approval/constants/inspectStatusList";
import ApiService from "@/services/ApiService";

export default {
  name: "PopInspectDetail",
  components: {
    PopInspectHistoryListLine,
  },
  props: {
    inspectData: Object,
    contractId: Number,
  },
  data() {
    return {
      inspectDetail: [],
    };
  },
  mounted() {
    this.getInspectHistoryData();
  },
  methods: {
    async getInspectHistoryData() {
      const path = `${this.$apiPath.INSPECT}?calculateNum=${this.inspectData.calculateNum}&contractId=${this.contractId}`;

      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        // this.dataList = [];
        this.alert(result.text);
        return;
      }
      this.inspectDetail = result.data;
    },
    stateCodeName(stateCode) {
      const result = inspectStatusList.find((item) => item.code === stateCode);
      if (!result) return "";

      return result.text;
    },
  },
};
</script>
