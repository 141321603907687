<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 944px">
      <div class="layer_head">
        <strong class="tit_popup"
          >{{ isPurchaseRequestSeleted ? "계약 정보" : "구매요청서" }} 선택</strong
        >
        <span class="txt_popup">Step0{{ isPurchaseRequestSeleted ? "2" : "1" }}</span>
      </div>
      <!-- step01 -->
      <div v-if="!isPurchaseRequestSeleted" class="layer_body">
        <!-- 필터바 -->
        <PopPurchaseRequestFilter :searchForm="searchForm" @select-connector="getPurchaseRequest" />
        <!-- 구매요청서 리스트 -->
        <div class="tbl_comm tbl_list tbl_scroll">
          <div
            class="inner_scroll"
            :style="
              !isPurchaseRequestSeleted
                ? 'height: calc(100vh - 544px); max-height: 294px; min-height: 49px;'
                : ''
            "
          >
            <table>
              <colgroup>
                <col style="width: 52px" />
                <col style="width: 88px" />
                <!-- 요청일 -->
                <col style="width: 122px" />
                <!-- 요청번호 -->
                <col style="width: 155px" />
                <!-- 구매요청서명 -->
                <col style="width: 156px" />
                <!-- 구매카테고리 -->
                <col style="width: 135px" />
                <!-- 기안금액 -->
                <col />
                <!-- 구매요청자 -->
              </colgroup>
              <thead>
                <tr>
                  <th style="width: 52px" />
                  <th style="width: 88px">요청일</th>
                  <th style="width: 122px">요청번호</th>
                  <th style="width: 155px">구매요청서명</th>
                  <th style="width: 156px">구매카테고리</th>
                  <th style="width: 135px">기안금액</th>
                  <th style="width: 172px">구매요청자</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="purchaseRequestDataList.length > 0">
                  <PopPurchaseRequestLine
                    v-for="item in purchaseRequestDataList"
                    :key="item.requestCid"
                    :isRadioMode="true"
                    :rowData="item"
                    :selectedPurchase.sync="selectedPurchase"
                  />
                </template>
                <template v-else>
                  <tr>
                    <td colspan="7">검색 결과가 없습니다.</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- step02 -->
      <PopPurchaseRequestContractinfoList
        v-else
        :selectedPurchase="selectedPurchase"
        :selectedContractinfo.sync="selectedContractinfo"
        :contractinfo-data-length.sync="contractinfoDataLength"
        :is-loading.sync="isLoadingContractinfoData"
      />
      <!-- 구매요청서 선택후 -->
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            취소
          </button>
          <button
            v-if="!purchaseRequestInfo"
            type="button"
            class="btn_medium btn_secondary"
            :disabled="isDisabledMoveBtn"
            @click.prevent="onClickMove"
          >
            {{ isPurchaseRequestSeleted ? "이전" : "다음" }}
          </button>
          <button
            v-if="isPurchaseRequestSeleted"
            type="button"
            class="btn_medium btn_primary"
            :disabled="isDisabledSelectedBtn"
            @click.prevent="onClickSelected"
          >
            {{
              isLoadingContractinfoData ? "선택" : contractinfoDataLength > 0 ? "선택" : "선택안함"
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopPurchaseRequestFilter from "./PopPurchaseRequestFilter";
import PopPurchaseRequestLine from "./PopPurchaseRequestLine";
import PopPurchaseRequestContractinfoList from "./PopPurchaseRequestContractinfoList";

import ApiService from "@/services/ApiService";

import { makeQueryStringByObject } from "@/utils/urlUtils";

export default {
  name: "PopPurchaseRequest",
  components: {
    PopPurchaseRequestFilter,
    PopPurchaseRequestLine,
    PopPurchaseRequestContractinfoList,
  },
  props: {
    purchaseRequestInfo: Object,
    contractInfo: Object,
    selectedPurchaseRequestInfo: Object,
    selectedContract: Object,
  },
  data() {
    return {
      searchForm: {
        dates: [],
        draftFromDate: "",
        draftToDate: "",
        searchType: { code: "requestCid", name: "구매요청번호" },
        searchKeyword: "",
      },
      isPurchaseRequestSeleted: false, // 구매요청서 선택 유무
      purchaseRequestDataList: [],
      selectedPurchase: {}, // 선택한 구매요청서 데이터
      // selectedContractinfo: [] // 선택한 계약 정보 데이터
      selectedContractinfo: {}, // 선택한 계약 정보 데이터
      contractinfoDataLength: 0,
      isLoadingContractinfoData: false,
    };
  },
  computed: {
    isDisabledMoveBtn() {
      return !this.selectedPurchase.requestCid;
    },
    isDisabledSelectedBtn() {
      if (this.isLoadingContractinfoData) return true;
      if (this.contractinfoDataLength < 1) return false;
      return !this.selectedContractinfo || !this.selectedContractinfo.contractCid;
    },
  },
  watch: {
    // 구매요청서 변경시 계약정보 선택값 리셋
    selectedPurchase(newVal, oldVal) {
      if (oldVal.requestCid && newVal.requestCid != oldVal.requestCid) {
        this.selectedContractinfo = {};
      }
    },
  },
  mounted() {
    this.selectedContractinfo = this.selectedContract || {};
    if (!_.isEmpty(this.purchaseRequestInfo)) {
      // this.selectedPurchase = this.purchaseRequestInfo;
      let path = `${this.$apiPath.SETTLEMENT_PURCHASE}`;
      path += `?searchType=requestCid&searchValue=${this.purchaseRequestInfo.requestCid}`;

      this.getPurchaseRequestInfo(path);
    } else {
      this.getInitPurchaseRequestInfo();
    }
  },
  methods: {
    getInitPurchaseRequestInfo() {
      //  const path = `${ this.$apiPath.DRAFT_LIST }`;
      this.getPurchaseRequest("init");
    },
    getPurchaseRequest(isSelectd) {
      let path = `${this.$apiPath.SETTLEMENT_PURCHASE}`;
      //  if(isInit = '') {
      //     path += `?startDate=${ this.searchForm.draftFromDate }`
      //  }
      const { dates, searchType, searchKeyword } = this.searchForm;

      let searchObj = {};
      if (dates[0] && dates[1]) {
        searchObj.startDate = this.moment(this.searchForm.dates[0]).format("YYYY-MM-DD");
        searchObj.endDate = this.moment(this.searchForm.dates[1]).format("YYYY-MM-DD");
      }
      if (searchKeyword) {
        searchObj.searchType = searchType.code;
        searchObj.searchValue = searchKeyword;
      }
      const params = makeQueryStringByObject(searchObj);

      this.getPurchaseRequestInfo(`${path}${params}`);
    },
    async getPurchaseRequestInfo(path) {
      const result = await ApiService.shared.getData(path);

      const { code, data, text } = result;
      if (code !== "200") {
        return;
      }

      if (!_.isEmpty(this.purchaseRequestInfo)) {
        this.selectedPurchase = result.data[0];
        this.isPurchaseRequestSeleted = true;
      } else {
        this.purchaseRequestDataList = result.data;
        this.selectedPurchase = this.selectedPurchaseRequestInfo;
      }
      this.selectedContractinfo = this.selectedContract || this.contractInfo;
    },
    // 구매요청서 선택
    onClickPurchase(item) {
      this.selectedPurchase = item;
    },
    // 계약 정보 선택
    onClickContractinfo(item) {
      this.selectedContractinfo = item;
    },
    onClickMove() {
      // 구매요청서 선택 전
      if (!this.isPurchaseRequestSeleted) {
        this.isPurchaseRequestSeleted = true;
      } else {
        this.isPurchaseRequestSeleted = false;
      }
    },
    onClickSelected() {
      this.$emit("onClickComplete", this.selectedPurchase, this.selectedContractinfo);
    },
  },
};
</script>
