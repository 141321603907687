<template>
  <div class="box_sorting">
    <strong class="screen_out">내역 검색하기</strong>
    <ul class="list_sorting">
      <li class="item_sort">
        <em class="tit_txt">사용년월1</em>
        <div class="cont_sorting">
          <div class="row_pack">
            <DatePicker
              :type="'month'"
              :dateString.sync="searchForm.accountStartDate"
              :dateFormat="'YYYY-MM'"
              @dateString:update="listenToOnChangeDayDraftFrom"
            />
          </div>
        </div>
      </li>
      <li class="item_sort item_right">
        <button type="button" class="btn_medium btn_primary" @click.prevent="clickSearchButton">
          검색
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
// import axios from 'axios';
//import { default as CommFlatpickrMixin } from '@/_approval/mixins/common/commFlatpickr.js';

import DatePicker from "@/components/common/calendar/DatePicker";

export default {
  name: "PopCorporateCardFilter",
  components: { DatePicker },
  //mixins: [CommFlatpickrMixin],
  props: {
    searchForm: Object,
  },
  data() {
    return {};
  },
  beforeMount() {},
  methods: {
    listenToOnChangeDayDraftFrom(dateString, dateObj) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
      this.flatpickrConfigDayDraftTo.minDate = dateString;
      this.flatpickrConfigDayDraftTo = { ...this.flatpickrConfigDayDraftTo };

      // this.calculateDateEnd = null;
    },

    clickSearchButton() {
      this.$emit("select-connector", this.searchForm);
    },
  },
};
</script>
