<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 948px">
      <div class="layer_head">
        <strong class="tit_popup">발주서 불러오기</strong>
      </div>
      <div class="layer_body">
        <PopOrderFilter :searchForm="searchForm" @select-connector="getOrderInfo" />
        <div class="tbl_comm tbl_list tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 544px); max-height: 294px; min-height: 49px"
          >
            <table>
              <colgroup>
                <!-- <col style="width: 52px;"> 선택 -->
                <col style="width: 110px" />
                <!-- 발주일 -->
                <col style="width: 140px" />
                <!-- 발주번호 -->
                <col style="width: 200px" />
                <!-- 발주서명 -->
                <col style="width: 140px" />
                <!-- 파트너사 -->
                <col style="width: 122px" />
                <!-- 발주금액 -->
                <col />
                <!-- 구매담당자 -->
              </colgroup>
              <thead>
                <tr>
                  <!--  <th style="width: 52px;">
                    <div class="box_choice type_alone">
                      텍스트 없이 아이콘만 있는 타입 type_alone 클래스 추가 
                      <div class="item_choice">
                         disabled 상태시 disabled 클래스 추가
                        <input
                          type="checkbox"
                          id="checkLineAll"
                          class="inp_choice"
                          name="checkLineAll"
                          v-model="checkAll"
                        />
                        <label class="lab_choice" for="checkLineAll">
                          <span class="ico_account ico_check"></span>
                        </label>
                      </div>
                    </div>
                  </th> -->
                  <th style="width: 110px">발주일</th>
                  <th style="width: 140px">발주번호</th>
                  <th style="width: 200px">발주서명</th>
                  <th style="width: 140px">파트너사</th>
                  <th style="width: 122px">발주금액</th>
                  <th style="width: 173px">구매담당자</th>
                </tr>
              </thead>
              <tbody v-if="orderinfoDataList">
                <PopOrderListLine
                  v-for="item in orderinfoDataList"
                  :key="item.orderCid"
                  :rowData="item"
                  :selectedOrder.sync="selectedOrder"
                />
                <tr v-if="orderinfoDataList.length == 0">
                  <td colspan="6" class="td_empty">발주서가 없습니다</td>
                </tr>
                <!-- <PopOrderListLine 
                  v-for="item in orderinfoDataList"
                  :key="item.orderCid"
                  :rowData="item"
                  :checkedNames.sync="checkedNames"/> -->
                <!-- @onClickPurchase="onClickPurchase" -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            닫기
          </button>
          <button
            type="button"
            class="btn_medium btn_medium btn_secondary"
            :disabled="isDisabledSeletedBtn"
            @click.prevent="onClickSelected"
          >
            선택
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import PopPurchaseRequestFilter from './PopPurchaseRequestFilter';
import PopOrderFilter from "./PopOrderFilter";
import PopOrderListLine from "./PopOrderListLine";

import ApiService from "@/services/ApiService";
import { makeQueryStringByObject } from "@/utils/urlUtils";

export default {
  name: "PopOrder",
  components: {
    //PopPurchaseRequestFilter,
    PopOrderFilter,
    PopOrderListLine,
  },
  props: {
    purchaseRequestWf: Object,
  },
  data() {
    return {
      checkAll: false,
      //checkedNames: [],
      selectedOrder: {}, // 선택한 발주 데이터
      searchForm: {
        dates: [],
        draftFromDate: "",
        draftToDate: "",
        searchType: { code: "orderCid", name: "발주번호" },
        searchKeyword: "",
      },
      orderinfoDataList: [
        // api 연동 전 임시 데이터
        // { orderDate: '2020-12-31', orderCid: '20201231_002_031', title:'구매(입찰)관리 시스템 외주 개발 건1', companyName:'빨간모자', price: '88,000,000 KRW', commonReqName: 'justin.koo(구본국)', purchasePersonLoginId: 'justin.koo', purchasePersonName:'구본국' },
        // { orderDate: '2020-12-31', orderCid: '20201231_002_032', title:'구매(입찰)관리 시스템 외주 개발 건2', companyName:'카카오엔터프라이즈', price: '88,000,000 KRW', commonReqName: 'flora.a(이혜민)'  , purchasePersonLoginId: 'flora.a', purchasePersonName:'이혜민'},
        // { orderDate: '2020-12-31', orderCid: '20201231_002_033', title:'구매(입찰)관리 시스템 외주 개발 건3', companyName:'빨간모자', price: '88,000,000 KRW', commonReqName: 'jason.jeong888 (김제일)', purchasePersonLoginId: 'jason.jeong888', purchasePersonName:'김제일' },
        // { orderDate: '2020-12-31', orderCid: '20201231_002_034', title:'구매(입찰)관리 시스템 외주 개발 건4', companyName:'빨간모자', price: '88,000,000 KRW', commonReqName: 'justin.koo(구본국)', purchasePersonLoginId: 'justin.koo', purchasePersonName:'구본국' },
        // { orderDate: '2020-12-31', orderCid: '20201231_002_035', title:'구매(입찰)관리 시스템 외주 개발 건5', companyName:'빨간모자', price: '88,000,000 KRW', commonReqName: 'justin.koo(구본국)', purchasePersonLoginId: 'justin.koo', purchasePersonName:'구본국' },
        // { orderDate: '2020-12-31', orderCid: '20201231_002_036', title:'구매(입찰)관리 시스템 외주 개발 건6', companyName:'빨간모자', price: '88,000,000 KRW', commonReqName: 'justin.koo(구본국)' , purchasePersonLoginId: 'justin.koo', purchasePersonName:'구본국'},
        // { orderDate: '2020-12-31', orderCid: '20201231_002_037', title:'구매(입찰)관리 시스템 외주 개발 건7', companyName:'빨간모자', price: '88,000,000 KRW', commonReqName: 'justin.koo(구본국)', purchasePersonLoginId: 'justin.koo', purchasePersonName:'구본국' },
        // // { date: '2020-12-31', requestCid: '20201231_002_038', title:'구매(입찰)관리 시스템 외주 개발 건8', companyNm:'빨간모자', price: '88,000,000 KRW', managerNm: 'justin.koo(구본국)' },
      ],
    };
  },
  computed: {
    isDisabledSeletedBtn() {
      return !this.selectedOrder.orderCid;
    },
    requestCid() {
      if (!this.purchaseRequestWf) return;
      if (this.purchaseRequestWf.requestCid == "") return;

      return this.purchaseRequestWf.requestCid;
    },
  },
  // 임시
  // watch:{
  //   checkedNames(){
  //     if(this.checkedNames.length === this.orderinfoDataList.length){
  //       this.checkAll = true;
  //     }else if(this.checkAll && this.checkedNames.length != this.orderinfoDataList.length){
  //       this.checkAll = false;
  //     }
  //   },
  //   checkAll(to,from){
  //     // cheked on
  //     if( to && !from ){
  //       if(this.checkedNames.length != this.orderinfoDataList.length){
  //         this.checkedNames = this.orderinfoDataList;
  //       }
  //     }
  //   },
  // },
  mounted() {
    this.getInitOrderInfo();
  },
  methods: {
    getInitOrderInfo() {
      // let obj = {};
      // obj.requestCid = '';
      // obj.contractCid = '';

      // this.getOrderInfo(obj);
      this.getOrderInfo();
    },
    async getOrderInfo(data) {
      const path = this.getApiPath();
      //const path = `${ this.$apiPath.SETTLEMENT_ORDER }`;
      const result = await ApiService.shared.getData(path);

      if (result.data) {
        this.orderinfoDataList = result.data;
      }
    },
    getApiPath(param) {
      let path = `${this.$apiPath.SETTLEMENT_ORDER}`;

      const { dates, searchType, searchKeyword } = this.searchForm;

      let searchObj = {};
      if (this.requestCid) {
        searchObj.requestCid = this.requestCid;
      }
      if (dates[0] && dates[1]) {
        searchObj.startDate = this.moment(this.searchForm.dates[0]).format("YYYY-MM-DD");
        searchObj.endDate = this.moment(this.searchForm.dates[1]).format("YYYY-MM-DD");
      }
      if (searchKeyword) {
        searchObj.searchType = searchType.code;
        searchObj.searchValue = searchKeyword;
      }
      const params = makeQueryStringByObject(searchObj);

      return `${path}${params}`;
    },
    onClickSelected() {
      // this.selectedOrder.orderDate =  this.selectedOrder.orderDate.replaceAll("-", "");
      //order data에 대한 데이터 파싱 처리
      this.selectedOrder.orderDate = this.selectedOrder.orderDate
        .replaceAll("-", "")
        .concat("000000");

      this.$emit("onClickComplete", this.selectedOrder);
    },
  },
};
</script>
