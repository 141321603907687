import { stringToDateWithTime, dateToStringWithTime } from "@/utils/dateUtils";

class ContractInfoModel {
  constructor(obj) {
    this.commonReqName = ""; // 계약요청자
    this.contractCid = 0; // 계약서 번호
    this.contractStartDate = null; // 계약시작일
    this.description = ""; // 계약명
    this.purchasePersonLoginId = ""; // 구매담당자 ID
    this.purchasePersonName = ""; // 구매담당자 이름

    if (obj) {
      this.setData(obj);
    }
  }

  setData(obj) {
    //if(obj.commonReqName) this.commonReqName = obj.commonReqName;
    if (obj.contractCid) this.contractCid = obj.contractCid;
    //if(obj.contractStartDate) this.contractStartDate = stringToDateWithTime(obj.contractStartDate);
    // 테스트용
    if (obj.contractStartDate) this.contractStartDate = obj.contractStartDate;
    if (obj.description) this.description = obj.description;
    if (obj.purchasePersonLoginId) this.purchasePersonLoginId = obj.purchasePersonLoginId;
    if (obj.purchasePersonName) this.purchasePersonName = obj.purchasePersonName;
    this.commonReqName = this.purchasePersonLoginId + "(" + this.purchasePersonName + ")";
  }

  getData() {
    let obj = {
      //commonReqName : this.commonReqName,
      purchasePersonName: this.purchasePersonName,
      purchasePersonLoginId: this.purchasePersonLoginId,
      contractCid: this.contractCid,
      contractStartDate: dateToStringWithTime(this.contractStartDate),
      description: this.description,
    };

    return obj;
  }
}

export default ContractInfoModel;
