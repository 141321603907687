<template>
  <tr>
    <td class="align_top">
      {{ rowData.regDate | date }}
    </td>
    <td class="align_top">
      {{ rowData.stateCodeName }}
    </td>
    <td class="align_top">
      {{ rowData.etc }}
    </td>
    <td class="align_top">
      {{ rowData.regId }}
    </td>
    <td>
      <FileView :dataList="rowData.inspectFile ? rowData.inspectFile : []" />
    </td>
  </tr>
</template>
<script>
import FileView from "@/_approval/components/common/file/FileView";
export default {
  name: "PopInspectHistoryListLine",
  components: {
    FileView,
  },
  props: {
    rowData: Object,
  },
};
</script>
