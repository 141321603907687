<template>
  <!-- <tr :class="{ tr_select : isChecked }"> -->
  <tr :class="{ tr_select: isSelected }" @click="$emit('update:selectedContractinfo', rowData)">
    <!-- <td>
      <div class="box_choice type_alone">
        <div class="item_choice">
          <input type="checkbox" :id="rowData.requestCid" class="inp_choice" name="projectCode" v-model="checkedNamesSync" :value="rowData">
          <label class="lab_choice" :for="rowData.requestCid">
            <span class="ico_account ico_check"></span>
          </label>
        </div>
      </div>
    </td> -->
    <td>{{ rowData.contractStartDate }}</td>
    <td>{{ rowData.contractId }}</td>
    <td class="td_ellip">
      {{ rowData.description }}
    </td>
    <td class="td_ellip">
      {{ rowData.companyName }}
    </td>
    <td>{{ rowData.finalPrice | currency }} {{ rowData.currency }}</td>
    <td>{{ rowData.purchasePersonLoginId }} ({{ rowData.purchasePersonName }})</td>
  </tr>
</template>

<script>
export default {
  name: "PopPurchaseRequestContractinfoListLine",
  props: {
    rowData: Object,
    selectedContractinfo: Object,
    //checkedNames:Array
  },
  computed: {
    // checkedNamesSync: {
    //   get() {
    //     return this.checkedNames;
    //   },
    //   set(value) {
    //     this.$emit("update:checkedNames", value);
    //   }
    // },
    // isChecked(){
    //   return this.checkedNames.find( item => item.contractCid == this.rowData.contractCid );
    // }
    isSelected() {
      if (!this.selectedContractinfo) return false;
      return this.selectedContractinfo.contractCid == this.rowData.contractCid;
    },
  },
};
</script>
