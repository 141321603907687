<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 464px">
      <div class="layer_head">
        <strong class="tit_popup">세목 검색</strong>
      </div>
      <div class="layer_body">
        <!-- 필터바 -->
        <div class="box_sorting">
          <strong class="screen_out">세목 검색하기</strong>
          <ul class="list_sorting">
            <li class="item_full">
              <!-- <em class="tit_txt">검색어</em> -->
              <div class="row_pack">
                <Input
                  style="width: 240px"
                  placeholder="검색어를 입력하세요"
                  :value.sync="searchForm.searchKeyword"
                />
                <button
                  type="button"
                  class="btn_medium btn_primary"
                  @click.prevent="clickSearchButton"
                >
                  검색
                </button>
              </div>
            </li>
          </ul>
        </div>
        <!-- 법인카드 사용내역 리스트 -->
        <div class="tbl_comm tbl_list tbl_list_sm8 tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 544px); max-height: 294px; min-height: 49px"
          >
            <table>
              <colgroup>
                <col style="width: 52px" />
                <!-- 선택 -->
                <col />
                <!-- 사용금액 -->
              </colgroup>
              <thead>
                <tr>
                  <th style="width: 52px" />
                  <th style="width: 348px">세목</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="taxDataList.length === 0">
                  <td colspan="9">조회된 세목이 없습니다</td>
                </tr>
                <tr
                  v-for="(item, index) in taxDataList"
                  :key="index"
                  :class="{ tr_select: isSelected(item) }"
                >
                  <td>
                    <div class="box_choice type_alone">
                      <div class="item_choice">
                        <!-- disabled 상태시 disabled 클래스 추가 -->
                        <input
                          :id="item.detailCode"
                          v-model="selectedTax"
                          type="radio"
                          class="inp_choice"
                          name="selectedCorporateCard"
                          :value="item"
                        />
                        <label class="lab_choice" :for="item.detailCode">
                          <span class="ico_account ico_rdo" />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td class="td_ellip">
                    {{ item.detailName }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            취소
          </button>
          <button
            type="button"
            class="btn_medium btn_secondary"
            :disabled="isDisabledMoveBtn"
            @click.prevent="onClickSelected"
          >
            선택
          </button>
          <!-- <button v-if="isBillSeleted" type="button" class="btn_medium btn_primary" :disabled="isDisabledSeletedBtn" @click.prevent="onClickSelected">선택</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Input from "@/_approval/components/common/input/Input";
import ApiService from "@/services/ApiService";

import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";

export default {
  name: "PopTax",
  components: {
    Input,
  },
  mixins: [CommLayerMixin],
  props: {
    accntCode: String,
    selectedData: Object,
  },
  data() {
    return {
      searchForm: {
        searchKeyword: "",
      },
      taxDataList: [],
      selectedTax: {}, // 선택한 세목 데이터
    };
  },
  computed: {
    isDisabledMoveBtn() {
      return !this.selectedTax.detailCode;
    },
  },
  mounted() {
    this.selectedTax = this.selectedData;
    this.getTaxItemList();
  },
  methods: {
    async getTaxItemList() {
      let path = `${this.$apiPath.VOUCHER_INFRA_TAXITEM}?accountCode=${this.accntCode}`;
      if (this.searchForm.searchKeyword != "") {
        path += `?detailName=${this.searchForm.searchKeyword}`;
      }

      const result = await ApiService.shared.getData(path);

      if (result.data) {
        this.taxDataList = result.data;
      }
    },
    isSelected(item) {
      return this.selectedTax.detailCode == item.detailCode;
    },
    onClickSelected() {
      this.$emit("onClickComplete", this.selectedTax);
    },
    clickSearchButton() {
      this.getTaxItemList();
    },
  },
};
</script>
