<template>
  <tr v-if="isShowVoucherLine">
    <td>{{ index + 1 }}</td>
    <td>
      <div class="group_input_search">
        <input
          ref="accountName"
          v-model="rowData.accountName"
          type="text"
          class="inp_comm"
          maxLength="100"
          disabled="disabled"
          @focusout="onFocusout"
        />
        <!-- <Input
          :value.sync="rowData.accountCode"
          :isDisabled="true"/> -->
        <button class="btn_input_search" @click="onClickPopAccount">
          <span class="ico_account ico_search" />
        </button>
      </div>
    </td>
    <td>
      <div class="group_input_search">
        <Input :value.sync="rowData.taxName" :isDisabled="true" @focusout="onFocusout" />
        <button class="btn_input_search" @click="onClickPopTax">
          <span class="ico_account ico_search" />
        </button>
      </div>
    </td>
    <td>
      <div class="group_input_search">
        <Input :value.sync="rowData.deptName" :isDisabled="true" @focusout="onFocusout" />
        <button class="btn_input_search" @click="onClickPopDept">
          <span class="ico_account ico_search" />
        </button>
      </div>
    </td>
    <td>
      <Input :value.sync="rowData.barcode" @focusout="onFocusout" />
    </td>
    <td>
      <Input :value.sync="rowData.goodsName" :maxLength="100" @focusout="onFocusout" />
    </td>
    <td>
      <div class="group_input_search">
        <Input
          :value.sync="rowData.serviceCode"
          :isDisabled="true"
          :maxLength="100"
          @focusout="onFocusout"
        />
        <button class="btn_input_search" @click="$emit('onClickSearchProjectCode')">
          <span class="ico_account ico_search" />
        </button>
      </div>
    </td>
    <td>
      <div class="group_input_search">
        <Input
          :value.sync="newUsedName"
          :isDisabled="true"
          :maxLength="100"
          @focusout="onFocusout"
        />
        <button class="btn_input_search" @click="onClickPopAssetsGubun">
          <span class="ico_account ico_search" />
        </button>
      </div>
    </td>
    <td>
      <InputAutoMoney :value.sync="rowData.amount" :maxLength="100" @focusout="onFocusout" />
    </td>
    <td>
      <InputAutoMoney :value.sync="rowData.debit" :maxLength="100" @focusout="onFocusout" />
    </td>
    <td>
      <button
        v-if="rowData.crdr !== 'CR' && rowData.origin === true"
        type="button"
        class="btn_small btn_secondary"
        @click="$emit('addVoucherItem')"
      >
        추가
      </button>
      <button
        v-if="rowData.origin === false"
        type="button"
        class="btn_small btn_fourthly"
        @click="$emit('removeVoucherLine', index)"
      >
        삭제
      </button>
    </td>
  </tr>
</template>

<script>
import Input from "@/_approval/components/common/input/Input";
import InputAutoMoney from "@/_approval/components/common/input/InputAutoMoney";

import { default as CommFlatpickrMixin } from "@/_approval/mixins/common/commFlatpickr.js";
import CommInpSearch from "@/_approval/components/common/CommInpSearch";

export default {
  name: "InfraVoucherListLine",
  components: {
    CommInpSearch,
    Input,
    InputAutoMoney,
  },
  mixins: [CommFlatpickrMixin],
  props: {
    rowData: Object,
    index: Number,
  },
  data() {
    return {
      filteredList: [],
    };
  },
  computed: {
    isShowVoucherLine() {
      // 인프라 전표에서 생성되는 차변 / 대변에 대한 전표 row는 화면에서 노출되지않는다.
      return this.rowData.serviceCode !== "S999999";
    },
    newUsedName() {
      if (this.rowData.newUsed === "") return "";
      return this.rowData.newUsed === "NEW" ? "신규" : "중고";
    },
  },
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    onClickPopAccount() {
      this.$emit("onClickPopAccount", this.index);
    },
    onClickPopTax() {
      this.$emit("onClickPopTax", this.index);
    },
    onClickPopDept() {
      this.$emit("onClickPopDept", this.index);
    },
    onClickPopAssetsGubun() {
      this.$emit("onClickPopAssetsGubun", this.index);
    },
  },
};
</script>
