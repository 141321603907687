<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 464px">
      <div class="layer_head">
        <strong class="tit_popup">거래처 검색</strong>
      </div>
      <div class="layer_body">
        <!-- 필터바 -->
        <div class="box_sorting">
          <strong class="screen_out">거래처 검색하기</strong>
          <ul class="list_sorting">
            <li class="item_full">
              <!-- <em class="tit_txt">검색어</em> -->
              <div class="row_pack">
                <Input
                  style="width: 240px"
                  placeholder="검색어를 입력하세요"
                  :value.sync="searchForm.searchKeyword"
                  @onEnterKey="clickSearchButton"
                />
                <button
                  type="button"
                  class="btn_medium btn_primary"
                  @click.prevent="clickSearchButton"
                >
                  검색
                </button>
              </div>
            </li>
          </ul>
        </div>
        <!-- 법인카드 사용내역 리스트 -->
        <div class="tbl_comm tbl_list tbl_list_sm8 tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 544px); max-height: 294px; min-height: 49px"
          >
            <table>
              <colgroup>
                <col style="width: 52px" />
                <!-- 선택 -->
                <col />
                <!-- 사용금액 -->
              </colgroup>
              <thead>
                <tr>
                  <th style="width: 52px" />
                  <th style="width: 348px">거래처</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in partnerDataList"
                  :key="index"
                  :class="{ tr_select: isSelected(item) }"
                >
                  <td>
                    <div class="box_choice type_alone">
                      <div class="item_choice">
                        <!-- disabled 상태시 disabled 클래스 추가 -->
                        <input
                          :id="item.companyId"
                          v-model="selectedPartner"
                          type="radio"
                          class="inp_choice"
                          name="selectedCorporateCard"
                          :value="item"
                        />
                        <label class="lab_choice" :for="item.companyId">
                          <span class="ico_account ico_rdo" />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td class="td_ellip">
                    {{ item.companyName }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            취소
          </button>
          <button
            type="button"
            class="btn_medium btn_secondary"
            :disabled="isDisabledMoveBtn"
            @click.prevent="onClickSelected"
          >
            선택
          </button>
          <!-- <button v-if="isBillSeleted" type="button" class="btn_medium btn_primary" :disabled="isDisabledSeletedBtn" @click.prevent="onClickSelected">선택</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Input from "@/_approval/components/common/input/Input";
import ApiService from "@/services/ApiService";

export default {
  name: "PopPartner",
  components: {
    Input,
  },
  props: {
    // isPartnerInfo: Boolean,
    selectedData: Object,
  },
  data() {
    return {
      searchForm: {
        searchKeyword: "",
      },
      partnerDataList: [],
      selectedPartner: {}, // 선택한 거래처 데이터
    };
  },
  computed: {
    isDisabledMoveBtn() {
      return !this.selectedPartner.companyId;
    },
  },
  beforeMount() {
    this.selectedPartner = this.selectedData;
    this.getVoucherPartner();
  },
  methods: {
    isSelected(item) {
      return this.selectedPartner.companyId == item.companyId;
    },
    clickSearchButton() {
      this.getVoucherPartner();
    },
    onClickSelected() {
      this.$emit("onClickComplete", this.selectedPartner);
    },
    async getVoucherPartner() {
      let path = `${this.$apiPath.VOUCHER_PARTNER}`;
      if (this.searchForm.searchKeyword) {
        path += `?companyName=${this.searchForm.searchKeyword}`;
      }
      const result = await ApiService.shared.getData(path);

      if (result.code == "200") {
        this.partnerDataList = result.data.map((item) => {
          let obj = {};
          obj.companyName = item.companyName;
          obj.companyId = item.companyId;
          obj.corporateNum = item.corporateNum;
          return obj;
        });
      }
    },
  },
};
</script>
