<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">자산</strong>
        <p class="desc_tip">
          <span class="ico_account ico_exclam" />전표에서 선택하신 자산 내용이 보여집니다.
        </p>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>구분</th>
            <td>
              <!--<Radio-->
              <!--  :dataList="newUsedList"-->
              <!--  name="receiptBill"-->
              <!--  :selectedId.sync="voucher.newUsed"-->
              <!--/>-->
              신규
              <input v-model="assetVoucher.newUsed" type="hidden" value="NEW" />
            </td>
            <th>자산명<sup>*</sup></th>
            <td>
              <!-- <div class="group_input_search"> -->
              <Input
                :value.sync="assetVoucher.goodsName"
                :maxLength="200"
                placeholder="자산명을 입력하세요"
                @focusout="onFocusout"
              />
              <!-- <button class="btn_input_search" @click="$emit('onClickPopAssets')"><span class="ico_account ico_search"></span></button>
              </div> -->
            </td>
          </tr>
          <tr>
            <th>자산분류<sup>*</sup></th>
            <td>
              <comm-opt
                :classList="['type_medium']"
                optionDesc="자산분류 선택상자"
                eventName="select"
                :optionName="assetVoucher.assetMainClassificationName || '자산분류'"
                :optionList="assetMainClassificationList"
                @select="selectAssetMainClassification"
              />
            </td>
            <th>수량<sup>*</sup></th>
            <td>
              <InputNumber
                :value.sync="assetVoucher.amount"
                :maxLength="20"
                placeholder="수량을 입력하세요"
                @focusout="onFocusout"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Input from "@/_approval/components/common/input/Input";
import CommOpt from "@/_approval/components/common/CommOpt";
import InputNumber from "@/_approval/components/common/input/InputNumber";
import Radio from "@/_approval/components/common/radio/Radio";
import ApiService from "@/services/ApiService";

export default {
  name: "StatementAsset",
  components: {
    Radio,
    Input,
    CommOpt,
    InputNumber,
  },
  props: {
    assetVoucher: Object,
  },
  data() {
    return {
      assetMainClassificationList: [{ code: "", name: "자산분류" }],
      newUsedList: [
        { code: "NEW", name: "신규" },
        // {code: 'A', name: '자본적 지출', isDisabled: true},
      ],
    };
  },
  mounted() {
    this.getInfraAssetType();
    if (!this.assetVoucher.newUsed) this.assetVoucher.newUsed = "NEW";
  },
  methods: {
    selectAssetMainClassification(item) {
      this.assetVoucher.assetMainClassification = item.code;
      this.assetVoucher.assetMainClassificationName = item.name;
    },
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    async getInfraAssetType() {
      try {
        // jde/asset
        const res = await ApiService.shared.get(this.$apiPath.JDE_ASSET);

        if (res.code === "200") {
          this.assetMainClassificationList = res.data;
          this.assetMainClassificationList.unshift({
            code: "",
            name: "자산분류",
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
