<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 980px">
      <div class="layer_head">
        <strong class="tit_popup">법인카드 사용내역</strong>
      </div>
      <div class="layer_body">
        <!-- 필터바 -->
        <PopCorporateCardFilter :searchForm="searchForm" @select-connector="getCorporateData" />
        <!-- 법인카드 사용내역 리스트 -->
        <div class="tbl_comm tbl_list tbl_list_sm8 tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 544px); max-height: 294px; min-height: 49px"
          >
            <table>
              <colgroup>
                <col style="width: 52px" />
                <!-- 선택 -->
                <col style="width: 182px" />
                <!-- 사용일시 -->
                <col style="width: 224px" />
                <!-- 카드 -->
                <col style="width: 242px" />
                <!-- 사용처 -->
                <col />
                <!-- 사용금액 -->
              </colgroup>
              <thead>
                <tr>
                  <th style="width: 52px" />
                  <th style="width: 182px">사용일시</th>
                  <th style="width: 224px">카드</th>
                  <th style="width: 242px">사용처</th>
                  <th style="width: 216px">사용금액</th>
                </tr>
              </thead>
              <tbody>
                <PopCorporateCardLine
                  v-for="(item, index) in corporateCardDataList"
                  :key="index"
                  :rowData="item"
                  :selectedCorporateCard.sync="selectedCorporateCard"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            취소
          </button>
          <button
            type="button"
            class="btn_medium btn_secondary"
            :disabled="isDisabledMoveBtn"
            @click.prevent="onClickSelected"
          >
            선택
          </button>
          <!-- <button v-if="isBillSeleted" type="button" class="btn_medium btn_primary" :disabled="isDisabledSeletedBtn" @click.prevent="onClickSelected">선택</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopCorporateCardFilter from "./PopCorporateCardFilter";
import PopCorporateCardLine from "./PopCorporateCardLine";

import ApiService from "@/services/ApiService";
import { getDate, getYearMonthWithDash } from "@/utils/dateUtils";
import { make00 } from "@/utils/stringUtils";

export default {
  name: "PopCorporateCard",
  components: {
    PopCorporateCardFilter,
    PopCorporateCardLine,
  },
  data() {
    return {
      initMonth: "",
      searchForm: {
        paymentDate: "",
      },
      corporateCardDataList: [
        // api 연동 전 임시 법인카드 사용내역 데이터
        //    { completeNo:'000001' ,date: '2020-12-31 12:40:44', cardNm: '삼성5531', storeNm: '장여사 강릉짬뽕',price: '5730000' },
        //    { completeNo:'000002' ,date: '2020-12-30 11:40:01', cardNm: '삼성5532', storeNm: '포메인 판교역점',price: '20000' },
        //    { completeNo:'000003' ,date: '2020-12-28 13:00:20', cardNm: '삼성5533', storeNm: '후라토 판교아비뉴프랑점',price: '888888' },
        //    { completeNo:'000004' ,date: '2020-12-27 12:01:00', cardNm: '삼성5534', storeNm: '장여사 강릉짬뽕',price: '5730000' },
        //    { completeNo:'000005' ,date: '2020-12-26 12:50:00', cardNm: '삼성5535', storeNm: '장여사 강릉짬뽕',price: '5730000' },
        //    { completeNo:'000006' ,date: '2020-12-23 13:40:00', cardNm: '삼성5536', storeNm: '장여사 강릉짬뽕',price: '5730000' },
        //    { completeNo:'000007' ,date: '2020-12-22 11:44:00', cardNm: '삼성5537', storeNm: '장여사 강릉짬뽕',price: '5730000' },
        //    { completeNo:'000008' ,date: '2020-12-21 13:00:00', cardNm: '삼성5538', storeNm: '장여사 강릉짬뽕',price: '5730000' },
        //    { completeNo:'000009' ,date: '2020-12-20 11:01:00', cardNm: '삼성5539', storeNm: '장여사 강릉짬뽕',price: '5730000' },
        //
      ],
      selectedCorporateCard: {}, // 선택한 국세청 세금계산서/계산서 데이터
    };
  },
  computed: {
    isDisabledMoveBtn() {
      return !this.selectedCorporateCard.approvalNo;
    },
  },
  mounted() {
    this.getInitCorporateData();
  },
  methods: {
    // // 구매요청서 선택
    // onClickPurchase( item ){
    //   this.selectedBill = item;
    // },
    getInitCorporateData() {
      let today = new Date();
      let currentYear = today.getFullYear().toString();
      let currentInitMonth = make00(today.getMonth() + 1);

      this.initMonth = `${currentYear}-${currentInitMonth.toString()}`;

      this.getCorporateData();
    },
    getCorporateData(data) {
      // const paymentDay = data ? getYearMonthWithDash( data.paymentDate ) : this.initMonth;
      const paymentDay = data ? data.accountStartDate : this.initMonth;
      const path = `${this.$apiPath.VOUCHER_PROOF_CORPCARD}?yearMonth=${paymentDay}`;

      const result = ApiService.shared.getData(path);

      if (result.data) {
        // 현재 실제 데이터를 확인할수 없어서 테스트 데이터 사용
        this.corporateCardDataList = result.data;
      }
    },
    onClickSelected() {
      this.$emit("onClickComplete", this.selectedCorporateCard);
    },
  },
};
</script>
