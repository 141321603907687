<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">기간인식</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>기간<sup>*</sup></th>
            <td>
              <div class="box_picker">
                <DatePicker :dateString.sync="voucher.periodStartDate" />
                <span class="txt_bar">~</span>
                <DatePicker :dateString.sync="voucher.periodEndDate" />
              </div>
            </td>
            <th>구분<sup>*</sup></th>
            <td>
              <Radio
                :dataList="prospectiveRetroactiveList"
                :name="`prospectiveRetroactive_${index}`"
                :selectedId.sync="voucher.prospectiveRetroactive"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tbl_comm tbl_list tbl_list_sm12">
      <table>
        <thead>
          <tr>
            <th>손익계정과목<sup>*</sup></th>
            <th>세목<sup>*</sup></th>
            <th>손익부서</th>
            <th>손익프로젝트코드</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="group_input_search">
                <Input
                  :value.sync="voucher.incomeLossAccountName"
                  :isDisabled="true"
                  :maxLength="100"
                  @focusout="onFocusout"
                />
                <button class="btn_input_search" @click="$emit('onClickPopAccount', 0, 'period')">
                  <span class="ico_account ico_search" />
                </button>
              </div>
              <!-- {{ voucher.incomeLossAccountName }} -->
            </td>
            <td>
              <div class="group_input_search">
                <Input
                  v-model="voucher.incomeLossTaxName"
                  :value.sync="voucher.incomeLossTaxName"
                  :isDisabled="true"
                  :maxLength="100"
                  @focusout="onFocusout"
                />
                <button class="btn_input_search" @click="$emit('onClickPopTax', 0, 'period')">
                  <span class="ico_account ico_search" />
                </button>
              </div>
              <!-- {{ voucher.incomeLossTaxName }} -->
            </td>
            <td>{{ voucher.incomeLossDeptName }}</td>
            <td>{{ voucher.incomeLossServiceCode }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Radio from "@/_approval/components/common/radio/Radio";
import Input from "@/_approval/components/common/input/Input";
import DatePicker from "@/components/common/calendar/DatePicker";

//import { default as CommFlatpickrMixin } from '@/_approval/mixins/common/commFlatpickr.js';

// 월 캘린더 사용하게 되면 주석 활성화
// import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js';
// import vueFlatPickr from 'vue-flatpickr-component'

// const defaultMonthConfig = {
//   locale: 'ko',
//   wrap: false,
//   disabled: false,
//   plugins: [
//     new monthSelectPlugin({
//       shorthand: false,
//       dateFormat: 'Y-m',
//       altFormat: 'Y-m'
//     })
//   ]
// };

export default {
  name: "StatementPeriod",
  components: {
    Radio,
    Input,
    DatePicker,
    // vueFlatPickr
  },
  //mixins: [CommFlatpickrMixin],
  props: {
    voucher: {
      type: Object,
      default: () => {},
    },
    index: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      // 월 캘린더 사용하게 되면 주석 활성화
      // flatpickrConfigMonthDraftFrom: defaultMonthConfig,
      // flatpickrConfigMonthDraftTo: defaultMonthConfig,
      flatpickrConfigDayCompleteFrom: {},
      flatpickrConfigDayCompleteTo: {},
      prospectiveRetroactiveList: [
        {
          code: "F",
          name: "전진",
          id: `prospectiveRetroactive_${this.index}_F`,
        },
        {
          code: "B",
          name: "소급",
          id: `prospectiveRetroactive_${this.index}_B`,
        },
      ],
    };
  },
  watch: {
    voucher: {
      deep: true,
      immediate: true,
      handler(val) {
        // 전표 변경시 데이터 갱신
        const lst = val.voucherItems.filter(
          (voucher) => voucher.accountName.indexOf("선급비용(기간인식)") > -1,
        );
        if (lst.length > 0) {
          this.voucher.incomeLossDeptCode = lst[0].deptCode;
          this.voucher.incomeLossDeptName = lst[0].deptName;
          this.voucher.incomeLossServiceCode = lst[0].serviceCode;
          this.voucher.incomeLossServiceName = lst[0].serviceName;
        }
      },
    },
  },
  mounted() {
    this.flatpickrConfigDayCompleteFrom = {
      ...this.flatpickrConfigDay,
      minDate: "",
      maxDate: "",
    };
    this.flatpickrConfigDayCompleteTo = {
      ...this.flatpickrConfigDay,
      minDate: "",
      maxDate: "",
    };
  },
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
  },
};
</script>
