<template>
  <div class="blank_asset3">
    <div class="area_view">
      <div class="head_tbl">
        <div class="pos_left">
          <strong class="tit_text">계약/발주 정보<sup>*</sup></strong>
        </div>
      </div>
      <div class="tbl_comm tbl_view">
        <table>
          <colgroup>
            <col style="width: 171px" />
            <col />
            <col style="width: 171px" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>계약정보</th>
              <td>
                <div class="row_pack">
                  <button
                    type="button"
                    class="btn_small btn_fourthly"
                    @click="$emit('onClickContractinfo')"
                  >
                    계약정보 불러오기
                  </button>
                </div>
                <!--contractInfo-->
                <ConnectEditOnlyOne
                  :dataList="certificateItemData.contract"
                  titleKeyName="description"
                  @onClickConnect="onClickConnectContract"
                  @onClickRemove="(deleteData) => $emit('onClickRemoveContract', deleteData)"
                />
              </td>
              <th>발주정보</th>
              <td>
                <button
                  type="button"
                  class="btn_small btn_fourthly"
                  @click="$emit('onClickPopOrder')"
                >
                  발주서 불러오기
                </button>
                <ConnectEditOnlyOne
                  :dataList="certificateItemData.order"
                  @onClickConnect="onClickConnectOrder"
                  @onClickRemove="(deleteData) => $emit('onClickRemoveOrder', deleteData)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="area_view">
      <div class="head_tbl">
        <div class="pos_left">
          <strong class="tit_text">검수 정보</strong>
        </div>
      </div>
      <div class="tbl_comm tbl_view">
        <table>
          <colgroup>
            <col style="width: 171px" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>검수내역</th>
              <td>
                <button
                  type="button"
                  class="btn_small btn_fourthly"
                  @click="$emit('onClickPopInspect')"
                >
                  검수 정보 불러오기
                </button>
                <ConnectEdit
                  v-if="certificateItemData.contractInspect.length > 0"
                  :contractTitle="certificateItemData.contract.description"
                  :dataList="certificateItemData.contractInspect"
                  titleKeyName="calculateNum"
                  :disabledData="{ aprvlStatus: '-' }"
                  @onClickConnect="(item) => $emit('onClickPopInspectDetail', item)"
                  @onClickRemove="(deleteData) => $emit('onClickRemoveInspect', deleteData)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="tbl_comm tbl_list">
        <table>
          <colgroup>
            <col style="width: 140px" />
            <!-- No. -->
            <col style="width: 140px" />
            <!-- 정산 ID -->
            <col style="width: 140px" />
            <!-- 검수예정일 -->
            <col style="width: 140px" />
            <!-- 검수일 -->
            <col style="width: 140px" />
            <!-- 금번 검수 금액 -->
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>정산 ID</th>
              <th>검수예정일</th>
              <th>검수일</th>
              <th>금번 검수 금액</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in certificateItemData.contractInspect" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.calculateNum }}</td>
              <td>{{ item.calculateDate | date }}</td>
              <td>{{ item.inspectCompleteDate | date }}</td>
              <td>{{ item.inspectPrice | currency }}</td>
            </tr>
            <tr v-if="certificateItemData.contractInspect.length < 1">
              <td colspan="5">검수 리스트가 없습니다.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="tbl_foot">
        <div class="area_total">
          <dl>
            <dt>총 검수금액</dt>
            <dd>
              {{ totalInspectPrice | currency
              }}<span class="util_total">{{ certificateItemData.payment.currency }}</span>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectEdit from "@/components/common/connect/ConnectEdit";
import ConnectEditOnlyOne from "@/components/common/connect/ConnectEditOnlyOne";
import { dateToString } from "@/utils/dateUtils";

export default {
  name: "StatmentOtherMenuInfo",
  components: {
    ConnectEdit,
    ConnectEditOnlyOne,
  },
  props: {
    isInfra: Boolean,
    certificateItemData: Object,
    //inspectHistory: Array,
    contractInfo: Object,
  },
  computed: {
    totalInspectPrice() {
      if (
        !this.certificateItemData.contractInspect ||
        this.certificateItemData.contractInspect.length < 1
      )
        return "0";
      let result = 0;
      this.certificateItemData.contractInspect.forEach((inspect) => {
        result += Number(inspect.inspectPrice);
      });
      return result;
    },
  },
  methods: {
    // 계약정보 항목 클릭시
    onClickConnectContract(item) {
      // 임시 예시

      const { contractId } = item;
      const path = `${this.$routerPath.CONTRACT_VIEW}/${contractId}`;

      this.$windowOpen(path);
    },
    onClickConnectOrder(item) {
      const { orderCid } = item;
      const path = `${this.$routerPath.ORDER_VIEW}/${orderCid}`;

      this.$windowOpen(path);
    },
  },
};
</script>
