<template>
  <div ref="infoSearch" class="info_search" :class="classList" @mouseout="mouseout">
    <!-- 입력시 search_on 클래스 추가하여 자동완성 노출 -->
    <!-- <input type="text"
           class="inp_comm inp_search"
           :class="{ error: isError }"
           title="검색하기"
           v-model="keyword"
           @input="keyword = $event.target.value"
           @blur="blur()"
           @keyup="keyup"
           @focusin="focusin"
           @change="$emit('init')"
           @keyup.down="downFocus(0)"
           :disabled="disabled"
           :ref="inputRef"> -->
    <Input :value.sync="keyword" :isError="isError" :maxLength="100" @keyup="keyup" />
    <span class="ico_account ico_search" /><!-- 2019-06-27 이동 -->
    <div class="group_item" @mouseover="mouseover" @mouseout="mouseout">
      <em class="screen_out">검색 결과 목록</em>
      <ul v-if="resultList && resultList.length > 0" class="list_result">
        <li v-for="(item, index) in resultList" :key="index">
          <a
            ref="listIndex"
            href="javascript:void(0);"
            class="link_option"
            @click.prevent="selectItem(item)"
            @keyup.down="downFocus(1)"
            @keyup.up="upFocus(1)"
            >{{ item.display }}</a
          >
        </li>
      </ul>
      <p v-else class="desc_empty">검색결과가 없습니다.</p>
    </div>
    <!-- <span class="ico_account ico_search"></span> 2019-06-27 -->
  </div>
</template>

<script>
import Input from "@/_approval/components/common/input/Input";
import ApiService from "@/services/ApiService";

export default {
  name: "CommInpSearch",
  components: {
    Input,
  },
  props: {
    url: String,
    displayData: Function,
    isDisplayResult: Boolean,
    initValue: {
      type: String,
      default: "",
    },
    method: {
      type: String,
      default: "get",
    },
    inputRef: {
      type: String,
      default: "keyword",
    },
    isSearchByInitValue: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    classList: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSearchOn: false,
      keyword: "",
      resultList: [],
      indexFocus: 0,
    };
  },
  watch: {
    initValue: function (newVal, oldVal) {
      this.keyword = this.initValue;
      if (this.isSearchByInitValue) {
        this.search(this.keyword);
      }
    },
  },
  created() {
    if (this.initValue) {
      this.keyword = this.initValue;
    }
    if (this.isSearchByInitValue) {
      this.search(this.keyword);
    }
  },
  methods: {
    keyup(key) {
      this.isSearchOn = true;
      this.isError = false;
      let keyCode = key.which;
      if (keyCode === 40) {
        this.$refs.listIndex[0].focus();
      } else if (
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        keyCode === 8 ||
        keyCode === 40 ||
        keyCode === 32
      ) {
        this.resultList = [];
        let keyword = this.keyword.trim();
        this.search(keyword);
      } else if (keyCode === 27) {
        this.$refs.infoSearch.classList.remove("search_on");
      }
    },
    async search(keyword) {
      if (this.method === "get") {
        const params = { [this.inputRef]: keyword, page: 1, size: 99 };
        const result = await ApiService.shared.get(this.url, {
          params,
        });

        this.resultList = result.data;
        this.displayData(result);
      } else {
        this.$http.post(this.url, { [`${this.inputRef}`]: keyword }).then((res) => {
          this.resultList = res.data;
          this.displayData(res.data);
        });
      }
      if (!this.isSearchByInitValue) {
        this.$refs.infoSearch.classList.add("search_on");
      }
    },
    selectItem(item) {
      this.$refs.infoSearch.classList.remove("search_on");
      this.$emit("select", item);
      this.keyword = "";

      if (this.isDisplayResult) {
        for (let prop in this.$refs) {
          this.$refs[prop].value = item.display;
        }
        this.keyword = item.display;
      } else {
        for (let prop in this.$refs) {
          this.$refs[prop].value = "";
        }
      }
    },
    focusin() {
      if (this.keyword) {
        this.search(this.keyword);
      }
    },
    focusout(e) {
      this.$refs.infoSearch.classList.remove("search_on");
    },
    blur(e) {
      if (!this.isSearchOn) {
        this.$refs.infoSearch.classList.remove("search_on");
      }
    },
    mouseover(e) {
      this.isSearchOn = true;
    },
    mouseout(e) {
      this.isSearchOn = false;
    },
    downFocus(e) {
      const targetList = this.$refs.listIndex;
      if (e === 0) {
        if (targetList.length > 0) {
          targetList[0].focus();
          this.indexFocus = 0;
        }
      } else {
        this.indexFocus = this.indexFocus + 1;
        if (targetList.length > this.indexFocus) {
          targetList[this.indexFocus].focus();
        } else {
          this.indexFocus = 0;
          targetList[0].focus();
        }
      }
    },
    upFocus(e) {
      const targetList = this.$refs.listIndex;
      if (this.indexFocus === 0) {
        this.indexFocus = targetList.length;
        targetList[targetList.length - 1].focus();
      } else {
        this.indexFocus = this.indexFocus - 1;
        targetList[this.indexFocus].focus();
      }
    },
  },
};
</script>
