<template>
  <div v-if="!isAutoType">
    <div class="file_comm">
      <label class="btn_fourthly btn_small" :for="_uid">파일 첨부하기</label>
      <input
        :id="_uid"
        ref="files"
        type="file"
        class="inp_file"
        :multiple="canMultiple"
        :disabled="isDisabled"
        files="[]"
        @change="attachFile()"
      />
    </div>
    <p v-if="!isAutoType" class="desc_file_tip tc_red">
      <span class="ico_account ico_exclam_r" />ICO, EXE, DMG, GIF 파일 첨부 불가
    </p>
    <FileWriteItem
      v-for="(fileItemData, index) in dataList"
      :key="index"
      :index="index"
      :fileItemData="isComments ? fileItemData.attachFile : fileItemData"
      :isComments="isComments"
      @removeFile="removeFile"
    />
    <!-- <FileWriteItem
      v-for="(fileItemData, index) in dataList"
      :key="index"
      :index="index"
      :fileItemData="isComments ? fileItemData.attachFile : fileItemData"
      :isComments="isComments"
      :comments="fileItemData.comments"
      @removeFile="removeFile"/> -->
    <!-- <div class="file_view"
      v-for="(file, key) in dataList"
      :key="key">
      <button type="button" class="btn_delete file_sm" :click="removeFile(key, file)">
        <span :class="[ 'ico_account ico_filename', getClassByFileType(file) ]">{{ file.attachType }}</span>
        <div class="group_filename">
          <span class="txt_type">.{{ file.attachExt }}</span>
          <span class="txt_file">
            <span class="inner_txt_file" ref="fileNameEl">{{ fileName(file) }}</span>
          </span>
        </div>
        <span class="ico_account ico_cross">삭제</span>
      </button>
      <input
        v-if="isComments"
        type="text"
        class="inp_comm"
        v-model="file.comments"
        maxLength="40"
        placeholder="첨부파일 상세설명을 입력하세요 (최대 40자)"
      /> -->
  </div>
  <div v-else>
    <FileViewItem
      v-for="(fileItemData, index) in dataList"
      :key="index"
      :index="index"
      :fileItemData="isComments ? fileItemData.attachFile : fileItemData"
      :isComments="isComments"
      :comments="fileItemData.comments"
      @fileDownload="fileDownload"
    />
  </div>
  <!-- </div> -->
</template>

<script>
import FileWriteItem from "./FileWriteItem";
import FileViewItem from "./FileViewItem";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer";
import path from "path";
import ApiService from "@/services/ApiService";
import { downloadFile } from "@/utils/fileUtils";

export default {
  name: "StatementFileWrite",
  components: {
    FileWriteItem,
    FileViewItem,
  },
  mixins: [CommLayerMixin],
  props: {
    isAutoType: {
      type: Boolean,
      default: false,
    },
    dataList: Array,
    descFileTip: String,
    invalidFileExt: Array,
    isComments: {
      type: Boolean,
      default: false,
    },
    canMultiple: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async attachFile(e) {
      const files = this.$refs.files.files;
      if (!files.length) return;
      const file = files[0];
      // var files = e.target.files || e.dataTransfer.files;
      // if (!files.length)  return;

      if (this.hasInvalidFileExt(files[0].name)) {
        this._showLayer({
          content: "ICO, EXE, DMG, GIF 확장자 파일 첨부 불가.",
          type: "alert",
        });
        return;
      }

      const res = await ApiService.shared.postFiles(files);
      if (parseInt(res.code) !== 200) {
        throw "파일 업로드 실패";
      }

      this.$emit("fileUploaded", res.data);
    },
    hasInvalidFileExt(filename) {
      if (!this.invalidFileExt || this.invalidFileExt.length === 0) {
        return false;
      }

      const ext = path.extname(filename);
      return this.invalidFileExt.some((e) => ext.toLowerCase() === e);
    },
    async removeFile(index, fileItemData) {
      try {
        const res = await ApiService.shared.deleteData(
          this.$apiPath.ATTACH_FILE + "/" + fileItemData.sysFilePath,
        );

        if (parseInt(res.code) !== 200) {
          throw res;
        }

        //this.dataList.splice(index, 1);
        this.$emit("fileRemoved", fileItemData);
      } catch (e) {
        this._showLayer({
          content: e.text || "파일 삭제 실패. 관리자에게 문의해주세요.",
          type: "alert",
        });
      }
    },
    async fileDownload(file) {
      const path = `${this.$apiPath.ATTACH_FILE}/${file.sysFilePath}`;
      const result = await ApiService.shared.getFile(path, null);

      downloadFile(result, file.orgFile);
    },
  },
};
</script>

<style scoped>
.file_comm {
  display: inline-block;
  position: relative;
}
.file_comm label {
  display: block;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  cursor: pointer;
}
.file_comm input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.01;
}
.desc_file_tip {
  display: inline-block;
  padding: 8px;
  font-size: 11px;
  line-height: 16px;
  color: #bbb;
  vertical-align: top;
}
.file_comm.disabled label[disabled="disabled"] {
  color: #bbb;
  background-color: #fbfbfb;
  border-color: #e1e1e1;
}
.file_comm.disabled input[disabled="disabled"] {
  border-color: #e1e1e1;
  color: #bbb;
  background-color: #fbfbfb;
}
.desc_file_tip .ico_exclam_r {
  margin-right: 4px;
}
.desc_file_tip + .desc_file_tip {
  padding-left: 0;
}
</style>
