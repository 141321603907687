<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">원천세</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>사업장</th>
            <td colspan="3">
              {{ withholdingTax.businessPlacesName }}
            </td>
          </tr>
          <tr>
            <th>세금유형<sup>*</sup></th>
            <td>
              <comm-opt
                :classList="['type_medium']"
                :optionDesc="'세금유형 선택상자'"
                :optionName="withholdingTax.withholdingTaxName || '세금유형을 선택해주세요'"
                :optionList="optionList"
                :eventName="'select'"
                @select="selectSearchType"
              />
            </td>
            <th>하위분류</th>
            <td>
              <comm-opt
                :isDisabled="subOptionList.length == 0"
                :classList="['type_medium']"
                :optionDesc="'하위분류 선택상자'"
                :optionName="withholdingTax.subdivisionName || '하위분류를 선택해주세요'"
                :optionList="subOptionList"
                :eventName="'select'"
                @select="selectSubDivisionCode"
              />
            </td>
          </tr>
          <tr>
            <th>세액회사 부담<sup>*</sup></th>
            <td>
              <Radio
                :dataList="taxBurdenList"
                name="taxBurdenYn"
                :selectedId.sync="withholdingTax.taxBurdenYn"
              />
            </td>
            <th>이메일<sup>*</sup></th>
            <td>
              <InputEmail
                :emailName.sync="withholdingTax.email.name"
                :emailDomain.sync="withholdingTax.email.domain"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import CommOpt from "@/_approval/components/common/CommOpt";
import Radio from "@/_approval/components/common/radio/Radio";
import InputEmail from "@/_approval/components/common/input/InputEmail";
// import EmailModel from '@/_approval/models/EmailModel'
import ApiService from "@/services/ApiService";

export default {
  name: "StatementHoldingTax",
  components: {
    CommOpt,
    Radio,
    InputEmail,
  },
  props: {
    withholdingTax: Object,
  },
  data() {
    return {
      optionList: [],
      subOptionList: [],
      taxCodeList: [],
      subdivisionCodeList: [],
      taxBurdenList: [
        { code: "Y", name: "예" },
        { code: "N", name: "아니오" },
      ],
      // email: {},
      selectedTaxCode: "",
      selectedSubCode: "",
      originSubDivisionCode: [],
    };
  },
  computed: {},
  mounted() {
    // this.email.setDataByString( this.withholdingTax.email );

    this.getWithHolding();
    //this.getWithHoldingSub();
    this.getBusinessPlace();
  },
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    // onChangedEmail( changedValue ){
    //   console.log(changedValue);
    //   this.$emit('update:withholdingTax.email',changedValue)
    // },
    selectSearchType(type) {
      this.withholdingTax.withholdingTaxCode = type.code; // 세금유형코드
      this.withholdingTax.withholdingTaxName = type.name; // 세금유형명
      this.withholdingTax.withholdingTaxRate = type.rate; // 세금비율
      this.withholdingTax.subdivisionCode = ""; // 하위분류코드 리셋
      this.withholdingTax.subdivisionName = ""; // 하위분류명 리셋

      this.getWithHoldingSub();
    },
    selectSubDivisionCode(type) {
      this.withholdingTax.subdivisionCode = type.code; // 하위분류코드
      this.withholdingTax.subdivisionName = type.name; // 하위분류명
    },
    async getWithHolding() {
      const path = `${this.$apiPath.VOUCHER_WITHHOLDING}`;

      const res = await ApiService.shared.getData(path);

      if (res.data) {
        this.optionList = res.data.map((item) => {
          const obj = {};
          obj.code = item.withholdingTaxCode;
          obj.name = item.withholdingTaxNm;
          obj.rate = item.withholdingTaxRate;
          return obj;
        });
        this.taxCodeList = res.data;

        // 초기 세팅
        if (!this.withholdingTax.withholdingTaxCode) {
          this.selectSearchType(this.optionList[0]);
        }
      }
      this.getWithHoldingSub();
    },
    async getWithHoldingSub() {
      let taxCode =
        this.withholdingTax.withholdingTaxCode == ""
          ? this.taxCodeList[0].withholdingTaxCode
          : this.withholdingTax.withholdingTaxCode;

      const path = `${this.$apiPath.VOUCHER_WITHHOLDING_SUB}?withholdingTaxCode=${taxCode}&divName=`;

      const res = await ApiService.shared.getData(path);

      if (res.data) {
        this.subdivisionCodeList = res.data;
        this.subOptionList = res.data.map((item) => {
          const obj = {};
          obj.code = item.withholdingTaxSubDivCode;
          obj.name = item.withholdingTaxSubDivNm;
          return obj;
        });
        const isSubdivisionData = this.subdivisionCodeList.length > 0;
        this.$emit("setIsSubdivisionData", isSubdivisionData);

        // 초기 세팅
        if (!this.withholdingTax.subdivisionCode && this.subOptionList.length > 0) {
          // console.log(this.subOptionList[0]);
          this.selectSubDivisionCode(this.subOptionList[0]);
        }

        // "withholdingTaxSubDivCode": "940909", subdivisionCode
        // "withholdingTaxSubDivNm": "기타자영",subdivisionName
        // "expenseRt": "0"
      }
    },
    async getBusinessPlace() {
      // businessPlaces ?
      const path = `${this.$apiPath.VOUCHER_ORG_BUSINES}`;

      const res = await ApiService.shared.getData(path);

      if (res) {
        //this.withholdingTax.businessPlacesName =
        const findPan = res.data.filter((item) => {
          return item.belongingBusinessPlaces == "PAN";
        });

        this.withholdingTax.businessPlacesName = findPan[0].belongingBusinessPlacesNm;
        this.withholdingTax.businessPlaces = findPan[0].belongingBusinessPlaces;
      }
    },
  },
};
</script>
