<template>
  <tr :class="{ tr_select: isSelected }" @click="$emit('update:selectedBill', rowData)">
    <td>{{ rowData.etaxDate }}</td>
    <td class="td_ellip" :title="rowData.ntsPartnerNm">
      {{ rowData.ntsPartnerNm }}
    </td>
    <td class="td_ellip" :title="rowData.partnerNm">
      {{ rowData.partnerNm }}
    </td>
    <td>{{ rowData.corporateNum | corporateNum }}</td>
    <td>{{ rowData.price | currency }}</td>
    <td>{{ rowData.tax | currency }}</td>
    <td class="td_ellip" :title="rowData.email1">
      {{ rowData.email1 }}
    </td>
    <td class="td_ellip" :title="rowData.email2 || '-'">
      {{ rowData.email2 || "-" }}
    </td>
    <td>{{ rowData.ntsId || "-" }}</td>
    <td v-if="serachedCalculateYn">
      {{ rowData.erpVoucherNo || "-" }}
    </td>
  </tr>
</template>
<script>
export default {
  name: "PopBillLine",
  props: {
    rowData: Object,
    selectedBill: Object,
    serachedCalculateYn: Boolean,
  },
  computed: {
    isSelected() {
      if (!this.selectedBill) return false;
      return this.selectedBill.ntsId == this.rowData.ntsId;
    },
  },
};
</script>
