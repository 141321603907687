<template>
  <Connect class="connect_edit">
    <ul
      v-if="dataList && (titleKeyName ? dataList[titleKeyName] : dataList.title)"
      class="list_connect"
    >
      <li :class="{ disabled: isDisabled }">
        <span class="ico_purchase ico_connect_sm" />
        <button
          v-if="!isDisabled"
          type="button"
          class="btn_connect"
          @click="$emit('onClickConnect', dataList)"
        >
          <span class="tit_connect">{{
            titleKeyName ? dataList[titleKeyName] : dataList.title
          }}</span>
        </button>
        <span v-else class="tit_connect">{{
          titleKeyName ? dataList[titleKeyName] : dataList.title
        }}</span>
        <button type="button" class="btn_delete" @click="$emit('onClickRemove', dataList)">
          <span class="ico_purchase ico_cross">삭제</span>
        </button>
      </li>
    </ul>
  </Connect>
</template>

<script>
import Connect from "@/components/common/connect/Connect";

export default {
  name: "ConnectEditOnlyOne",
  components: {
    Connect,
  },
  props: {
    dataList: Object,
    titleKeyName: String,
    isDisabled: Boolean,
  },
};
</script>
