<template>
  <tr :class="{ tr_select: selectedProject.projectCode == rowData.projectCode }">
    <td>
      <div class="box_choice type_alone">
        <div class="item_choice">
          <!-- disabled 상태시 disabled 클래스 추가 -->
          <input
            :id="rowData.projectCode"
            v-model="selectedProjectSync"
            type="radio"
            class="inp_choice"
            name="projectData"
            :value="value"
          />
          <label class="lab_choice" :for="rowData.projectCode">
            <span class="ico_account ico_rdo" />
          </label>
        </div>
      </div>
    </td>
    <!-- <td>{{ rowData.category }}</td> -->
    <td>{{ rowData.projectCode }}</td>
    <td class="td_ellip" :title="rowData.projectName" v-html="rowData.projectName"></td>
  </tr>
</template>

<script>
export default {
  name: "PopProjectCodeListLine",
  props: {
    rowData: Object,
    selectedProject: Object,
  },
  computed: {
    value() {
      let obj = {};
      obj.projectCode = this.rowData.projectCode;
      obj.projectName = this.rowData.projectName;
      return obj;
    },
    selectedProjectSync: {
      get() {
        return this.selectedProject;
      },
      set(value) {
        this.$emit("update:selectedProject", value);
      },
    },
  },
};
</script>
