<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <!-- <strong class="tit_text">지출정산서 전표정보</strong> -->
        <strong class="tit_text">전표정보</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <!-- <tr>
            <th>기안번호</th>
            <td colspan="3">{{ voucher.voucherCid }}</td>
          </tr> -->
          <tr>
            <th>전표일자<sup>*</sup></th>
            <td>
              <DatePicker
                :dateString.sync="voucher.voucherDate"
                @dateString="listenToOnChangeDayComplete"
              />
            </td>
            <th>증빙일자<sup>*</sup></th>
            <td>
              <DatePicker :dateString.sync="voucher.proofDate" />
            </td>
          </tr>
          <tr>
            <th>저장상태로 전표 생성</th>
            <td>
              <div class="box_choice">
                <div class="item_choice">
                  <!-- disabled 상태시 disabled 클래스 추가 -->
                  <input
                    id="voucherSaveModeYn"
                    v-model="voucher.voucherSaveModeYn"
                    type="checkbox"
                    class="inp_choice"
                    name="voucherSaveModeYn"
                    :value="true"
                  />
                  <label class="lab_choice" for="voucherSaveModeYn">
                    <span class="ico_account ico_check" />저장상태로 전표 생성
                  </label>
                </div>
              </div>
            </td>
            <th>전표생성일</th>
            <td>-</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tbl_comm tbl_list tbl_list_sm12">
      <table>
        <colgroup>
          <col style="width: 46px" />
          <!-- radio -->
          <col style="width: 52px" />
          <!-- No. -->
          <col />
          <!-- 계정과목 -->
          <col style="width: 144px" />
          <!-- 세목 -->
          <col style="width: 144px" />
          <!-- 부서 -->
          <col style="width: 144px" />
          <!-- 프로젝트코드 -->
          <col style="width: 144px" />
          <!-- 거래처 -->
          <col style="width: 108px" />
          <!-- 차변 -->
          <col style="width: 108px" />
          <!-- 대변 -->
          <col style="width: 90px" />
          <!-- 삭제 -->
        </colgroup>
        <thead>
          <tr>
            <th />
            <th>No.</th>
            <th>계정과목<sup>*</sup></th>
            <th>세목</th>
            <th>부서<sup>*</sup></th>
            <th>프로젝트코드<sup>*</sup></th>
            <th>거래처<sup>*</sup></th>
            <th>차변<sup>*</sup></th>
            <th>대변<sup>*</sup></th>
            <th>
              <!-- <button type="button" class="btn_small btn_secondary" @click="addVoucherItem">추가</button> -->
            </th>
          </tr>
        </thead>
        <tbody>
          <StatementVoucherListLine
            v-for="(item, index) in voucherItems"
            :key="index"
            :index="index"
            :rowData="item"
            :partnerName.sync="voucher.erpPartnerName"
            :assetOpenIndex="assetOpenIndex"
            @onUpdateAssetOpen="$emit('onUpdateAssetOpen', index)"
            @addVoucherItem="addVoucherItem"
            @removeVoucherLine="removeVoucherLine"
            @onClickPopAccount="$emit('onClickPopAccount', index)"
            @onClickPopTax="$emit('onClickPopTax', index)"
            @onClickPopDept="$emit('onClickPopDept', index)"
            @onClickSearchProjectCode="$emit('onClickSearchProjectCode', index)"
            @onClickPopPartner="$emit('onClickPopPartner', index, 'voucher')"
            @setTotalDebit="setTotalDebit"
          />
        </tbody>
      </table>
    </div>
    <div class="tbl_foot">
      <div class="area_total">
        <dl>
          <dt>총 차변<span v-if="isTaxType" class="txt_info">(차변+세액)</span></dt>
          <dd>
            {{ totalDebit | currency }}<span class="util_total">{{ voucher.currency }}</span>
          </dd>
          <dt>총 대변</dt>
          <dd>
            {{ totalCredit | currency }}<span class="util_total">{{ voucher.currency }}</span>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import CommInpSearch from "@/_approval/components/common/CommInpSearch";
import StatementVoucherListLine from "./StatementVoucherListLine";
import VoucherItemsModel from "../../VoucherItemsModel";
// import { default as CommFlatpickrMixin } from '@/_approval/mixins/common/commFlatpickr.js';
import { getMoneyToNumber } from "@/utils/stringUtils";

import DatePicker from "@/components/common/calendar/DatePicker";

export default {
  name: "StatementVoucher",
  components: { CommInpSearch, StatementVoucherListLine, DatePicker },
  //mixins: [CommFlatpickrMixin],
  props: {
    voucher: Object,

    isAddApprover: Boolean,
    proofType: String,
    taxAmount: String,
    assetOpenIndex: Number,
  },
  computed: {
    voucherItems() {
      return this.voucher.voucherItems.filter((item) => item.surtaxYn === false);
    },
    // 세액 적용 여부
    isTaxType() {
      const proofType = this.proofType;
      return proofType != "WT" && proofType != "NN";
    },
    totalDebit() {
      let totalResult = 0;
      this.voucherItems.forEach((voucherItem) => {
        if (voucherItem.crdr === "DR" && voucherItem.debit) {
          totalResult += getMoneyToNumber(voucherItem.debit);
        }
      });
      return totalResult + getMoneyToNumber(this.taxAmount);
    },
    totalCredit() {
      let totalResult = null;
      this.voucherItems.forEach((voucherItem) => {
        totalResult += getMoneyToNumber(voucherItem.credit);
      });
      return totalResult;
    },
  },
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    addVoucherItem() {
      // 정책 : 차변만 추가할수 있다 ( 1차 )
      const voucherLength = this.voucher.voucherItems.length;
      const voucher = new VoucherItemsModel();
      if (voucherLength > 0) {
        // const orderNumber = this.voucher.voucherItems[voucherLength-1].orderNum;
        const orderNumber = this.voucher.voucherItems[0].orderNum;
        voucher.orderNum = orderNumber + 1;
        voucher.serviceCode = this.voucher.voucherItems[0].serviceCode;
        voucher.partner = this.voucher.voucherItems[0].partner;
        //voucher.accountCode = this.voucher.voucherItems[0].accountCode;
        //voucher.accountName = this.voucher.voucherItems[0].accountName;
        voucher.infraAccountYn = false;
        voucher.deptCode = this.voucher.voucherItems[0].deptCode;
        voucher.deptName = this.voucher.voucherItems[0].deptName;
        voucher.debit = "";
        voucher.origin = false;
        voucher.crdr = "DR";
      }
      // this.voucher.voucherItems.push(voucher);
      this.voucher.voucherItems.splice(1, 0, voucher);

      // 추가했을때 자산 전표가 있고, 첫번째가 아닐경우 index +1 항목 활성화
      const updateAssetOpenIndex =
        this.assetOpenIndex != null && this.assetOpenIndex != 0 ? this.assetOpenIndex + 1 : "";
      this.$emit("setAssetAccount", updateAssetOpenIndex);

      if (this.voucher.voucherItems[0].suplyPriceFlag) {
        this.voucher.voucherItems[0].debit = "";
      }
    },
    removeVoucherLine(idx) {
      if (idx == null) return;

      this.voucher.voucherItems.splice(idx, 1);
      // 자산의 전표가 제거되고, 다른 자산전표가 있을경우 활성화 자산항목 대체

      let updateAssetOpenIndex = "";
      // 자산전표가아니고 활성화된 자산전표 위 항목이 삭제된 경우 index - 1 항목 활성화
      if (!this.voucher.voucherItems[idx].infraAccountYn && idx < this.assetOpenIndex) {
        updateAssetOpenIndex = this.assetOpenIndex - 1;
      }
      this.$emit("setAssetAccount", updateAssetOpenIndex);
    },
    listenToOnChangeDayComplete() {
      this.$emit("onChangeVoucherDate");
    },
    setTotalDebit() {
      const voucherLength = this.voucher.voucherItems.length;
      const lastIdx = voucherLength - 1;

      let totalDebit = 0;
      this.voucher.voucherItems.forEach((item) => {
        if (item.crdr === "DR") {
          totalDebit += Number(item.debit);
        }
      });

      //const tax = Math.round(totalDebit) * (10 / 100) ;
      // totalDebit = tax +
      this.voucher.voucherItems[lastIdx].credit = String(totalDebit);
    },
  },
};
</script>
