<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 464px">
      <div class="layer_head">
        <strong class="tit_popup">부서 검색</strong>
      </div>
      <div class="layer_body">
        <!-- 필터바 -->
        <div class="box_sorting">
          <strong class="screen_out">부서 검색하기</strong>
          <ul class="list_sorting">
            <li class="item_full">
              <!-- <em class="tit_txt">검색어</em> -->
              <div class="row_pack">
                <Input
                  style="width: 240px"
                  placeholder="검색어를 입력하세요"
                  :value.sync="searchForm.searchKeyword"
                />
                <button
                  type="button"
                  class="btn_medium btn_primary"
                  @click.prevent="clickSearchButton"
                >
                  검색
                </button>
              </div>
            </li>
          </ul>
        </div>
        <!-- 법인카드 사용내역 리스트 -->
        <div class="tbl_comm tbl_list tbl_list_sm8 tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 544px); max-height: 294px; min-height: 49px"
          >
            <table>
              <colgroup>
                <col style="width: 52px" />
                <!-- 선택 -->
                <col />
                <!-- 사용금액 -->
              </colgroup>
              <thead>
                <tr>
                  <th style="width: 52px" />
                  <th style="width: 348px">부서</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="deptDataList.length === 0">
                  <td colspan="9">조회된 부서가 없습니다.</td>
                </tr>
                <tr
                  v-for="(item, index) in deptDataList"
                  :key="index"
                  :class="{ tr_select: isSelected(item) }"
                >
                  <td>
                    <div class="box_choice type_alone">
                      <div class="item_choice">
                        <!-- disabled 상태시 disabled 클래스 추가 -->
                        <input
                          :id="item.departmentCode"
                          v-model="selectedDept"
                          type="radio"
                          class="inp_choice"
                          name="selectedCorporateCard"
                          :value="item"
                        />
                        <label class="lab_choice" :for="item.departmentCode">
                          <span class="ico_account ico_rdo" />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td class="td_ellip">
                    {{ item.departmentName }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            취소
          </button>
          <button
            type="button"
            class="btn_medium btn_secondary"
            :disabled="isDisabledMoveBtn"
            @click.prevent="onClickSelected"
          >
            선택
          </button>
          <!-- <button v-if="isBillSeleted" type="button" class="btn_medium btn_primary" :disabled="isDisabledSeletedBtn" @click.prevent="onClickSelected">선택</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Input from "@/_approval/components/common/input/Input";
import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "PopDept",
  components: {
    Input,
  },
  props: {
    deptName: String,
    selectedData: Object,
  },
  data() {
    return {
      searchForm: {
        searchKeyword: "",
      },
      deptDataList: [
        // api 연동 전 임시 부서 데이터
        // { deptCode:'00001', deptName: '부서명1' },
        // { deptCode:'00002', deptName: '부서명2' },
        // { deptCode:'00003', deptName: '부서명3' },
        // { deptCode:'00004', deptName: '부서명4' },
        // // { deptCode:'00005', deptName: '부서명5' },
        // { deptCode:'00006', deptName: '부서명6' },
        // { deptCode:'00007', deptName: '부서명7' },
        // { deptCode:'00008', deptName: '부서명8' },
        // { deptCode:'00009', deptName: '부서명9' },
      ],
      selectedDept: {}, // 선택한 부서 데이터
    };
  },
  computed: {
    isDisabledMoveBtn() {
      return !this.selectedDept.departmentCode;
    },
  },
  mounted() {
    this.selectedDept = this.selectedData;
    // this.getDeptInfo();
    // 구매요청서에 해당하는 구매담당자의 deptName
    const userData = {};

    if (this.deptName != "") {
      userData.deptName = this.deptName;
    }
    this.getDeptList(userData);
  },
  methods: {
    async getDeptInfo() {
      const user = LocalStorageManager.shared.getUserData();
      const path = `${this.$apiPath.APRVL_ORGEMP}/${user.empNo}`;

      const result = await ApiService.shared.getData(path);
      const obj = {};
      if (result.data) {
        obj.deptName = result.data.deptCodeName;
        obj.empNo = result.data.empNo;
      }
      //   if(result.data) {
      //     obj.deptName = result.data.deptCodeName;
      //     obj.empNo = result.data.empNo;

      //     this.getDeptList(obj);
      //   }
    },
    async getDeptList(userData) {
      let path = `${this.$apiPath.VOUCHER_ORG_DEPARTMENT}`;

      if (userData.deptName) path += `?deptName=${userData.deptName}`;
      if (userData.empNo) path += `&empno=${userData.empNo}`;

      const result = await ApiService.shared.getData(path);

      if (result.data && result.data.length > 0) {
        this.deptDataList = result.data.map((item) => {
          let obj = {};
          obj.departmentCode = item.departmentCode;
          obj.departmentName = item.departmentName;
          return obj;
        });
      } else {
        this.deptDataList = [];
      }
    },
    clickSearchButton() {
      // 부서는 구매담당자? 혹은 정산서 작성하는사람 ?
      const obj = {};
      if (this.searchForm.searchKeyword) obj.deptName = this.searchForm.searchKeyword;

      this.getDeptList(obj);
    },
    isSelected(item) {
      return this.selectedDept.departmentCode == item.departmentCode;
    },
    onClickSelected() {
      this.$emit("onClickComplete", this.selectedDept);
    },
  },
};
</script>
