<template>
  <tr :class="{ tr_select: isChecked }">
    <td>
      <div class="box_choice type_alone">
        <div class="item_choice">
          <input
            :id="rowData.calculateNum"
            v-model="checkedNamesSync"
            type="checkbox"
            class="inp_choice"
            name="inspectItem"
            :value="rowData"
          />
          <label class="lab_choice" :for="rowData.calculateNum">
            <span class="ico_account ico_check" />
          </label>
        </div>
      </div>
    </td>
    <td>
      <button class="link_detail cell_center" @click="$emit('onClickInspectDetail', rowData)">
        {{ rowData.calculateNum }}
      </button>
    </td>
    <td>{{ rowData.calculateDate | date }}</td>
    <td>{{ rowData.inspectCompleteDate | date }}</td>
    <td>{{ rowData.inspectPrice | currency }}</td>
    <td>{{ stateCodeName(rowData.stateCode) }}</td>
  </tr>
</template>

<script>
import inspectStatusList from "@/_approval/constants/inspectStatusList";

export default {
  name: "PopInspectListLine",
  props: {
    rowData: Object,
    checkedNames: Array,
  },
  computed: {
    checkedNamesSync: {
      get() {
        return this.checkedNames;
      },
      set(value) {
        this.$emit("update:checkedNames", value);
      },
    },
    isChecked() {
      return Boolean(
        this.checkedNames.find((item) => item.calculateNum == this.rowData.calculateNum),
      );
    },
  },
  methods: {
    stateCodeName(stateCode) {
      const result = inspectStatusList.find((item) => item.code === stateCode);
      if (!result) return "";

      return result.text;
    },
  },
};
</script>
