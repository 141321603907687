<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 584px">
      <div class="layer_head">
        <strong class="tit_popup">계정과목 검색</strong>
      </div>
      <div class="layer_body">
        <!-- 필터바 -->
        <div class="box_sorting">
          <strong class="screen_out">계정과목 검색하기</strong>
          <ul class="list_sorting">
            <li class="item_full">
              <em class="tit_txt">검색어</em>
              <div class="cont_sorting">
                <div class="row_pack">
                  <Input
                    style="width: 240px"
                    placeholder="검색어를 입력하세요"
                    :value.sync="searchForm.searchKeyword"
                  />
                  <button
                    type="button"
                    class="btn_medium btn_primary"
                    @click.prevent="clickSearchButton"
                  >
                    검색
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 법인카드 사용내역 리스트 -->
        <div class="tbl_comm tbl_list tbl_list_sm8 tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 544px); max-height: 294px; min-height: 49px"
          >
            <table>
              <colgroup>
                <col style="width: 52px" />
                <!-- 선택 -->
                <col style="width: 164px" />
                <!-- 코드 -->
                <col />
                <!-- 사용금액 -->
              </colgroup>
              <thead>
                <tr>
                  <th style="width: 52px" />
                  <th style="width: 164px">코드</th>
                  <th style="width: 304px">계정과목</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in accountDataList"
                  :key="index"
                  :class="{ tr_select: isSelected(item) }"
                >
                  <td>
                    <div class="box_choice type_alone">
                      <div class="item_choice">
                        <!-- disabled 상태시 disabled 클래스 추가 -->
                        <input
                          :id="item.accountCode"
                          v-model="selectedAccount"
                          type="radio"
                          class="inp_choice"
                          name="selectedCorporateCard"
                          :value="item"
                        />
                        <label class="lab_choice" :for="item.accountCode">
                          <span class="ico_account ico_rdo" />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td>{{ item.accountCode }}</td>
                  <td class="td_ellip">
                    {{ item.accountName }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            취소
          </button>
          <button
            type="button"
            class="btn_medium btn_secondary"
            :disabled="isDisabledMoveBtn"
            @click.prevent="onClickSelected"
          >
            선택
          </button>
          <!-- <button v-if="isBillSeleted" type="button" class="btn_medium btn_primary" :disabled="isDisabledSeletedBtn" @click.prevent="onClickSelected">선택</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Input from "@/_approval/components/common/input/Input";
import ApiService from "@/services/ApiService";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";

export default {
  name: "PopAccount",
  components: {
    Input,
  },
  mixins: [CommLayerMixin],
  props: {
    voucherDate: String,
    isInfra: {
      type: Boolean,
      default: false,
    },
    crDr: String,
    selectedData: Object,
  },
  data() {
    return {
      searchForm: {
        searchKeyword: "",
      },
      accountDataList: [],
      selectedAccount: {}, // 선택한 계정과목 데이터
    };
  },
  computed: {
    isDisabledMoveBtn() {
      return !this.selectedAccount.accountCode;
    },
  },
  mounted() {
    this.selectedAccount = this.selectedData;
    //  팝업 오픈시 데이터를 조회하는것으로 수정.
    this.clickSearchButton();
  },
  methods: {
    async clickSearchButton() {
      // if(this.searchForm.searchKeyword == '') {
      //   const layer = {}
      //   layer.type = 'alert'
      //   layer.order = 2
      //   layer.content = '검색어를 입력해주세요'
      //   this._showLayer(layer);

      //   return;
      // }

      // 인프라정산서 계정 과목 조회시 DR 로 조회
      //const crdr = this.isInfra ? 'CR' : 'DR';
      const crdr = this.crDr;

      let path = `${this.$apiPath.VOUCHER_ACCOUNCT}?accountName=${this.searchForm.searchKeyword}&crdr=${crdr}&voucherDate=${this.voucherDate}`;

      const result = await ApiService.shared.getData(path);

      if (result.data) {
        this.accountDataList = result.data;
      }
    },
    isSelected(item) {
      return this.selectedAccount.accountCode == item.accountCode;
    },
    onClickSelected() {
      this.$emit("onClickComplete", this.selectedAccount);
    },
  },
};
</script>
