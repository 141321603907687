<template>
  <tr v-if="isShowVoucherLine">
    <td>
      <div v-if="rowData.infraAccountYn" class="box_choice">
        <RadioItem
          :id="'assetOpen' + index"
          name="assetOpen"
          :selectedId.sync="assetOpenIndexSync"
          @update:selectedId="$emit('onUpdateAssetOpen')"
        />
      </div>
    </td>
    <td>{{ index + 1 }}</td>
    <td>
      <div class="group_input_search">
        <Input
          :value.sync="rowData.accountName"
          :isDisabled="true"
          :maxLength="100"
          @focusout="onFocusout"
        />
        <button class="btn_input_search" @click="$emit('onClickPopAccount')">
          <span class="ico_account ico_search" />
        </button>
      </div>
    </td>
    <td>
      <div class="group_input_search">
        <Input
          v-model="rowData.taxName"
          :value.sync="rowData.taxName"
          :isDisabled="true"
          :maxLength="100"
          @focusout="onFocusout"
        />
        <button class="btn_input_search" @click="$emit('onClickPopTax')">
          <span class="ico_account ico_search" />
        </button>
      </div>
    </td>
    <td>
      <div class="group_input_search">
        <Input
          :value.sync="rowData.deptName"
          :isDisabled="true"
          :maxLength="100"
          @focusout="onFocusout"
        />
        <button class="btn_input_search" @click="$emit('onClickPopDept')">
          <span class="ico_account ico_search" />
        </button>
      </div>
    </td>
    <td>
      <div class="group_input_search">
        <Input
          :value.sync="rowData.serviceCode"
          :isDisabled="true"
          :maxLength="100"
          @focusout="onFocusout"
        />
        <button class="btn_input_search" @click="$emit('onClickSearchProjectCode')">
          <span class="ico_account ico_search" />
        </button>
      </div>
    </td>
    <td>
      <div class="group_input_search">
        <!-- 거래처에 해당하는 api 값 voucherItems에 없어 임의로 넣어둠 -->
        <Input
          :value.sync="partnerName"
          :isDisabled="true"
          :maxLength="100"
          @focusout="onFocusout"
        />
        <button class="btn_input_search" @click="$emit('onClickPopPartner')">
          <span class="ico_account ico_search" />
        </button>
      </div>
    </td>
    <td>
      <!-- <Input
        :value.sync="rowData.debit"
        :maxLength="100"
        :isDisabled="rowData.surtaxYn === false && rowData.crdr === 'DR'"
        @focusout="onFocusout"/> -->
      <!-- <Input
        :value.sync="rowData.debit"
        :maxLength="100"
        :isDisabled="rowData.crdr === 'CR'"
        @focusout="onfocusDr"/> -->
      <InputAutoMoney
        :value.sync="rowData.debit"
        :maxLength="100"
        :isDisabled="rowData.crdr === 'CR'"
        @focusout="onfocusDr"
      />
    </td>
    <td>
      <InputAutoMoney
        :value.sync="rowData.credit"
        :isDisabled="rowData.crdr !== 'CR'"
        :maxLength="100"
        @focusout="onFocusout"
      />
    </td>
    <!--  임시저장문서에서 orgin 값 안먹어서 임시로 Index로 구분 기존 : && rowData.origin === true -->
    <td>
      <button
        v-if="rowData.crdr !== 'CR' && index === 0"
        type="button"
        class="btn_small btn_secondary"
        @click="$emit('addVoucherItem')"
      >
        추가
      </button>
      <button
        v-if="rowData.crdr !== 'CR' && index != 0"
        type="button"
        class="btn_small btn_fourthly"
        @click="$emit('removeVoucherLine', index)"
      >
        삭제
      </button>
    </td>
  </tr>
</template>

<script>
import RadioItem from "@/_approval/components/common/radio/RadioItem";
import Input from "@/_approval/components/common/input/Input";
import InputAutoMoney from "@/_approval/components/common/input/InputAutoMoney";

//import { default as CommFlatpickrMixin } from '@/_approval/mixins/common/commFlatpickr.js';

export default {
  name: "StatementVoucherListLine",
  components: {
    RadioItem,
    Input,
    InputAutoMoney,
  },
  //mixins: [CommFlatpickrMixin],
  props: {
    rowData: Object,
    index: Number,
    partnerName: String,
    assetOpenIndex: Number, // 자산전표 활성화 index
  },
  data() {
    return {
      assetOpen: "",
    };
  },
  computed: {
    isShowVoucherLine() {
      return this.rowData.surtaxYn == false;
    },
    assetOpenIndexSync: {
      get() {
        return "assetOpen" + this.assetOpenIndex;
      },
      set(value) {
        const selectedIdx = Number(value.replace("assetOpen", ""));
        this.$emit("update:assetOpenIndex", selectedIdx);
      },
    },
  },
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    onfocusDr(e) {
      e.target.classList.remove("error");

      //console.log(e.target.value);
      //this.$emit('setTotalDebit');
    },
  },
};
</script>
