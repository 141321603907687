<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 944px">
      <div class="layer_head">
        <strong class="tit_popup">검수 정보</strong>
      </div>
      <!-- 상세 -->
      <PopInspectDetail :inspectData="inspectData" :contractId="contractId" />
      <!-- :checkedNames.sync="checkedNames" -->
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopInspectDetail from "./PopInspectDetail";

export default {
  name: "PopInspect",
  components: {
    PopInspectDetail,
  },
  props: {
    inspectData: Object,
    contractId: Number,
  },
  data() {
    return {
      SelectedInspectDetail: {
        inspectId: "20201231031",
        date: "2020-12-31",
        price: "88,000,000 KRW",
        statusNm: "검수완료",
      },
    };
  },
  computed: {
    isDisabledSeletedBtn() {
      return this.checkedNames.length === 0;
    },
  },
  // 임시
  watch: {
    checkedNames() {
      if (this.checkedNames.length === this.inspectDataList.length) {
        this.checkAll = true;
      } else if (this.checkAll && this.checkedNames.length != this.inspectDataList.length) {
        this.checkAll = false;
      }
    },
    checkAll(to, from) {
      // cheked on
      if (to && !from) {
        if (this.checkedNames.length != this.inspectDataList.length) {
          this.checkedNames = this.inspectDataList;
        }
      }
    },
  },
};
</script>
