<template>
  <div class="box_sorting">
    <strong class="screen_out">프로젝트 검색하기</strong>
    <ul class="list_sorting">
      <li class="item_full">
        <em class="tit_txt">검색어</em>
        <div class="cont_sorting">
          <div class="row_pack">
            <Input
              style="width: 240px"
              :value.sync="searchKeyword"
              :maxLength="200"
              @onKeyUpEnter="keypress"
            />
            <button type="button" class="btn_medium btn_primary" @click.prevent="clickSearchButton">
              검색
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// import axios from 'axios';
import CommOpt from "@/_approval/components/common/CommOpt";
import Input from "@/_approval/components/common/input/Input";

export default {
  name: "PopProjectCodeSearchFilter",
  components: {
    CommOpt,
    Input,
  },
  data() {
    return {
      searchKeyword: "",
    };
  },
  methods: {
    clickSearchButton() {
      this.$emit("onClickSearch", this.searchKeyword); // 부모 함수(selectDocList)에게 넘김
    },
    keypress(key) {
      // if (key.which === 13) {
      //   this.$emit('select-connector', this.searchForm);
      // }
    },
  },
};
</script>
