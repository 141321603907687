<template>
  <div class="layer_body">
    <!-- <div class="head_tbl">
      <div class="pos_left">
        <div class="tit_text">구매요청서 정보</div>
      </div>
    </div> -->
    <div class="tbl_comm tbl_list">
      <table>
        <colgroup>
          <col style="width: 103px" />
          <!-- 요청일 -->
          <col style="width: 122px" />
          <!-- 요청번호 -->
          <col style="width: 190px" />
          <!-- 구매요청서명 -->
          <col style="width: 158px" />
          <!-- 구분 -->
          <col style="width: 135px" />
          <!-- 기안금액 -->
          <col style="width: 172px" />
          <!-- 구매요청자 -->
        </colgroup>
        <thead>
          <tr>
            <th>요청일</th>
            <th>요청번호</th>
            <th>구매요청서명</th>
            <th>구분</th>
            <th>기안금액</th>
            <th>구매요청자</th>
          </tr>
        </thead>
        <tbody>
          <!-- 선택한 구매요청서 항목 -->
          <PopPurchaseRequestLine :isRadioMode="false" :rowData="selectedPurchase" />
        </tbody>
      </table>
    </div>
    <!-- 계약정보선택 리스트 -->
    <div class="blank_asset5">
      <!-- <div class="head_tbl">
        <div class="pos_left">
          <strong class="tit_text">계약 정보 선택</strong>
        </div>
      </div> -->
      <div class="tbl_comm tbl_list tbl_scroll">
        <div
          class="inner_scroll"
          style="height: calc(100vh - 544px); max-height: 294px; min-height: 49px"
        >
          <table>
            <colgroup>
              <!--<col style="width: 52px;"> 전체선택 -->
              <col style="width: 110px" />
              <!-- 계약일 -->
              <col style="width: 80px" />
              <!-- 계약번호 -->
              <col style="width: 200px" />
              <!-- 계약서명 -->
              <col style="width: 140px" />
              <!-- 파트너사 -->
              <col style="width: 135px" />
              <!-- 계약금액 -->
              <col />
              <!-- 구매담당자 -->
            </colgroup>
            <thead>
              <tr>
                <!-- <th style="width: 52px;">
                  <div class="box_choice type_alone">
                    텍스트 없이 아이콘만 있는 타입 type_alone 클래스 추가
                    <div class="item_choice">
                       disabled 상태시 disabled 클래스 추가
                      <input
                        type="checkbox"
                        id="checkLineAll"
                        class="inp_choice"
                        name="checkLineAll"
                        v-model="checkAll"
                      />
                      <label class="lab_choice" for="checkLineAll">
                        <span class="ico_account ico_check"></span>
                      </label>
                    </div>
                  </div>
                </th> -->
                <th style="width: 110px">계약일</th>
                <th style="width: 80px">계약번호</th>
                <th style="width: 200px">계약서명</th>
                <th style="width: 140px">파트너사</th>
                <th style="width: 135px">계약금액</th>
                <th style="width: 215px">구매담당자</th>
              </tr>
            </thead>
            <tbody>
              <PopPurchaseRequestContractinfoListLine
                v-for="item in contractinfoDataList"
                :key="item.contractCid"
                :rowData="item"
                :selectedContractinfo.sync="selectedContractinfoSync"
              />
              <!-- <PopPurchaseRequestContractinfoListLine
                v-for="item in contractinfoDataList"
                :key="item.contractCid"
                :rowData="item"
                :checkedNames.sync="selectedContractinfoSync"/> -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopPurchaseRequestLine from "./PopPurchaseRequestLine";
import PopPurchaseRequestContractinfoListLine from "./PopPurchaseRequestContractinfoListLine";

import ApiService from "@/services/ApiService";

export default {
  name: "PopPurchaseRequestContractinfoList",
  components: {
    PopPurchaseRequestLine,
    PopPurchaseRequestContractinfoListLine,
  },
  props: {
    selectedPurchase: Object,
    selectedContractinfo: Object, // 선택한 계약 데이터
    // selectedContractinfo: Array,
    contractinfoDataLength: Number,
    isLoading: Boolean,
  },
  data() {
    return {
      checkAll: false,
      contractinfoDataList: [],
    };
  },
  computed: {
    selectedContractinfoSync: {
      get() {
        return this.selectedContractinfo;
      },
      set(value) {
        this.$emit("update:selectedContractinfo", value);
      },
    },
  },
  watch: {
    // selectedContractinfo(){
    //   if(this.selectedContractinfo.length === this.contractinfoDataList.length){
    //     this.checkAll = true;
    //   }else if(this.checkAll && this.selectedContractinfo.length != this.contractinfoDataList.length){
    //     this.checkAll = false;
    //   }
    // },
    // checkAll(to,from){
    //   // cheked on
    //   if( to && !from ){
    //     if(this.selectedContractinfo.length != this.contractinfoDataList.length){
    //       this.$emit("update:selectedContractinfo", this.contractinfoDataList);
    //       // this.checkedNames = this.contractinfoDataList;
    //     }
    //   }
    // },
  },
  mounted() {
    this.getInitContract();
  },
  methods: {
    async getInitContract() {
      this.$emit("update:isLoading", true);
      let path = `${this.$apiPath.SETTLEMENT_CONTRACT}`;
      // path += `?searchType=requestCid&searchValue=${ this.selectedPurchase.requestCid }`;
      path += `?requestCid=${this.selectedPurchase.requestCid}`;
      const res = await ApiService.shared.getData(path);

      if (res.data) {
        this.contractinfoDataList = res.data;
        this.$emit("update:contractinfoDataLength", res.data.length);
      }
      this.$emit("update:isLoading", false);
    },
  },
};
</script>
