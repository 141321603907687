import { removeComma } from "@/utils/stringUtils";

class VoucherItemsModel {
  constructor() {
    this.accountCode = ""; // 계정과목
    this.accountName = ""; // 계정과목명
    this.deptCode = ""; // 부서코드
    this.deptName = ""; // 부서명
    this.debit = ""; // 전체 금액
    this.credit = "";
    this.warranty = ""; // 보증기간
    this.note = ""; // 비고

    this.cmdbCategoryId = ""; // 카테고리 코드
    this.cmdbCategoryName = ""; // 카테고리
    this.assetStatus = ""; // 자산 상태
    this.barcode = ""; // 인프라 바코드번호
    this.serialNo = ""; // 시리얼 번호
    this.idcCode = ""; // 입고 위치
    this.locationIdcSectionCode = ""; // IDC 구역
    this.locationRackX = ""; //Rack X
    this.locationRackY = ""; //Rack Y
    this.locationRackZ = ""; //Rack Z

    this.note = ""; // 비고
    this.orderNum = 1; // 전표순서
    //this.price = ''; // 가격
    this.serviceCode = ""; // 서비스코드
    this.serviceName = ""; // 서비스
    this.taxItem = ""; // 세목
    this.taxName = ""; // 세목명
    this.voucherSaveModeYn = false; // 저장상태로 전표 생성
    this.voucherCid = ""; // 전표번호
    this.voucherItemId = null; // 전표항목번호
    this.partner = ""; // 거래처(입고처)
    // this.partnerCorporateNum = 0; // 거래처 사업자번호

    this.infraAccountYn = false; //인프라 여부
    this.surtaxYn = false; // 부가세여부 : 인프라/지출 차변(세액) row만 Y

    // 인프라 템플릿 업로드시 전달해주는 데이터
    this.systemTypeId = "";
    this.systemTypeName = "";

    this.crdr = "DR"; // 기본값: DR (차변)
    this.origin = true;
    this.displayYn = true;

    // 자산
    this.newUsed = "NEW"; // 신규/중고 default : new
    this.goodsName = ""; // 자산명
    this.assetMainClassification = ""; // 자산소분류 코드
    this.assetMainClassificationName = ""; // 인프라 자산소분 이름// (서버, 기계장치, 네트워크)
    this.assetSubClassification = ""; // 인프라 자산대분류 코드
    this.amount = ""; // 수량
  }
  setData(obj) {
    if (obj.accountCode) this.accountCode = obj.accountCode;
    if (obj.accountName) this.accountName = obj.accountName;
    if (obj.deptCode) this.deptCode = obj.deptCode;
    if (obj.deptName) this.deptName = obj.deptName;

    if (obj.assetsCategory) this.assetsCategory = obj.assetsCategory;
    if (obj.assetsCategoryName) this.assetsCategoryName = obj.assetsCategoryName;
    if (obj.barcode) this.barcode = obj.barcode;
    if (obj.credit) this.credit = obj.credit;
    if (obj.debit) this.debit = obj.debit;
    if (obj.note) this.note = obj.note;
    if (obj.orderNum) this.orderNum = obj.orderNum;
    //if(obj.price) this.price = obj.price;
    if (obj.serviceCode) this.serviceCode = obj.serviceCode;
    if (obj.serviceName) this.serviceName = obj.serviceName;
    if (obj.taxItem) this.taxItem = obj.taxItem;
    if (obj.taxName) this.taxName = obj.taxName;
    if (obj.voucherSaveModeYn) this.voucherSaveModeYn = obj.voucherSaveModeYn;
    if (obj.voucherCid) this.voucherCid = obj.voucherCid;
    if (obj.voucherItemId) this.voucherItemId = obj.voucherItemId;
    if (obj.partner) this.partner = obj.partner;
    if (obj.partnerCorporateNum) this.partnerCorporateNum = obj.partnerCorporateNum;

    if (obj.warranty) this.warranty = obj.warranty; // 보증기간
    if (obj.cmdbCategoryId) this.cmdbCategoryId = obj.cmdbCategoryId; // 카테고리 코드
    if (obj.cmdbCategoryName) this.cmdbCategoryName = obj.cmdbCategoryName; // 카테고리
    if (obj.newUsed) this.newUsed = obj.newUsed; // 신규/중고
    if (obj.assetStatus) this.assetStatus = obj.assetStatus; // 자산 상태
    if (obj.serialNo) this.serialNo = obj.serialNo; // 시리얼 번호
    if (obj.idcCode) this.idcCode = obj.idcCode; // 입고 위치
    if (obj.locationIdcSectionCode) this.locationIdcSectionCode = obj.locationIdcSectionCode; // IDC 구역
    if (obj.locationRackX) this.locationRackX = obj.locationRackX; //Rack X
    if (obj.locationRackY) this.locationRackY = obj.locationRackY; //Rack Y
    if (obj.locationRackZ) this.locationRackZ = obj.locationRackZ; //Rack Z

    if (obj.infraAccountYn) this.infraAccountYn = obj.infraAccountYn; //인프라 여부
    if (obj.surtaxYn) this.surtaxYn = obj.surtaxYn; // 부가세여부 : 인프라/지출 차변(세액) row만 Y

    if (obj.systemTypeId) this.systemTypeId = obj.systemTypeId;
    if (obj.systemTypeName) this.systemTypeName = obj.systemTypeName;

    if (obj.displayYn) this.displayYn = obj.displayYn;

    if (obj.crdr) this.crdr = obj.crdr; // 기본값: DR (차변)
    if (obj.origin) this.origin = obj.origin;
    if (obj.displayYn) this.displayYn = obj.displayYn;

    // 자산
    if (obj.newUsed) this.newUsed = obj.newUsed;
    if (obj.assetMainClassification) this.assetMainClassification = obj.assetMainClassification; // 자산분류코드
    if (obj.assetMainClassificationName)
      this.assetMainClassificationName = obj.assetMainClassificationName; // 인프라 자산분류(서버, 기계장치, 네트워크)
    if (obj.assetSubClassification) this.assetSubClassification = obj.assetSubClassification; // 인프라 자산대분류 코드
    if (obj.goodsName) this.goodsName = obj.goodsName; // 자산명
    if (obj.amount) this.amount = obj.amount; // 수량
  }

  getData() {
    let obj = {
      partner: this.partner,

      accountCode: this.accountCode,
      accountName: this.accountName,
      debit: removeComma(this.debit),
      warranty: this.warranty,
      cmdbCategoryId: this.cmdbCategoryId,
      cmdbCategoryName: this.cmdbCategoryName,
      assetStatus: this.assetStatus,
      barcode: this.barcode,

      serialNo: this.serialNo,
      idcCode: this.idcCode,
      locationIdcSectionCode: this.locationIdcSectionCode,
      locationRackX: this.locationRackX,
      locationRackY: this.locationRackY,
      locationRackZ: this.locationRackZ,

      deptCode: this.deptCode,
      deptName: this.deptName,
      note: this.note,

      orderNum: this.orderNum,
      //price : this.price,
      serviceCode: this.serviceCode,
      serviceName: this.serviceName,

      taxItem: this.taxItem,
      taxName: this.taxName,
      voucherSaveModeYn: this.voucherSaveModeYn,
      voucherCid: this.voucherCid,
      voucherItemId: this.voucherItemId,

      infraAccountYn: this.infraAccountYn,
      surtaxYn: this.surtaxYn,

      systemTypeName: this.systemTypeName,
      systemTypeId: this.systemTypeId,
      credit: removeComma(this.credit),

      displayYn: this.displayYn,

      // 자산
      newUsed: this.newUsed,
      goodsName: this.goodsName,
      assetMainClassification: this.assetMainClassification,
      assetMainClassificationName: this.assetMainClassificationName,
      assetSubClassification: this.assetSubClassification,
      amount: removeComma(this.amount),
    };

    return obj;
  }
}

export default VoucherItemsModel;
