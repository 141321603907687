<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 1098px">
      <div class="layer_head">
        <strong class="tit_popup">국세청 세금계산서/계산서내역</strong>
      </div>
      <div class="layer_body">
        <!-- 필터바 -->
        <PopBillFilter
          :searchForm.sync="searchForm"
          :disableBill="disableBill"
          @select-connector="getBillData"
        />
        <!-- 국세청 세금계산서/계산서내역 리스트 -->
        <div class="head_tbl">
          <div class="pos_right">
            <span class="txt_info">통화 코드 : KRW</span>
          </div>
        </div>
        <div class="tbl_comm tbl_list tbl_list_sm8 tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 544px); max-height: 294px; min-height: 49px"
          >
            <table>
              <colgroup>
                <col style="width: 91px" />
                <!-- 증빙일자 -->
                <col style="width: 113px" />
                <!-- 거래처명(계산서) -->
                <col style="width: 108px" />
                <!-- 거래처명(등록) -->
                <col style="width: 88px" />
                <!-- 사업자 번호 -->
                <col style="width: 98px" />
                <!-- 공급가액 -->
                <col style="width: 98px" />
                <!-- 세액 -->
                <col style="width: 120px" />
                <!-- 수신이메일1 -->
                <col style="width: 120px" />
                <!-- 수신이메일2 -->
                <col :style="serachedCalculateYn ? 'width: 88px;' : ''" />
                <!-- 승인번호 -->
                <col v-if="serachedCalculateYn" />
                <!-- 사용내역번호 -->
              </colgroup>
              <thead>
                <tr>
                  <th style="width: 91px">증빙일자</th>
                  <th style="width: 113px">거래처명(계산서)</th>
                  <th style="width: 108px">거래처명(등록)</th>
                  <th style="width: 88px">사업자 번호</th>
                  <th style="width: 98px">공급가액</th>
                  <th style="width: 98px">세액</th>
                  <th style="width: 120px">수신이메일1</th>
                  <th style="width: 120px">수신이메일2</th>
                  <th :style="serachedCalculateYn ? 'width: 88px;' : 'width: 196px'">승인번호</th>
                  <th v-if="serachedCalculateYn" style="width: 108px">사용내역번호</th>
                </tr>
              </thead>
              <tbody v-if="externalDataList">
                <PopBillLine
                  v-for="item in externalDataList"
                  :key="item.ntsId"
                  :rowData="item"
                  :serachedCalculateYn.sync="serachedCalculateYn"
                  :selectedBill.sync="selectedBill"
                />
                <tr v-if="externalDataList.length == 0">
                  <td :colspan="serachedCalculateYn ? 10 : 9" class="td_empty">
                    {{
                      isProgressSync ? "조회 중입니다" : "국세청 세금계산서/계산서내역이 없습니다"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            취소
          </button>
          <button
            type="button"
            class="btn_medium btn_secondary"
            :disabled="isDisabledMoveBtn"
            @click.prevent="onClickSelected"
          >
            선택
          </button>
          <!-- <button v-if="isBillSeleted" type="button" class="btn_medium btn_primary" :disabled="isDisabledSeletedBtn" @click.prevent="onClickSelected">선택</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopBillFilter from "./PopBillFilter";
import PopBillLine from "./PopBillLine";
import ApiService from "@/services/ApiService";
import {
  getDate,
  getYearMonth,
  firstDayOfCurrentMonthWithOutDash,
  lastDayOfCurrentMonthWithOutDash,
} from "@/utils/dateUtils";
import { make00 } from "@/utils/stringUtils";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer";

export default {
  name: "PopBill",
  components: {
    PopBillFilter,
    PopBillLine,
  },
  mixins: [CommLayerMixin],
  props: {
    selectedType: String,
  },
  data() {
    return {
      searchForm: {
        billType: "taxBill",
        inspectYn: false,
        draftFromDate: "",
        draftToDate: "",
        email: "",
        partnerNm: "",
        // searchType: { code: 'subject', name: '구매요청명' },
        //searchKeyword:''
      },
      initMonthFirstDay: "",
      initMonthLastDay: "",
      externalDataList: [],
      selectedBill: {}, // 선택한 국세청 세금계산서/계산서 데이터
      disableBill: false,
      serachedCalculateYn: false,
      isProgress: false,
    };
  },
  computed: {
    isDisabledMoveBtn() {
      return !this.selectedBill.corporateNum;
    },
    isProgressSync() {
      return this.isProgress;
    },
  },
  mounted() {
    if (this.selectedType) {
      this.searchForm.billType = this.selectedType;
      if (this.selectedType === "taxBill") this.disableBill = true;
    }
    this.getInitBillData();
    this.serachedCalculateYn = this.searchForm.inspectYn;
  },
  methods: {
    getInitBillData() {
      let today = new Date();
      let currentYear = today.getFullYear().toString();
      let currentInitMonth = make00(today.getMonth() + 1);

      this.initMonthFirstDay = firstDayOfCurrentMonthWithOutDash();
      this.initMonthLastDay = lastDayOfCurrentMonthWithOutDash();

      const obj = {};
      obj.draftFromDate = this.initMonthFirstDay;
      obj.draftToDate = this.initMonthLastDay;

      this.getBillData(obj);
    },
    async getBillData(filterData) {
      //const path = `${ this.$apiPath.VOUCHER_PROOF_NTS }?startDate=${ initMonth }&endDate=${ initMonth }`;
      const path = this.getApiPath(filterData);
      this.serachedCalculateYn = filterData.inspectYn;

      this.externalDataList = [];
      this.isProgress = true;

      const result = await ApiService.shared.getData(path);

      if (result.code == 200) {
        this.externalDataList = result.data;
        this.isProgress = false;
      } else if (result.code !== 200) {
        this.isProgress = false;
        this._showLayer({
          type: "alert",
          content: result.text || "조회에 실패했습니다. 관리자에게 문의하세요",
        });
        return;
      }
    },
    getApiPath(data) {
      let path = `${this.$apiPath.VOUCHER_PROOF_NTS}`;
      const draftFromDate =
        this.searchForm.draftFromDate == ""
          ? this.initMonthFirstDay
          : this.searchForm.draftFromDate.replaceAll("-", "");
      const draftToDate =
        this.searchForm.draftToDate == ""
          ? this.initMonthLastDay
          : this.searchForm.draftToDate.replaceAll("-", "");

      path += `?startDate=${draftFromDate}&endDate=${draftToDate}`;

      if (this.searchForm.billType) {
        const billType = this.searchForm.billType == "taxBill" ? "세금계산서" : "계산서";
        path += `&billType=${billType}`;
      }
      //if(this.searchForm.inspectYn) {
      const inspectYn = this.searchForm.inspectYn == false ? "N" : "Y";
      path += `&calculateYn=${inspectYn}`;
      //}
      if (this.searchForm.email) {
        path += `&email=${this.searchForm.email}`;
      }
      if (this.searchForm.partnerNm) {
        path += `&partnerNm=${this.searchForm.partnerNm}`;
      }
      return path;
    },
    // 구매요청서 선택
    onClickPurchase(item) {
      this.selectedBill = item;
    },
    onClickSelected() {
      const ret = this.externalDataList.filter((item) => item.ntsId === this.selectedBill.ntsId);

      this.$emit("onClickComplete", ret, this.searchForm.billType);
    },
  },
};
</script>
