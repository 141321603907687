<template>
  <div class="row_pack">
    <!-- <Input
      :isDisabled="isDisabled"
      class="w104"
      :value.sync="emailNameSync" />
    <span class="txt_view_c w27">@</span>
    <Input
      class="w121"
      :isDisabled="isDisabled || !isDirectEmailMode"
      :value.sync="emailDomainSync" /> -->
    <Input
      style="width: 96px"
      :value.sync="emailNameSync"
      :isDisabled="isDisabled"
      :maxLength="100"
    />
    <!-- @focusout="onFocusout" -->
    <span class="txt_view_c" style="width: 27px">@</span>
    <Input
      style="width: 96px"
      :value.sync="emailDomainSync"
      :isDisabled="isDisabled || !isDirectEmailMode"
      :maxLength="100"
    />
    <comm-opt
      :classList="['type_medium']"
      style="width: calc(100% - 227px)"
      :optionDesc="'이메일 도메인 선택상자'"
      :optionName="emailSelectSync"
      :optionList="emailVendor"
      :eventName="'select'"
      :disabled="isDisabled"
      :allFlag="false"
      @select="onChangeSelect"
    />
    <!-- <SelectBox
      :isDisabled="isDisabled"
      class="w120"
      :dataList="emailVendor"
      :value.sync="emailSelectSync" /> -->
    <!-- <button v-if="isMailPushBtn" type="button" class="btn_fourthly btn_small" @click="onClickMailConfirm">인증 메일 발송</button> -->
  </div>
</template>

<script>
import Input from "@/_approval/components/common/input/Input";
// import SelectBox from '@/_approval/components/common/selectBox/SelectBox'
// import { mapState } from 'vuex'
// import { COMMON_CODE_ACTION } from '@/_approval/store/modules/common/action'
import CommOpt from "@/_approval/components/common/CommOpt";

export default {
  name: "InputEmail",
  components: {
    Input,
    //   SelectBox
    CommOpt,
  },
  props: {
    emailName: String,
    emailDomain: String,
    isMailPushBtn: Boolean,
    isDisabled: Boolean,
  },
  data() {
    return {
      emailSelect: "직접입력",
      emailVendor: [
        { code: "daum.net", name: "daum.net", desc: "daum.net" },
        { code: "hanmail.net", name: "hanmail.net", desc: "hanmail.net" },
        { code: "naver.com", name: "naver.com", desc: "naver.com" },
        { code: "gmail.com", name: "gmail.com", desc: "gmail.com" },
        { code: "nate.com", name: "nate.com", desc: "nate.com" },
        { code: "직접입력", name: "직접입력", desc: "직접입력" },
      ],
    };
  },
  computed: {
    // ...mapState({
    //   emailVendor: state => state.commonCode.emailVendor,
    // }),
    isDirectEmailMode() {
      return this.emailSelect === "직접입력";
    },
    emailNameSync: {
      get() {
        return this.emailName;
      },
      set(value) {
        this.$emit("update:emailName", value);

        this.$emit("onChanged", `${value}@${this.emailDomain}`);
      },
    },
    emailDomainSync: {
      get() {
        const hasDomain = this.emailVendor.some((item) => item.code === this.emailDomain);
        if (!hasDomain) {
          this.emailSelect = "직접입력";
        } else {
          this.emailSelect = this.emailDomain;
        }

        return this.emailDomain;
      },
      set(value) {
        this.$emit("update:emailDomain", value);

        this.$emit("onChanged", `${this.emailName}@${value}`);
      },
    },
    emailSelectSync: {
      get() {
        return this.emailSelect;
      },
      set(value) {
        this.emailSelect = value;

        if (this.isDirectEmailMode) {
          this.$emit("update:emailDomain", "");
        } else {
          this.$emit("update:emailDomain", value);
        }

        this.$emit("onChanged", `${this.emailName}@${value}`);
      },
    },
  },
  // mounted() {
  //   const { GET_EMAIL_VENDOR } = COMMON_CODE_ACTION;

  //   this.$store.dispatch( GET_EMAIL_VENDOR );
  // },
  methods: {
    onClickMailConfirm() {
      this.$emit("onClickMailConfirm");
    },
  },
  methods: {
    onChangeSelect(obj) {
      this.emailSelect = obj.code;
      if (obj.code === "직접입력") {
        this.$emit("update:emailDomain", "");
      } else {
        // this.$emit("update:value", value);
        this.$emit("update:emailDomain", obj.code);
        this.$emit("onChanged", `${this.emailName}@${obj.code}`);
      }
    },
  },
};
</script>
