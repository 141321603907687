<template>
  <div class="blank_asset3">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">지급처정보</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>지급처<sup>*</sup></th>
            <td>
              <div class="row_pack">
                <Input
                  :style="{
                    width: isPartnerSearchType ? 'calc(100% - 64px)' : '',
                  }"
                  :value.sync="partnerName"
                  :maxLength="100"
                  :isDisabled="true"
                  @focusout="onPartnerFocusout"
                />
                <!-- {{ partnerName }} -->
                <button
                  v-if="isPartnerSearchType"
                  type="button"
                  class="btn_small btn_fourthly"
                  @click="$emit('onClickPopPartner')"
                >
                  조회
                </button>
              </div>
            </td>
            <th>사업자/주민등록번호<sup>*</sup></th>
            <td>
              <!-- <Input 
                v-if="certificateItemData.corporateNum != ''"
                :value.sync="certificateItemData.corporateNum"
                :maxLength="100"
                @focusout="onFocusout"/> -->
              <Input
                :value.sync="certificateItemData.corporateNum"
                :maxLength="100"
                :isDisabled="true"
                @focusout="onFocusout"
              />
            </td>
          </tr>
          <tr>
            <th>사업자/주민등록번호 사본</th>
            <td v-if="isRegisterParter" colspan="3">
              <FileView :dataList="[certificateItemData.businessRegFile]" />
            </td>
            <td v-else colspan="3">
              <StatementFileWrite
                :isAutoType="isAutoType"
                :dataList="
                  certificateItemData.businessRegFile ? [certificateItemData.businessRegFile] : []
                "
                :canMultiple="false"
                @fileUploaded="(fileData) => uploadFile(fileData[0], 'R', 'businessRegFile')"
                @fileRemoved="removeFile('businessRegFile')"
              />
            </td>
          </tr>
          <tr>
            <th>통장 사본</th>
            <td v-if="isRegisterParter" colspan="3">
              <FileView :dataList="[certificateItemData.bankBookFile]" />
            </td>
            <td v-else colspan="3">
              <StatementFileWrite
                :isAutoType="isAutoType"
                :dataList="
                  certificateItemData.bankBookFile ? [certificateItemData.bankBookFile] : []
                "
                :canMultiple="false"
                @fileUploaded="(fileData) => uploadFile(fileData[0], 'B', 'bankBookFile')"
                @fileRemoved="removeFile('bankBookFile')"
              />
            </td>
          </tr>
          <tr>
            <th>세금계산서/계산서</th>
            <td v-if="docflag" colspan="3">
              <FileView :dataList.sync="certificateItemData.billFiles" :isComments="true" />
            </td>
            <td v-else colspan="3">
              <StatementFileListWrite
                :dataList="certificateItemData.billFiles"
                :isComments="true"
                @fileUploaded="(fileData) => uploadFiles(fileData, 'L', 'billFiles')"
                @fileRemoved="(index, fileData) => removeFiles(index, fileData, 'billFiles')"
              />
            </td>
          </tr>
          <tr>
            <th>기타 증빙 문서</th>
            <td v-if="docflag" colspan="3">
              <FileView :dataList.sync="certificateItemData.etcFiles" :isComments="true" />
            </td>
            <td v-else colspan="3">
              <StatementFileListWrite
                :dataList="certificateItemData.etcFiles"
                :isComments="true"
                @fileUploaded="(fileData) => uploadFiles(fileData, 'I', 'etcFiles')"
                @fileRemoved="(index, fileData) => removeFiles(index, fileData, 'etcFiles')"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Input from "@/_approval/components/common/input/Input";
import FileWrite from "@/_approval/components/common/file/FileWrite";
import StatementFileWrite from "@/_approval/components/common/file/StatementFileWrite";
import StatementFileListWrite from "@/_approval/components/common/file/StatementFileListWrite";
import FileView from "@/_approval/components/common/file/FileView";

export default {
  name: "StatmentPartner",
  components: {
    Input,
    FileWrite,
    StatementFileWrite,
    StatementFileListWrite,
    FileView,
  },
  props: {
    certificateItemData: Object,
    docflag: String,
  },
  computed: {
    partnerName() {
      const partnerName = this.certificateItemData.partnerName;
      const companyName = this.certificateItemData.partnerName;

      if (partnerName == "" && companyName == "") return "";

      return partnerName != "" ? partnerName : companyName;
    },
    isAutoType() {
      return (
        this.certificateItemData.proofType == "AT" || this.certificateItemData.proofType == "AB"
      );
    },
    // 지급처 조회 유무
    isPartnerSearchType() {
      const proofType = this.certificateItemData.proofType;
      return proofType != "AT" && proofType != "AB";
    },
    isRegisterParter() {
      return this.certificateItemData.userNum != null;
    },
    businessRegFile() {
      const fileList = [];

      if (
        this.certificateItemData.businessRegFile &&
        this.certificateItemData.businessRegFile.attachNum
      ) {
        fileList.push(this.certificateItemData.businessRegFile);
      }

      return fileList;
    },
    bankBookFile() {
      const fileList = [];

      if (
        this.certificateItemData.bankBookFile &&
        this.certificateItemData.bankBookFile.attachNum
      ) {
        fileList.push(this.certificateItemData.bankBookFile);
      }

      return fileList;
    },
    billFile() {
      const fileList = [];

      if (this.certificateItemData.billFiles.attachFile.attachNum) {
        fileList.push(this.certificateItemData.bankBookFile);
      }

      return fileList;
    },
    etcFile() {
      const fileList = [];

      if (this.certificateItemData.etcFiles.attachFile.attachNum) {
        fileList.push(this.certificateItemData.bankBookFile);
      }

      return fileList;
    },
  },
  mounted() {
    //this.certificateItemData.billFiles.attacheType = 'BILL';
    //this.certificateItemData.etcFiles.attacheType = 'ETC';
  },
  methods: {
    isEmpty(dataList) {
      return dataList && dataList.length == 0;
    },
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    onPartnerFocusout(e) {
      e.target.classList.remove("error");
      this.certificateItemData.voucher.voucherItems.forEach((item) => {
        item.partner = e.target.value;
      });
      this.certificateItemData.voucher.erpPartnerName = e.target.value;
    },
    isEmptyFile(data) {
      const fileList = [];

      if (this.certificateItemData.bankBookFile.attachNum) {
      }
    },
    getListAfterRemove(fileToRemove, dataList) {
      const ret = dataList.filter((item) => item.attachNum !== fileToRemove.attachNum);

      return ret;
    },
    // uploadBillFiles(data){
    //   const obj = {};
    //   obj.attachFile = data;
    //   obj.attachId = '';
    //   obj.comments = '';
    //   obj.attacheType = 'L';

    //   this.certificateItemData.billFiles.push(obj);
    // },
    // removeBillFiles(idx , data) {
    //   this.certificateItemData.billFiles.splice(idx, 1);
    // },
    // uploadEtcFiles(data){
    //   const obj = {};
    //   obj.attachFile = data;
    //   obj.attachId = '';
    //   obj.comments = '';
    //   obj.attacheType = 'I';

    //   this.certificateItemData.etcFiles.push(obj);
    // },
    // removeEtcFiles(idx , data) {
    //   this.certificateItemData.etcFiles.splice(idx, 1);
    // },
    uploadFile(data, attacheType, fileDataKeyName) {
      data.attachType = attacheType;
      this.certificateItemData[fileDataKeyName] = data;
    },
    uploadFiles(data, attacheType, fileDataKeyName) {
      const obj = {};
      obj.attachFile = data;
      obj.attachId = "";
      obj.comments = "";
      obj.attacheType = attacheType;

      this.certificateItemData[fileDataKeyName].push(obj);
    },
    removeFile(fileDataKeyName) {
      this.certificateItemData[fileDataKeyName] = {};
    },
    removeFiles(index, data, fileDataKeyName) {
      this.certificateItemData[fileDataKeyName].splice(index, 1);
    },
  },
};
</script>
