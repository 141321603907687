<template>
  <tr :class="{ tr_select: isSelected }">
    <td>
      <div class="box_choice type_alone">
        <div class="item_choice">
          <!-- disabled 상태시 disabled 클래스 추가 -->
          <input
            :id="rowData.approvalNo"
            v-model="selectedCorporateCardSync"
            type="radio"
            class="inp_choice"
            name="selectedCorporateCard"
            :value="rowData"
          />
          <label class="lab_choice" :for="rowData.approvalNo">
            <span class="ico_account ico_rdo" />
          </label>
        </div>
      </div>
    </td>
    <td>{{ rowData.dealDt }}</td>
    <td>{{ rowData.companyCodeNm }}</td>
    <td class="td_ellip">
      {{ rowData.bzmName }}
    </td>
    <td>{{ rowData.approvalAmount }}</td>
  </tr>
</template>

<script>
export default {
  name: "PopCorporateCardLine",
  props: {
    isRadioMode: Boolean,
    rowData: Object,
    selectedCorporateCard: Object,
    // checkedNames: Array
  },
  computed: {
    selectedCorporateCardSync: {
      get() {
        return this.selectedCorporateCard;
      },
      set(value) {
        this.$emit("update:selectedCorporateCard", value);
      },
    },
    isSelected() {
      return this.selectedCorporateCard == this.rowData;
    },
    //   checkedNamesSync: {
    //     get() {
    //       return this.checkedNames;
    //     },
    //     set(value) {
    //       this.$emit("update:checkedNames", value);
    //     }
    //   },
  },
};
</script>
