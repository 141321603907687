<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">부가세정보</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 171px" />
          <col />
          <col style="width: 171px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>세금유형<sup>*</sup></th>
            <td>
              <comm-opt
                :classList="['type_medium']"
                :optionDesc="'세금유형 선택상자'"
                :optionName="taxCodeName"
                :optionList="taxCodeList"
                :eventName="'select'"
                @select="selectTaxCode"
              />
            </td>
            <th>처리유형<sup>*</sup></th>
            <td>
              <div class="row_pack">
                <Radio
                  :dataList="receiptBillList"
                  name="receiptBill"
                  :selectedId.sync="surtax.receiptBill"
                />
                <div class="area_right">
                  <Input
                    style="width: 88px"
                    :value.sync="numberOfVat"
                    :maxLength="100"
                    @focusout="onFocusout"
                  />
                  <span class="txt_view_r">건</span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>발행유형<sup>*</sup></th>
            <td>
              <Radio
                :dataList="electroWriteList"
                name="electroWrite"
                :selectedId.sync="surtax.electroWrite"
              />
            </td>
            <th>자산구분</th>
            <td>
              <comm-opt
                :classList="['type_medium']"
                :optionDesc="'세금유형 선택상자'"
                :optionName="vatAssetTypeName"
                :optionList="vatAssetTypeList"
                :eventName="'select'"
                @select="selectAssetCode"
              />
            </td>
          </tr>
          <tr>
            <th>불공제 사유</th>
            <td colspan="3">
              <!-- <Input
                :value.sync="surtax.exclusionOfDeductionReason"
                :maxLength="100"
                @focusout="onFocusout"/> -->
              <comm-opt
                :classList="['type_long2']"
                :optionDesc="'불공제 사유'"
                :optionName="reasonCodeName"
                :optionList="exclusionRasonList"
                :eventName="'select'"
                @select="selectReasonCode"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import CommOpt from "@/_approval/components/common/CommOpt";
import Radio from "@/_approval/components/common/radio/Radio";
import Input from "@/_approval/components/common/input/Input";

import ApiService from "@/services/ApiService";

export default {
  name: "StatementSurtax",
  components: {
    CommOpt,
    Radio,
    Input,
  },
  props: {
    accountCode: String,
    proofType: String,
    surtax: Object,
  },
  data() {
    return {
      taxCodeList: [],
      reasonCodeList: [],
      exclusionRasonList: [],
      surtaxList: [],
      receiptBillList: [
        { code: "R", name: "영수" },
        { code: "D", name: "청구" },
      ],
      electroWriteList: [
        { code: "ELECT", name: "전자" },
        { code: "WRITE", name: "수기" },
      ],
      vatAssetTypeList: [
        { code: "", name: "없음" },
        { code: "BUILDING", name: "건물구축물" },
        { code: "MACHINE", name: "기계장치" },
        { code: "CAR", name: "차량운반구" },
        { code: "ETC", name: "그 밖의 감가상각자산" },
      ],
    };
  },
  computed: {
    numberOfVat: {
      get() {
        if (this.surtax.numberOfVat == null) this.surtax.numberOfVat = 0;
        return String(this.surtax.numberOfVat);
      },
      set(value) {
        this.surtax.numberOfVat = Number(value);
        // return String(value);
      },
    },
    taxCodeName() {
      //return '';
      if (this.taxCodeList.length == 0) return "";

      const code = this.taxCodeList.find((item) => item.code == this.surtax.taxCode);
      if (!code) return this.taxCodeList[0].name;

      return this.taxCodeList.find((item) => item.code == this.surtax.taxCode).name;
    },
    vatAssetTypeName() {
      //return '';
      // return this.vatAssetTypeList.find(item => item.code == this.surtax.vatAssetType).name;
      if (this.vatAssetTypeList.length == 0) return "";

      const code = this.vatAssetTypeList.find((item) => item.code == this.surtax.vatAssetType);
      if (!code) return this.vatAssetTypeList[0].name;

      return code.name;
    },
    reasonCodeName() {
      if (this.exclusionRasonList.length == 0) return "";

      const code = this.exclusionRasonList.find(
        (item) => item.code == this.surtax.exclusionOfDeductionReasonCode,
      );
      if (!code) return this.exclusionRasonList[0].name;

      return code.name;
    },
    electroWrite: {
      get() {
        return this.surtax.electroWrite ? this.surtax.electroWrite : "ELECT";
      },
      set(value) {
        return value;
      },
    },
  },
  beforeMount() {
    this.getExclusionReason();

    const elect = this.setInitElectCode();
    this.surtax.electroWrite = elect;
  },
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    selectTaxCode(data) {
      this.surtax.surtaxCode = data.code;
      this.surtax.surtaxName = data.name;

      const filtered = this.surtaxList.filter((item) => item.taxCode == data.code);
      this.surtax.taxType = filtered[0].taxType;
      // 부가세에 따른 전표 차변 & 대변 세팅 ?
      this.surtax.surtaxRate = filtered[0].taxRate;
    },
    selectReasonCode(data) {
      this.surtax.exclusionOfDeductionReasonCode = data.code;
      this.surtax.exclusionOfDeductionReason = data.name;
    },
    selectAssetCode(data) {
      this.surtax.vatAssetType = data.code;
    },
    async getaxCodeList() {
      // let path = `${ this.$apiPath.VOUCHER_TAXRATE }?accountType=NA`;
      // if(this.accountCode) {
      //   path += `&accoutCode=${ this.accountCode }`;
      // }
      // if(this.proofType){
      //   path +=`&proofType=${ this.proofType }`
      // }
      let path = `${this.$apiPath.VOUCHER_TAXRATE}`;

      const res = await ApiService.shared.getData(path);

      if (res.data) {
        this.surtaxList = res.data;
        this.taxCodeList = res.data.map((item) => {
          const obj = {};
          obj.code = item.taxCode;
          obj.name = item.taxNm;
          return obj;
        });

        const initTaxCode = this.setInitTaxCode();
        const taxCode = initTaxCode.code == "" ? this.taxCodeList[0] : initTaxCode;

        this.selectTaxCode(taxCode);
      }
    },
    setInitTaxCode() {
      const proofType = this.proofType;
      switch (proofType) {
        case "AT":
        case "TB":
        case "PT":
          return { code: "VPT1", name: "매입과세(일반)" };
        case "CP":
          return { code: "VPT2", name: "매입과세(신용카드)" };
        case "ID":
          return { code: "VPT5", name: "매입과세(개인카드)" };
        default:
          return { code: "", name: "" };
      }
    },
    setInitElectCode() {
      const proofType = this.proofType;
      switch (proofType) {
        case "PT":
        case "PB":
          return "WRITE";
        default:
          return "ELECT";
      }
    },
    async getExclusionReason() {
      let path = `${this.$apiPath.REASON_NON_DEDUCTION}`;

      const res = await ApiService.shared.getData(path);

      if (res.data) {
        this.exclusionRasonList = res.data;
        // this.reasonCodeList = res.data.map(item => {
        //     const obj = {};
        //     obj.code = item.
        // })
        this.selectReasonCode(this.exclusionRasonList[0]);
      }
    },
    async getAssetCodeList() {
      const path = `${this.$apiPath.JDE_ASSET}`;

      const res = await ApiService.shared.getData(path);
      if (res.data) {
        this.vatAssetTypeList = res.data;
      }
    },
  },
};
</script>
