<template>
  <!-- <tr :class="{ tr_select : isChecked }"> -->
  <tr :class="{ tr_select: isSelected }" @click="$emit('update:selectedOrder', rowData)">
    <!-- <td>
      <div class="box_choice type_alone">
        <div class="item_choice">
          <input type="checkbox" :id="rowData.orderCid" class="inp_choice" name="projectCode" v-model="checkedNamesSync" :value="rowData">
          <label class="lab_choice" :for="rowData.orderCid">
            <span class="ico_account ico_check"></span>
          </label>
        </div>
      </div>
    </td> -->
    <td>{{ rowData.orderDate }}</td>
    <td>{{ rowData.orderCid }}</td>
    <td class="td_ellip">
      {{ rowData.title }}
    </td>
    <td class="td_ellip">
      {{ rowData.companyName }}
    </td>
    <td class="align_right">{{ rowData.price | currency }} {{ rowData.currency }}</td>
    <!-- <td>{{ rowData.commonReqName }}</td> -->
    <td>{{ rowData.purchasePersonLoginId }}({{ rowData.purchasePersonName }})</td>
  </tr>
</template>

<script>
export default {
  name: "PopOrderListLine",
  props: {
    rowData: Object,
    selectedOrder: Object,
    //checkedNames: Array
  },
  computed: {
    isSelected() {
      if (!this.selectedOrder) return false;
      return this.selectedOrder.orderCid == this.rowData.orderCid;
    },
    // checkedNamesSync: {
    //   get() {
    //     return this.checkedNames;
    //   },
    //   set(value) {
    //     this.$emit("update:checkedNames", value);
    //   }
    // },
    // isChecked(){
    //   return this.checkedNames.find( item => item.orderCid == this.rowData.orderCid );
    // }
  },
};
</script>
