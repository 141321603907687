<template>
  <li :class="{ open: !isFold }">
    <div class="head_fold">
      <div class="box_choice type_alone">
        <div class="item_choice">
          <!-- disabled 상태시 disabled 클래스 추가 -->
          <!-- <input type="checkbox" :id="'statementInfo'+index" class="inp_choice" name="projectCode" v-model="checkedNamesSync" :value="certificateItemData"> -->
          <input
            :id="'statementInfo' + index"
            v-model="checkedNamesSync"
            type="checkbox"
            class="inp_choice"
            name="projectCode"
            :value="index"
          />
          <label :id="'statementInfo' + index" class="lab_choice" :for="'statementInfo' + index">
            <span class="ico_account ico_check" />
          </label>
        </div>
      </div>
      <!-- 우측 영역 -->
      <div class="area_right">
        <button class="btn_delete" @click="removeStatementLine">
          <span class="ico_account ico_delete" />삭제
        </button>
        <button class="btn_copy" @click="copyStatementLine">
          <span class="ico_account ico_copy" />복사
        </button>
        <button class="btn_fold" @click="onClickFold">
          <span class="ico_account ico_arr_ellip">{{ isFold ? "펼치기" : "접기" }}</span>
        </button>
      </div>

      <!-- 제목 영역 -->
      <div class="fold_subject">
        <Input
          style="width: 680px"
          :value.sync="certificateItemData.certificateDesc"
          :maxLength="200"
          @focusout="onFocusout"
        />
      </div>
    </div>
    <div v-if="!isFold" class="body_fold">
      <!-- 결제정보 -->
      <StatementPayment
        :certificateItemData.sync="certificateItemDataSync"
        :isInfra="isInfraStatement"
        @onClickBill="onClickBill"
        @onClickPopCorporateCard="onClickPopCorporateCard"
      />
      <!-- 지급처정보 -->
      <StatementPartner
        :certificateItemData.sync="certificateItemDataSync"
        :docflag="docflag"
        @onClickPopPartner="onClickPopPartner"
      />
      <!-- 계약/발주/검수 정보 -->
      <!-- <StatmentOtherMenuInfo
        :certificateItemData.sync="certificateItemDataSync"
        :inspectHistory="inspectHistory"
        :contractInfo="contractInfo"
        @onClickContractinfo="onClickContractinfo"
        @onClickPopOrder="onClickPopOrder"
        @onClickPopInspect="onClickPopInspect"
        @onClickRemoveContract="onClickRemoveContract"
      @onClickRemoveOrder="onClickRemoveOrder"/>-->
      <StatmentOtherMenuInfo
        :isInfra="isInfraStatement"
        :certificateItemData.sync="certificateItemDataSync"
        :contractInfo="purchaseContract"
        @onClickContractinfo="onClickContractinfo"
        @onClickPopOrder="onClickPopOrder"
        @onClickPopInspect="onClickPopInspect"
        @onClickPopInspectDetail="onClickPopInspectDetail"
        @onClickRemoveContract="onClickRemoveContract"
        @onClickRemoveOrder="onClickRemoveOrder"
        @onClickRemoveInspect="onClickRemoveInspect"
      />
      <!-- 지출정산서 전표정보 -->
      <StatementVoucher
        v-if="!isInfraStatement"
        :docflag="docflag"
        :voucher.sync="certificateItemDataSync.voucher"
        :isAddApprover="isAddApprover"
        :isInfra="isInfraStatement"
        :proofType="certificateItemDataSync.proofType"
        :taxAmount="certificateItemDataSync.payment.taxAmount"
        :assetOpenIndex.sync="assetOpenIndex"
        @onUpdateAssetOpen="onUpdateAssetOpen"
        @setAssetAccount="setAssetAccount"
        @onClickPopAccount="onClickPopAccount"
        @onClickPopTax="onClickPopTax"
        @onClickPopDept="onClickPopDept"
        @onClickSearchProjectCode="onClickSearchProjectCode"
        @onClickPopPartner="onClickPopPartner"
        @onChangeVoucherDate="getExchangeInfo"
      />
      <!-- 인프라정산서 전표정보 -->
      <InfraVoucher
        v-else
        :docflag="docflag"
        :voucher.sync="certificateItemDataSync.voucher"
        :isAddApprover="isAddApprover"
        :isInfra="isInfraStatement"
        :deptInfo="deptInfo"
        @onClickPopAccount="onClickPopAccount"
        @onClickPopTax="onClickPopTax"
        @onClickPopDept="onClickPopDept"
        @onClickSearchProjectCode="onClickSearchProjectCode"
        @onClickPopAssetsGubun="onClickPopAssetsGubun"
        @onChangeVoucherDate="getExchangeInfo"
      />
      <!-- 자산 -->
      <StatementAsset v-if="hasAsset" :assetVoucher.sync="selectedAssetAccountItem" />
      <!-- 2차에 자산검색기능 적용예정 -->
      <!-- @onClickPopAssets="onClickPopAssets" -->
      <!-- 기간인식 -->
      <!-- v-if="voucher > voucherItems 중 계정과목이 선급비용(기간인식)인 항목이 하나라도 있으면 노출" : 상세화면설계서 v1.0 기준 59Page 참고하여 진행 -->
      <!--  && apprType == 'F' -->
      <StatementPeriod
        v-if="hasPeriod"
        :index="index"
        :voucher.sync="certificateItemDataSync.voucher"
        @onClickPopAccount="onClickPopAccount"
        @onClickPopTax="onClickPopTax"
      />
      <!-- 원천세 -->
      <StatementHoldingTax
        v-if="certificateItemDataSync.proofType == 'WT'"
        :withholdingTax.sync="certificateItemDataSync.voucher.withholdingTax"
        @setIsSubdivisionData="setIsSubdivisionData"
      />
      <!-- 부가세 - 최종결재자만 visible && 원천세랑 부가세가 함께 나오면 안된다 (함께 나오는 경우에서는 원천세가 우선) -->
      <StatementSurtax
        v-if="certificateItemDataSync.proofType != 'WT'"
        :accountCode="certificateItemDataSync.voucher.accountCode"
        :proofType="certificateItemDataSync.proofType"
        :surtax.sync="certificateItemDataSync.voucher.surtax"
      />
    </div>
    <!-- 국세청 세금계산서/계산서 내역 팝업 -->
    <PopBill
      v-if="isPopBill"
      :selectedType="billType"
      @onClickClose="onClickClosePopBill"
      @onClickComplete="onClickCompletePopBill"
    />
    <PopCorporateCard
      v-if="isPopCorporateCard"
      @onClickClose="onClickClosePopCorporateCard"
      @onClickComplete="onClickCompletePopCorporateCard"
    />
    <!-- 계약 정보 불러오기 팝업 -->
    <PopContractinfo
      v-if="isPopContractinfo"
      :selectedData="contractInfo"
      @onClickClose="onClickClosePopContractinfo"
      @onClickComplete="onClickCompletePopContractinfo"
    />
    <!-- 발주서 불러오기 팝업 -->
    <PopOrder
      v-if="isPopOrder"
      :purchaseRequestWf="model.purchaseRequestWf"
      @onClickClose="onClickClosePopOrder"
      @onClickComplete="onClickCompletePopOrder"
    />
    <!-- 검수 정보 불러오기 팝업 -->
    <PopInspect
      v-if="isPopInspect"
      :contractInfo="contractInfo"
      :contract="certificateItemDataSync.contract"
      :inspectData="inspectData"
      :checkedNames="certificateItemDataSync.contractInspect"
      @onClickClose="onClickClosePopInspect"
      @onClickComplete="onClickCompletePopInspect"
    />
    <!-- 검수 정보 상세 팝업 -->
    <PopInspectDetail
      v-if="isPopInspectDetail"
      :inspectData="popInspectData"
      :contractId="Number(certificateItemDataSync.contract.contractId)"
      @onClickClose="onClickClosePopInspectDetail"
    />
    <!-- 계정과목 검색 팝업 -->
    <PopAccount
      v-if="isPopAccount"
      :crDr="crDr"
      :selectedData="selectedAccount"
      :voucherDate="certificateItemDataSync.voucher.voucherDate"
      @onClickClose="onClickClosePopAccount"
      @onClickComplete="onClickCompletePopAccount"
    />
    <!-- 세목 검색 팝업 -->
    <PopTax
      v-if="isPopTax"
      :accntCode="accntCode"
      :selectedData="selectedTax"
      @onClickClose="onClickClosePopTax"
      @onClickComplete="onClickCompletePopTax"
    />
    <!-- 부서 검색 팝업 -->
    <PopDept
      v-if="isPopDept"
      :deptName="model.purchaseRequestWf.deptName"
      :selectedData="selectedDept"
      @onClickClose="onClickClosePopDept"
      @onClickComplete="onClickCompletePopDept"
    />
    <!-- 서비스 검색 팝업 -->
    <!-- <PopService
      v-if="isPopService"
      @onClickClose="onClickClosePopService"
      @onClickComplete="onClickCompletePopService"
    /> -->
    <!-- 프로젝트코드 검색 팝업 -->
    <PopProjectCodeSearch
      v-if="isPopProjectCodeSearch"
      :selectedProject="selectedProject"
      @onClickClose="onClickClosePopProjectCodeSearch"
      @onClickComplete="onClickCompletePopProjectCodeSearch"
    />
    <!-- 거래처 검색 팝업 -->
    <PopPartner
      v-if="isPopPartner"
      :selectedData="selectedPartenr"
      @onClickClose="onClickClosePopPartner"
      @onClickComplete="onClickCompletePopPartner"
    />
    <!-- :isPartnerInfo="isVoucher" -->
    <!-- 구분 검색 팝업 -->
    <PopAssetsGubun
      v-if="isPopAssetsGubun"
      :selectedData="selectedAssetsGubun"
      @onClickClose="onClickClosePopAssetsGubun"
      @onClickComplete="onClickCompletePopAssetsGubun"
    />
    <!-- 자산 검색 팝업 -->
    <PopAssets
      v-if="isPopAssets"
      @onClickClose="onClickClosePopAssets"
      @onClickComplete="onClickCompletePopAssets"
    />
    <!-- 구매요청 선택된 계약서 팝업 -->
    <PopPurchaseRequest
      v-if="isPopPurchaseRequest"
      :purchaseRequestInfo="model.purchaseRequestWf"
      :contractInfo="contractInfo"
      @onClickClose="onClickClosePopPurchaseRequest"
      @onClickComplete="onClickCompletePopPurchaseRequest"
    />
    <button v-if="!isFold" type="button" class="btn_bar_fold" @click="onClickFold">
      <span class="ico_account ico_bar_fold">접기</span>
    </button>
  </li>
</template>
<script>
import Input from "@/_approval/components/common/input/Input";
import StatementPayment from "./contents/StatementPayment";
import StatementPartner from "./contents/StatementPartner";
import StatmentOtherMenuInfo from "./contents/StatmentOtherMenuInfo";
import StatementVoucher from "./contents/StatementVoucher";
import InfraVoucher from "./contents/InfraVoucher";
import StatementAsset from "./contents/StatementAsset";
import StatementPeriod from "./contents/StatementPeriod";
import StatementHoldingTax from "./contents/StatementHoldingTax";
import StatementSurtax from "./contents/StatementSurtax";
import ContractInfoModel from "../ContractInfoModel";
import OrderInfoModel from "@/_approval/components/statement/write/OrderInfoModel";

import PopBill from "@/_approval/components/statement/write/popup/PopBill";
import PopCorporateCard from "@/_approval/components/statement/write/popup/PopCorporateCard";
import PopContractinfo from "@/_approval/components/statement/write/popup/PopContractinfo";
import PopOrder from "@/_approval/components/statement/write/popup/PopOrder";
import PopInspect from "@/_approval/components/statement/write/popup/PopInspect";
import PopInspectDetail from "@/_approval/components/statement/view/popup/PopInspect";
import PopAccount from "@/_approval/components/statement/write/popup/PopAccount";
import PopTax from "@/_approval/components/statement/write/popup/PopTax";
import PopDept from "@/_approval/components/statement/write/popup/PopDept";
// import PopService from '@/_approval/components/statement/write/popup/PopService';
import PopProjectCodeSearch from "@/_approval/components/purchaseDraft/write/popup/PopProjectCodeSearch";
import PopPartner from "@/_approval/components/statement/write/popup/PopPartner";
import PopAssetsGubun from "@/_approval/components/statement/write/popup/PopAssetsGubun";
import PopPurchaseRequest from "@/_approval/components/statement/write/popup/PopPurchaseRequest";

import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import ApiService from "@/services/ApiService";
import { getLastDayOfCurrentMonth, getLastDayOfNextMonth } from "@/utils/dateUtils";
import PopAssets from "@/_approval/components/statement/write/popup/PopAssets";

export default {
  name: "StatementInfoLine",
  components: {
    PopAssets,
    Input,

    StatementPayment,
    StatementPartner,
    StatmentOtherMenuInfo,
    StatementVoucher,
    InfraVoucher,
    StatementAsset,
    StatementPeriod,
    StatementHoldingTax,
    StatementSurtax,

    PopBill,
    PopCorporateCard,
    PopContractinfo,
    PopOrder,
    PopInspect,
    PopInspectDetail,
    PopAccount,
    PopTax,
    PopDept,
    // PopService,
    PopProjectCodeSearch,
    PopPartner,
    PopAssetsGubun,
    PopPurchaseRequest,
  },
  mixins: [CommLayerMixin],
  props: {
    certificateItemData: Object,
    //inspectHistory:Array,
    index: Number,
    checkedNames: Array,
    isInfraStatement: Boolean,
    purchaseContract: Object,
    model: Object,
    docflag: String,

    deptInfo: Object,
    isAddApprover: Boolean,
  },
  data() {
    return {
      // isFold: true,
      isFold: false,
      // isEdit: false,
      isPopBill: false,
      isPopCorporateCard: false,
      isPopContractinfo: false,
      isPopOrder: false,
      isPopInspect: false,
      isPopAccount: false,
      isPopTax: false,
      isPopDept: false,
      // isPopService: false,
      isPopProjectCodeSearch: false,
      isPopPartner: false,
      isPopAssetsGubun: false,
      isPopAssets: false,
      selectedIdx: 0,
      isPopFromPeriod: false,
      isPopPurchaseRequest: false,
      isPopInspectDetail: false,
      popInspectData: {},
      contractInfo: {},
      billType: "taxBill",
      inspectData: [],
      crDr: "",
      // isVoucher:false,
      accntCode: "",

      assetOpenIndex: null, // 자산전표항목 활성화 index
      assetVoucherIndexArray: [], // 자산전표항목 index 모음
    };
  },
  computed: {
    certificateItemDataSync: {
      get() {
        return this.certificateItemData;
      },
      set(value) {
        this.$emit("update:certificateItemData", value);
      },
    },
    checkedNamesSync: {
      get() {
        return this.checkedNames;
      },
      set(value) {
        this.$emit("update:checkedNames", value);
      },
    },
    hasPeriod() {
      if (this.isInfraStatement) {
        this.model._hasPeriod = false;
        return false;
      }

      if (!this.certificateItemData.voucher || !this.certificateItemData.voucher.voucherItems) {
        this.model._hasPeriod = false;
        return false;
      }

      const periodVoucher = this.certificateItemData.voucher.voucherItems
        .filter((voucher) => voucher.accountName)
        .filter((voucher) => voucher.accountName.indexOf("선급비용(기간인식)") > -1);

      if (periodVoucher.length > 0) {
        // 기간인식 내 손익부서, 손익서비스 데이터는 '선급비용(기간인식)'을 계정과목으로 가지고있는 전표항목의 부서와 서비스 값을 가진다
        // 그럴일은 없지만 기능상 '선급비용(기간인식)'을 계정과목으로 가지고있는 전표항목이 여러개일 경우 첫번째 항목의 값을 가져온다
        const voucherData = this.certificateItemData.voucher;
        const periodVoucherItemData = this.certificateItemData.voucher.voucherItems.find(
          (voucher) => voucher.accountName.indexOf("선급비용(기간인식)") > -1,
        );

        voucherData.incomeLossDeptCode = periodVoucherItemData.deptCode;
        voucherData.incomeLossDeptName = periodVoucherItemData.deptName;
        voucherData.incomeLossServiceCode = periodVoucherItemData.serviceCode;
        voucherData.incomeLossServiceName = periodVoucherItemData.serviceName;
        this.model._hasPeriod = true;
        return true;
      } else {
        // 기간인식 => 일반 계정과목으로 수정시 기간인식 값 초기화
        const voucherData = this.certificateItemData.voucher;

        voucherData.incomeLossAccountCode = "";
        voucherData.incomeLossAccountName = "";
        voucherData.incomeLossServiceCode = "";
        voucherData.incomeLossServiceName = "";
        voucherData.incomeLossDeptCode = "";
        voucherData.incomeLossDeptName = "";
        voucherData.incomeLossServiceName = "";
        voucherData.incomeLossTaxItem = "";
        voucherData.incomeLossTaxName = "";

        this.model._hasPeriod = false;
        return false;
      }
    },
    assetAccountConst() {
      let assetAccount = [];
      // 임시 고정할 자산 노출 계정과목 - 추후 계정과목 API에 유무 데이터 추가 이후 변경 예정

      return assetAccount;
    },
    selectedAssetAccountItem() {
      // 활성화된 전표데이터
      return this.certificateItemData.voucher.voucherItems[this.assetOpenIndex];
    },
    // 자산전표가 있는지 여부
    hasAsset() {
      this.setAssetAccount();
      if (this.assetVoucherIndexArray.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    // 선택된 계약정보
    selectedContractinfo() {},
    // 선택된 계정과목
    selectedAccount() {
      const voucehrItem = this.certificateItemData.voucher.voucherItems[this.selectedIdx];
      let obj = {};
      obj.accountCode = voucehrItem.accountCode;
      obj.accountName = voucehrItem.accountName;
      return obj;
    },
    // 선택된 세목
    selectedTax() {
      const voucehrItem = this.certificateItemData.voucher.voucherItems[this.selectedIdx];
      let obj = {};
      obj.detailCode = voucehrItem.taxItem;
      obj.detailName = voucehrItem.taxName;
      return obj;
    },
    // 선택된 부서
    selectedDept() {
      const voucehrItem = this.certificateItemData.voucher.voucherItems[this.selectedIdx];
      let obj = {};
      obj.departmentCode = voucehrItem.deptCode;
      obj.departmentName = voucehrItem.deptName;
      return obj;
    },
    // 선택된 프로젝트코드
    selectedProject(b1) {
      const voucehrItem = this.certificateItemData.voucher.voucherItems[this.selectedIdx];

      let obj = {};
      obj.projectCode = voucehrItem.serviceCode;
      obj.projectName = voucehrItem.serviceName;

      return obj;
    },
    // 선택된 거래처
    selectedPartenr() {
      let obj = {};
      obj.companyName = this.certificateItemData.partnerName;
      obj.companyId = this.certificateItemData.voucher.erpPartnerCode;
      obj.corporateNum = this.certificateItemData.corporateNum;
      return obj;
    },
    // 선택된 구분
    selectedAssetsGubun() {
      const voucehrItem = this.certificateItemData.voucher.voucherItems[this.selectedIdx];
      let obj = {};
      obj.assetsGubunCode = voucehrItem.newUsed;
      obj.assetsGubunName = voucehrItem.newUsed == "NEW" ? "신규" : "중고";
      return obj;
    },
  },
  created() {},
  beforeMount() {
    if (this.purchaseContract) {
      this.contractInfo = this.purchaseContract;
    }
  },
  methods: {
    // 자산전표세팅
    setAssetAccount(index) {
      if (
        this.isInfraStatement ||
        !this.certificateItemData.voucher ||
        !this.certificateItemData.voucher.voucherItems
      )
        return;
      const assetVoucherIndexArray = []; // 자산전표 index 모음
      this.certificateItemData.voucher.voucherItems.forEach((voucherItem, voucherItemIndex) => {
        let assetVoucherItem = this.setAssetVoucher(voucherItem); // 자산항목중에 전표항목 찾기
        // 전표항목이 자산항목이 있을경우
        if (assetVoucherItem) {
          voucherItem.infraAccountYn = true;
          assetVoucherIndexArray.push(voucherItemIndex);
          // 전표항목이 자산항목이 아닐경우
        } else {
          voucherItem.infraAccountYn = false;
        }
      });
      // 기존 활성화된 자산전표가 있으나 자산전표가 없을경우 (활성화된 마지막 자선전표가 삭제되었을때)
      if (assetVoucherIndexArray.length == 0 && this.assetOpenIndex != null) {
        this.onUpdateAssetOpen(null);
        // 자산전표가 있으나 기존 활성화된 자산전표가 없을경우 (활성화된 자산전표가 삭제되고 다른 자선전표가 있을때)
      } else if (assetVoucherIndexArray.length != 0 && this.assetOpenIndex == null) {
        this.onUpdateAssetOpen(assetVoucherIndexArray[0]);
        // 활성화 자산전표가 자산전표데이터에 없을 경우
      } else if (!assetVoucherIndexArray.find((index) => index == this.assetOpenIndex)) {
        this.onUpdateAssetOpen(assetVoucherIndexArray[0]);
      }
      // 전표항목 추가,삭제 등으로 전표항목 index가 변경되었을 경우
      if (index) {
        this.onUpdateAssetOpen(index);
      }
      this.assetVoucherIndexArray = assetVoucherIndexArray;
    },
    //자산항목중에 전표항목 찾아 세팅
    setAssetVoucher(voucherItem) {
      return this.assetAccountConst.find((item) => item.accountCode == voucherItem.accountCode);
    },
    //자산전표활성화index 업데이트
    onUpdateAssetOpen(index) {
      this.assetOpenIndex = index;
    },
    onClickFold() {
      this.isFold = !this.isFold;
    },
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    removeStatementLine() {
      this.$emit("removeStatementLine", this.index);
    },
    copyStatementLine() {
      //this.$emit('copyStatementLine', this.index);
      this.$emit("copyStatementLine", this.index, this.certificateItemData);
    },
    // 국세청 세금계산서/계산서 내역 팝업
    onClickBill(type) {
      this.billType = type == "AT" ? "taxBill" : "bill";

      this.isPopBill = true;
    },
    // 국세청 세금계산서/계산서 내역 팝업 닫기
    onClickClosePopBill() {
      this.isPopBill = false;
    },
    // 국세청 세금계산서/계산서 내역 팝업 선택완료
    onClickCompletePopBill(data, bilType) {
      this.isPopBill = false;
      // 증빙일자 etaxDate , 증빙번호 ntsId, 지급예정일 , 공급가액 price , 지급처, 지급처 "1058812323" => 조회 후  사업자/주민등록번호 자동입력
      const billData = data[0];
      this.certificateItemData.proofDate = billData.etaxDate;
      this.certificateItemData.proofNum = billData.ntsId;

      this.certificateItemData.payment.supplyPrice = billData.price;
      // this.certificateItemData.payment.expenditureDate = expenditureDate;
      this.certificateItemData.payment.modifiedFlag = false;

      // 증빙일자 = 전표의 전표일자,증빙일자와 동일
      this.certificateItemData.voucher.voucherDate = billData.etaxDate;
      this.certificateItemData.voucher.proofDate = billData.etaxDate;

      // 인프라정산서
      // 세금계산서(자동)일때만 택스
      if (bilType == "taxBill") {
        this.certificateItemData.payment.taxAmount = billData.tax;
      }

      const total = Number(billData.price) + Number(billData.tax);
      this.certificateItemData.payment.totalAmount = String(total);

      // 지급예정일 국세청세금계산서/계산서, 법인카드 선택시 자동 입력
      const expenditureDate = getLastDayOfNextMonth();
      if (expenditureDate.getDay == 0 || expenditureDate.getDay == 6) {
        this.getExpenditureDate();
      }

      if (!this.isInfraStatement) {
        this.setVoucherPrice();
      }

      this.certificateItemData.partnerName = billData.partnerNm;
      this.certificateItemData.corporateNum = billData.corporateNum;

      this.certificateItemData.partnerName = billData.partnerNm;
      this.certificateItemData.corporateNum = billData.corporateNum;

      //if(this.isInfraStatement) {
      this.certificateItemData.voucher.erpPartnerName = billData.partnerNm || "";
      this.certificateItemData.voucher.erpPartnerCode = billData.erpPartnerCode || "";
      //}
      this.certificateItemData.voucher.erpVoucherNo = billData.erpVoucherNo;
      // 증빙일자 - 전표일자 동일
      this.certificateItemData.voucher.voucherDate = billData.etaxDate;

      this.certificateItemData.voucher.voucherItems.forEach((item) => {
        item.partner = billData.partnerNm;
      });
      // 지급처의 사업자/주민등록번호 사본, 통장 사본 조회
      this.getPartnerInfo(billData.corporateNum);
    },
    setVoucherPrice() {
      const debitRow = this.certificateItemData.voucher.voucherItems.filter(
        (item) => (item.crdr === "DR" || item.debit !== "") && item.surtaxYn == false,
      );
      const creditRow = this.certificateItemData.voucher.voucherItems.filter(
        (item) => item.crdr === "CR" || item.credit !== "",
      );

      if (debitRow.length == 0) return;

      if (debitRow.length > 1) {
        debitRow.forEach((item) => {
          item.debit = "";
          item.suplyPriceFlag = true;
        });
      } else {
        debitRow[0].debit = this.certificateItemData.payment.supplyPrice;
        debitRow[0].suplyPriceFlag = true;
      }

      creditRow[0].credit = this.certificateItemData.payment.totalAmount;
    },
    // 법인카드 사용내역 팝업
    onClickPopCorporateCard() {
      this.isPopCorporateCard = true;
    },
    // 법인카드 사용내역 팝업 닫기
    onClickClosePopCorporateCard(data) {
      this.isPopCorporateCard = false;
    },
    // 법인카드 사용내역 팝업 선택완료
    onClickCompletePopCorporateCard(data) {
      this.isPopCorporateCard = false;

      // 증빙번호, 증빙일, 총금액
      this.certificateItemData.payment.certificateId = data.approvalNo;
      this.certificateItemData.proofDate = data.dealDt;
      this.certificateItemData.payment.totalAmount = data.sumAmount;
      this.certificateItemData.payment.supplyPrice = data.sumAmount;

      this.certificateItemData.voucher.voucherDate = data.dealDt;
      this.certificateItemData.voucher.proofDate = data.dealDt;

      //this.getDataWithCorpData(data);
      if (!this.isInfraStatement) {
        this.setVoucherPrice();
      }
    },
    // 계약 정보 불러오기
    onClickContractinfo() {
      const requestCid = this.model.purchaseRequestWf.requestCid || "";
      if (requestCid == "") {
        this.isPopContractinfo = true;
      } else {
        this.isPopPurchaseRequest = true;
      }
    },
    // 계약 정보 불러오기 닫기
    onClickClosePopContractinfo() {
      this.isPopContractinfo = false;
    },
    // 계약 정보 불러오기 선택완료
    onClickCompletePopContractinfo(data) {
      this.isPopContractinfo = false;
      // 계약명, 날짜, 담당자 data
      //구매요청정보 연결 시 해당 구매요청정보에 엮인 계약정보 불러옴. 구매요청정보 연결하지 않은 경우
      // 계약정보 불러오기 팝업을 통해 역으로 계약정보에 엮인 구매요청정보를 불러옴
      // 계약정보 클릭 시 해당 계약정보 상세페이지 새창으로 생성 . 정산서 등록 프로세스 참고

      const obj = {};
      obj.contractId = data.contractId;
      obj.contractStartDate = data.contractStartDate;
      obj.description = data.description;

      this.contractInfo = data;
      this.$emit("update:purchaseContract", data);
      this.certificateItemData.contract = obj;

      // 1) 구매요청이 연결안되어있다면 구매요청정보 조회 API 호출
      // this.$emit('completedContractInfo', data.contractId);
      this.getPurchaseDraft(data.requestCid);
      // 2) 검수목록 조회 API 호출
      this.getInspectList(data.requestCid);
      //this.$emit('setContractInfo', data) ;
    },
    // 검수 상세 팝업 열기
    onClickPopInspectDetail(item) {
      this.popInspectData = item;
      this.isPopInspectDetail = true;
    },
    // 검수 상세 팝업 닫기
    onClickClosePopInspectDetail() {
      this.isPopInspectDetail = false;
      this.popInspectData = {};
    },
    // 발주서 불러오기
    onClickPopOrder() {
      if (!this.model.purchaseRequestWf) {
        this._showLayer({
          content: "구매요청서를 먼저 선택해주세요",
          type: "alert",
        });

        return;
      }
      this.isPopOrder = true;
    },
    // 발주서 불러오기 닫기
    onClickClosePopOrder() {
      this.isPopOrder = false;
    },
    // 발주서 불러오기 선택완료
    onClickCompletePopOrder(data) {
      this.isPopOrder = false;
      const itemModel = new OrderInfoModel();
      itemModel.setData(data);
      this.certificateItemData.order = itemModel;
    },
    // 검수 정보 불러오기
    onClickPopInspect() {
      if (!this.certificateItemData.contract.contractId) {
        this._showLayer({ type: "alert", content: "계약정보를 선택해주세요." });
        return;
      }

      this.isPopInspect = true;
    },
    // 검수 정보 불러오기 닫기
    onClickClosePopInspect() {
      this.isPopInspect = false;
    },
    // 검수 정보 불러오기 선택완료
    onClickCompletePopInspect(data) {
      this.isPopInspect = false;

      const contractId = this.contractInfo.contractId;
      this.certificateItemData.contractInspect = data;

      const history = data.map((item) => {
        const obj = {};
        obj.calculateDate = item.calculateDate;
        obj.calculateNum = item.calculateNum;
        obj.inspectCompleteDate = item.inspectCompleteDate;
        obj.inspectPrice = item.inspectPrice;
        return obj;
      });

      // this.inspectHistory = history;
      this.certificateItemData.contractInspect = history;
    },
    // 계정과목 검색
    onClickPopAccount(idx, gubun) {
      // 기간인식의 손익계정과목 선택시
      if (gubun == "period") {
        this.isPopAccount = true;
        this.isPopFromPeriod = true;
        return;
      }

      // 전표의 계정과목 선택시
      this.selectedIdx = idx;
      const hasPeriod = this.certificateItemData.voucher.voucherItems
        ? this.certificateItemData.voucher.voucherItems.filter(
            (voucher) => voucher.accountName.indexOf("선급비용(기간인식)") > -1,
          )
        : [];
      if (hasPeriod.length < 1 && gubun == "period") {
        const layer = {};
        layer.type = "alert";
        layer.order = 2;
        layer.content = "전표를 먼저 선택해주세요";
        this._showLayer(layer);

        return;
        this.crDr = "DR";
      } else {
        if (!this.certificateItemData.voucher.voucherDate) {
          const layer = {};
          layer.type = "alert";
          layer.order = 2;
          layer.content = "전표일자를 선택해주세요";
          this._showLayer(layer);

          return;
        }

        if (this.isInfraStatement) {
          // 차변 : DR / 대변 : CR
          this.crDr = "DR";
        } else {
          const fixCrDr = this.certificateItemData.voucher.voucherItems[idx];
          if (fixCrDr.crdr == "CR") {
            this.crDr = "CR";
          } else {
            this.crDr = "DR";
          }
        }
      }
      this.isPopAccount = true;
    },
    // 계정과목 검색 닫기
    onClickClosePopAccount() {
      this.isPopAccount = false;
      this.isPopFromPeriod = false;
    },
    // 계정과목 검색 선택완료
    onClickCompletePopAccount(obj) {
      this.isPopAccount = false;
      const voucehrData = this.certificateItemData.voucher;
      const voucehrItem = voucehrData.voucherItems[this.selectedIdx];
      // 기간인식의 손익계정과목 선택시
      if (this.isPopFromPeriod) {
        voucehrData.incomeLossAccountCode = obj.accountCode;
        voucehrData.incomeLossAccountName = obj.accountName;
        voucehrData.incomeLossTaxItem = "";
        voucehrData.incomeLossTaxName = "";
        this.isPopFromPeriod = false;

        // 전표의 계정과목 선택시
      } else {
        // this.accntCode = obj.code;
        voucehrItem.accountCode = obj.accountCode;
        voucehrItem.accountName = obj.accountName;
      }
      // 자산의 계정과목 선택시
      if (Boolean(this.setAssetVoucher(obj))) {
        // 자산항목중에 전표항목 찾아 여부 확인
        // 계정과목 선택한 자산전표항목 활성화
        this.assetOpenIndex = this.selectedIdx;
        // 자산 계정과목이 아닌데 기존 자산계정과목값이 있다면 리셋
      } else if (voucehrItem.infraAccountYn) {
        voucehrItem.infraAccountYn = false;
        voucehrItem.goodsName = "";
        voucehrItem.assetMainClassification = "";
        voucehrItem.assetMainClassificationName = "";
        voucehrItem.assetSubClassification = "";
        voucehrItem.amount = "";
      }
      this.setAssetAccount();
      // 계정과목 변경시 세목도 리셋되게
      voucehrItem.taxItem = "";
      voucehrItem.taxName = "";
    },
    // 세목 검색
    onClickPopTax(idx, gubun) {
      let accntCode = "";
      let layerContent = "";
      // 기간인식의 세목 선택시
      if (gubun == "period") {
        this.isPopFromPeriod = true;
        accntCode = this.certificateItemData.voucher.incomeLossAccountCode;
        layerContent = "손익계정과목을 먼저 선택해주세요";

        // 전표의 세목 선택시
      } else {
        this.selectedIdx = idx;
        accntCode = this.certificateItemData.voucher.voucherItems[this.selectedIdx].accountCode;
        layerContent = "계정과목을 먼저 선택해주세요";
      }

      if (!accntCode || accntCode == "") {
        const layer = {};
        layer.type = "alert";
        layer.order = 2;
        layer.content = layerContent;
        this._showLayer(layer);

        return;
      }
      this.accntCode = accntCode;
      this.isPopTax = true;
    },
    // 세목 검색 닫기
    onClickClosePopTax() {
      this.isPopTax = false;
      this.isPopFromPeriod = false;
    },
    // 세목 검색 선택완료
    onClickCompletePopTax(obj) {
      this.isPopTax = false;
      // 기간인식의 세목 선택시
      if (this.isPopFromPeriod) {
        this.certificateItemData.voucher.incomeLossTaxItem = obj.detailCode;
        this.certificateItemData.voucher.incomeLossTaxName = obj.detailName;
        this.isPopFromPeriod = false;

        // 전표의 세목 선택시
      } else {
        this.certificateItemData.voucher.voucherItems[this.selectedIdx].taxItem = obj.detailCode;
        this.certificateItemData.voucher.voucherItems[this.selectedIdx].taxName = obj.detailName;
      }
    },
    // 부서 검색
    onClickPopDept(idx, gubun) {
      this.selectedIdx = idx;
      this.isPopDept = true;
    },
    // 부서 검색 닫기
    onClickClosePopDept() {
      this.isPopDept = false;
    },
    // 부서 검색 선택완료
    onClickCompletePopDept(data) {
      // this.selectedIdx = idx;
      this.isPopDept = false;

      // this.certificateItemData.voucher.voucherItems.forEach(item => {
      //   item.deptCode = data.departmentCode;
      //   item.deptName = data.departmentName;
      // });
      for (var i = 0; i < this.certificateItemData.voucher.voucherItems.length; i++) {
        this.certificateItemData.voucher.voucherItems[i].deptCode = data.departmentCode;
        this.certificateItemData.voucher.voucherItems[i].deptName = data.departmentName;
      }

      this.deptInfo.deptCode = data.departmentCode;
      this.deptInfo.deptName = data.departmentName;
    },
    // 서비스 검색
    // onClickPopService(idx){
    //   this.selectedIdx = idx;
    //   this.isPopService = true;
    // },
    // // 서비스 검색 닫기
    // onClickClosePopService(){
    //   this.isPopService = false;
    // },
    // // 서비스 검색 선택완료
    // onClickCompletePopService(data){
    //   this.isPopService = false;

    //   this.certificateItemData.voucher.voucherItems[this.selectedIdx].serviceCode = data.serviceCode;
    //   this.certificateItemData.voucher.voucherItems[this.selectedIdx].serviceName = data.serviceName;
    // },
    // 프로젝트 코드 검색 팝업 열기
    onClickSearchProjectCode(idx) {
      this.selectedIdx = idx;
      this.isPopProjectCodeSearch = true;
    },
    // 프로젝트 코드 검색 팝업 닫기
    onClickClosePopProjectCodeSearch() {
      this.selectedIdx = null;
      this.selectedProject = {};
      this.isPopProjectCodeSearch = false;
    },
    // 프로젝트 코드 검색 팝업 선택완료
    onClickCompletePopProjectCodeSearch(data) {
      this.isPopProjectCodeSearch = false;

      // 여러개 변경시
      const code = data.projectCode;
      const name = data.projectName;
      this.certificateItemData.voucher.voucherItems.forEach((item) => {
        item.serviceCode = code;
        item.serviceName = name;
      });

      this.selectedIdx = null;
      this.selectedProject = {};
    },
    // 거래처 검색
    onClickPopPartner(idx) {
      // isVoucher
      this.isPopPartner = true;
      this.selectedIdx = idx;
      // if(isVoucher == 'voucher') this.isVoucher = true;
    },
    // 거래처 검색 닫기
    onClickClosePopPartner() {
      this.isPopPartner = false;
    },
    // 거래처 검색 선택완료 (지급처검색, 전표 거래처검색 팝업)
    onClickCompletePopPartner(data) {
      // isPartner
      // 지급처의 지급처 조회 = 지출전표의 거래처 조회
      this.isPopPartner = false;

      //if(this.isInfraStatement) {
      this.certificateItemData.voucher.erpPartnerName = data.companyName || "";
      this.certificateItemData.voucher.erpPartnerCode = data.companyId || "";

      this.certificateItemData.corporateNum = data.corporateNum || "";
      this.certificateItemData.partnerName = data.companyName || "";
      this.certificateItemData.partnerName = data.companyName || "";
      //}

      this.certificateItemData.corporateNum = data.corporateNum;
      this.certificateItemData.voucher.voucherItems.forEach((item) => {
        item.partner = data.companyName;
      });
      this.getPartnerInfo(data.corporateNum);
      // if(isPartner) {
      //   this.certificateItemData.voucher.voucherItems.forEach(item => {
      //       item.partner = data.companyName;
      //   });
      //   this.getPartnerInfo(data.corporateNum);
      // } else {
      //   this.certificateItemData.voucher.voucherItems.forEach(item => {
      //       item.partner = data.companyName;
      //   });
      // }
    },
    // 구분 검색
    onClickPopAssetsGubun(index, gubun) {
      this.selectedIdx = index;

      this.isPopAssetsGubun = true;
    },
    // 구분 검색 닫기
    onClickClosePopAssetsGubun() {
      this.isPopAssetsGubun = false;
    },
    // 구분 검색 선택완료
    onClickCompletePopAssetsGubun(data) {
      this.isPopAssetsGubun = false;
      this.certificateItemData.voucher.voucherItems[this.selectedIdx].newUsed =
        data.assetsGubunCode;
      //this.certificateItemData.voucher.voucherItems[this.selectedIdx].assetsGubunName = data.assetsGubunName;
    },

    async getDataWithCorpData(data) {
      // 증빙일자, 증빙번호, 지급예정일, 공급가액, 지급처, 지급처 사업자/주민등록번호 자동입력
      // proofDate, proofNum,  expenditureDate, supplyPrice , partner , businessRegFile
      const path = `${this.$apiPath.SETTLEMENT_USEPROOF}/${data.erpVoucherNo}`;

      const res = ApiService.shared.getData(path);
      if (res.data) {
        //console.log(res.data);
      }
    },
    async getCorporateInfo(corporateNm) {
      const path = `${this.$apiPath.VOUCHER_PARTNER}?corporateNum=${corporateNm}`;

      const res = await ApiService.shared.getData(path);

      if (res.data) {
        const company = res.data[0];
        this.certificateItemData.partnerName = company.companyName || "";
        this.certificateItemData.corporateNum = company.corporationNum || "";
      }
    },
    async getPartnerInfo(corporateNm) {
      const path = `${this.$apiPath.SETTLEMENT_PARTNER}/${corporateNm}`;

      const res = await ApiService.shared.getData(path);

      if (!res.data) {
        // 우리쪽 파트너에 등록이 안되어있으면 partner를 빈값으로 보낸다.
        //  this.certificateItemData.partner = {};
        this.certificateItemData.businessRegFile = {};
        this.certificateItemData.bankBookFile = {};
        this.certificateItemData.userNum = null;
        return;
      }
      // 예상 데이터 : businessRegFile, bankBookFile
      // this.certificateItemData.businessRegFile = res.data.businessRegFile ? res.data.businessRegFile[0] : {};
      // this.certificateItemData.bankBookFile = res.data.bankBookFile ? res.data.bankBookFile[0] : {};
      this.certificateItemData.businessRegFile = res.data.businessRegFile
        ? res.data.businessRegFile
        : {};
      this.certificateItemData.bankBookFile = res.data.bankBookFile ? res.data.bankBookFile : {};

      this.certificateItemData.userNum = res.data.userNum;
    },
    async getExpenditureDate() {
      const dayLabel = lastDay.getDay();
      if (dayLabel == 0 || dayLabel == 6) {
        // 지급예정일 after api 호출
        const path = `${this.$apiPath.WORKING_DAY}?date=${lastDay}`;
        const res = await ApiService.shared.getData(path);

        if (res.data) {
          this.certificateItemData.payment.expenditureDate = res.data.workingDate;
        }
      }
    },
    onClickRemoveContract(deleteData) {
      this.certificateItemData.contract = {};
      this.certificateItemData.contractInspect = [];
      this.contractInfo = {};
      this.$emit("update:purchaseContract", {});
    },
    onClickRemoveInspect(deleteData) {
      this.certificateItemData.contractInspect = this.certificateItemData.contractInspect.filter(
        (item) => item.calculateNum !== deleteData.calculateNum,
      );
    },
    onClickRemoveOrder(deleteData) {
      this.certificateItemData.order = {};
    },
    async getInspectList(requestCid) {
      const path = `${this.$apiPath.SETTLEMENT_CALCULATE}/${requestCid} `;
      const res = await ApiService.shared.getData(path);

      if (res.data) {
        this.inspectData = res.data.map((item) => {
          const obj = {};
          obj.calculateNum = item.calculateNum;
          obj.deliveryCompleteDate = item.deliveryCompleteDate;
          obj.description = item.description;
          obj.calculateDate = item.calculateDate;
          obj.inspectCompleteDate = item.inspectCompleteDate;
          obj.inspectPrice = item.inspectPrice;
          obj.stateCode = item.stateCode;
          return obj;
        });

        this.certificateItemData.contractInspect = this.inspectData;
      }
    },
    async getPurchaseDraft(requestId) {
      // const path = `${ this.$apiPath.SETTLEMENT_PURCHASE }?searchType=contractCid&searchValue=${ contractId } `
      const path = `${this.$apiPath.SETTLEMENT_PURCHASE}?requestCid=${requestId} `;

      const res = await ApiService.shared.getData(path);

      if (res.data && res.data.length > 0) {
        const purchase = res.data[0];
        this.model.purchaseRequestWf = {
          assetType: purchase.assetType,
          currency: purchase.currency,
          name: purchase.name,
          price: purchase.price,
          // regDate: purchase.regDate,
          requestCid: purchase.requestCid,
          title: purchase.title,
        };
      }
    },
    getExchangeInfo() {
      this.exchangeInfo();
    },
    async exchangeInfo() {
      //
      const fromCurrency = this.certificateItemData.payment.currency;
      if (!fromCurrency) return;

      const exchangeDate = this.certificateItemData.voucher.voucherDate.replaceAll("-", "");

      const path = `${this.$apiPath.VOUCHER_EXCHANGE_RATE}?exchangeDate=${exchangeDate}&exchangeType=S&fromCurrency=${fromCurrency}&toCurrency=KRW`;

      const res = await ApiService.shared.getData(path);

      if (res.data) {
        this.certificateItemData.voucher.currency = res.data.currency;
        this.certificateItemData.voucher.exchangeRateDate = res.data.date;
        this.certificateItemData.voucher.exchangeRate = res.data.exchangeRate;
      }
    },
    onClickClosePopPurchaseRequest() {
      this.isPopPurchaseRequest = false;
    },
    onClickCompletePopPurchaseRequest(purchase, contract) {
      this.isPopPurchaseRequest = false;

      // 구매요청서 선택된 계약서 연결
      if (contract) {
        this.model.certificateList.forEach((item) => {
          const obj = {};
          obj.contractId = contract.contractId;
          obj.contractStartDate = contract.contractStartDate;
          obj.description = contract.description;
          item.contract = obj;
        });
        this.contractInfo = contract;
        this.$emit("update:purchaseContract", contract);

        // 2) 검수목록 조회 API 호출
        this.getInspectList(purchase.requestCid);
      }
    },
    setIsSubdivisionData(isSubdivisionData) {
      this.model._isSubdivisionData = isSubdivisionData;
    },
  },
};
</script>
<style scoped>
.head_fold {
  overflow: hidden;
  padding: 8px 32px 8px 24px;
  border-bottom: 1px solid #e1e1e1;
}
.box_choice {
  float: left;
}

/* 제목영역 */
.fold_subject {
  overflow: hidden;
  padding-left: 24px;
}
.fold_subject .txt_subject {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 0;
  line-height: 18px;
  color: #222;
}
/* 제목영역 버튼 */
/* .btn_edit{display:block;max-width:100%;outline:none}
.btn_edit .ico_edit{float:right;margin:9px 8px 10px}
.btn_complete{display:inline-block;height:32px;padding:0 13px;vertical-align:top;outline:none}
.btn_complete .ico_complete{margin:9px 0} */

/* 우측 영역 버튼 */
.btn_delete,
.btn_copy {
  display: inline-block;
  height: 32px;
  padding: 6px 0 8px;
  line-height: 18px;
  color: #888;
  vertical-align: top;
  outline: none;
}
.btn_delete:disabled,
.btn_copy:disabled {
  color: #c4c4c4;
}
.btn_delete .ico_delete {
  margin: 3px 8px 0 0;
}
.btn_copy {
  margin-left: 40px;
}
.btn_copy .ico_copy {
  margin: 3px 6px 0 0;
}
.btn_fold {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 4px 0 4px 64px;
  outline: none;
}
.btn_fold .ico_fold {
  margin: 8px 5px;
}

.btn_bar_fold {
  display: block;
  width: 100%;
  height: 15px;
  padding: 4px 0 5px;
  border-top: 1px solid #ccc;
  background-color: #f5f5f5;
}

/* 열렸을때 */
.open .head_fold {
  background-color: #fbfbfb;
}
/* .open .txt_subject{color:#5551CE} */

/* 내용 영역 */
.body_fold {
  padding: 39px 24px 72px;
}
.body_fold /deep/ .tbl_comm {
  border-top: 1px solid #e1e1e1;
}
</style>
