import { stringToDateWithTime, dateToStringWithTime } from "@/utils/dateUtils";

class OrderInfoModel {
  constructor() {
    this.orderCid = ""; // 발주 일련번호
    this.orderDate = null; // 발주 날짜
    this.status = ""; // 발주 상태
    this.title = ""; // 발주서 명
  }

  setData(obj) {
    if (obj.orderCid) this.orderCid = obj.orderCid;
    if (obj.orderDate) this.orderDate = stringToDateWithTime(obj.orderDate);
    if (obj.status) this.status = obj.status;
    if (obj.title) this.title = obj.title;
  }

  getData() {
    let obj = {
      orderCid: this.orderCid,
      orderDate: dateToStringWithTime(this.orderDate),
      status: this.status,
      title: this.title,
    };
    return obj;
  }
}

export default OrderInfoModel;
