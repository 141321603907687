<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 1100px">
      <div class="layer_head">
        <strong class="tit_popup">검수 정보 불러오기</strong>
        <span class="txt_popup">{{ !isInspectDetail ? "목록" : "상세" }}</span>
      </div>
      <!-- 목록 -->
      <div v-if="!isInspectDetail" class="layer_body">
        <div class="tbl_comm tbl_list tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 316px); max-height: 392px; min-height: 49px"
          >
            <table>
              <colgroup>
                <col style="width: 52px" />
                <!-- 선택 -->
                <col style="width: 179px" />
                <!-- 정산ID -->
                <col style="width: 170px" />
                <!-- 검수예정일 -->
                <col style="width: 170px" />
                <!-- 검수완료일 -->
                <col style="width: 270px" />
                <!-- 검수금액 -->
                <col />
                <!-- 검수상태 -->
              </colgroup>
              <thead>
                <tr>
                  <th style="width: 52px">
                    <div class="box_choice type_alone">
                      <!-- 텍스트 없이 아이콘만 있는 타입 type_alone 클래스 추가 -->
                      <div class="item_choice">
                        <!-- disabled 상태시 disabled 클래스 추가 -->
                        <input
                          id="checkLineAll"
                          v-model="checkAll"
                          type="checkbox"
                          class="inp_choice"
                          name="checkLineAll"
                        />
                        <label class="lab_choice" for="checkLineAll">
                          <span class="ico_account ico_check" />
                        </label>
                      </div>
                    </div>
                  </th>
                  <th style="width: 179px">정산ID</th>
                  <th style="width: 170px">검수예정일</th>
                  <th style="width: 170px">검수완료일</th>
                  <th style="width: 270px">검수금액</th>
                  <th style="width: 195px">검수상태</th>
                </tr>
              </thead>
              <tbody>
                <PopInspectListLine
                  v-for="item in inspectDataList"
                  :key="item.inspectId"
                  :rowData="item"
                  :checkedNames.sync="checkedNamesSync"
                  @onClickInspectDetail="onClickInspectDetail"
                />
                <!-- @onClickPurchase="onClickPurchase" -->
                <tr v-if="inspectDataList.length == 0">
                  <td colspan="6" class="td_empty">검수정보가 없습니다</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- 상세 -->
      <PopInspectDetail
        v-else
        :SelectedInspectDetail="SelectedInspectDetail"
        :contractId="contract.contractId"
      />
      <!-- :checkedNames.sync="checkedNames" -->
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            닫기
          </button>
          <button
            v-if="isInspectDetail"
            type="button"
            class="btn_medium btn_secondary"
            @click="onClickBack"
          >
            이전
          </button>
          <button
            type="button"
            class="btn_medium btn_medium btn_primary"
            :disabled="isDisabledSeletedBtn"
            @click.prevent="onClickSelected"
          >
            선택
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PopInspectListLine from "./PopInspectListLine";
import PopInspectDetail from "./PopInspectDetail";

import ApiService from "@/services/ApiService";

export default {
  name: "PopInspect",
  components: {
    PopInspectListLine,
    PopInspectDetail,
  },
  props: {
    contractInfo: Object,
    contract: Object,
    checkedNames: Array,
  },
  data() {
    return {
      isInspectDetail: false,
      SelectedInspectDetail: {},

      checkAll: false,
      checkedNamesSync: [],

      inspectDataList: [],
    };
  },
  computed: {
    isDisabledSeletedBtn() {
      return this.checkedNamesSync.length === 0;
    },
  },
  // 임시
  watch: {
    checkedNamesSync() {
      this.getCheckAll();
    },
    checkAll(to, from) {
      // cheked on
      if (to && !from) {
        if (this.checkedNamesSync.length != this.inspectDataList.length) {
          this.checkedNamesSync = this.inspectDataList;
        }
      } else {
        if (this.checkedNamesSync.length == this.inspectDataList.length) {
          this.checkedNamesSync = [];
        }
      }
    },
  },
  mounted() {
    this.getInspectList();
    this.checkedNamesSync =
      this.checkedNames.map((item) => {
        let obj = {};
        obj.calculateDate = item.calculateDate || "";
        obj.calculateNum = item.calculateNum || null;
        obj.deliveryCompleteDate = item.deliveryCompleteDate || "";
        obj.description = item.description || "";
        obj.inspectCompleteDate = item.inspectCompleteDate || "";
        obj.inspectPrice = item.inspectPrice || "";
        obj.stateCode = item.stateCode || "";
        return obj;
      }) || [];
  },
  methods: {
    getCheckAll() {
      if (this.checkedNamesSync.length === this.inspectDataList.length) {
        this.checkAll = true;
      } else if (this.checkAll && this.checkedNamesSync.length != this.inspectDataList.length) {
        this.checkAll = false;
      }
    },
    onClickInspectDetail(rowData) {
      this.isInspectDetail = true;
      this.SelectedInspectDetail = rowData;
    },
    onClickBack() {
      this.isInspectDetail = false;
      this.SelectedInspectDetail = {};
    },
    onClickSelected() {
      this.$emit("onClickComplete", this.checkedNamesSync);
    },
    async getInspectList() {
      const requestCid = this.contractInfo.requestCid;

      const path = `${this.$apiPath.SETTLEMENT_CALCULATE}/${requestCid} `;

      const res = await ApiService.shared.getData(path);

      if (res.data) {
        this.inspectDataList = res.data;
        this.getCheckAll();
      }
    },
  },
};
</script>
