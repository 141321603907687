<template>
  <div class="popup_layer">
    <div class="inner_popup_layer" style="width: 464px">
      <div class="layer_head">
        <strong class="tit_popup">자산 검색</strong>
      </div>
      <div class="layer_body">
        <!-- 필터바 -->
        <div class="box_sorting">
          <strong class="screen_out">자산 검색하기</strong>
          <ul class="list_sorting">
            <li class="item_full">
              <!-- <em class="tit_txt">검색어</em> -->
              <div class="row_pack">
                <Input
                  style="width: 240px"
                  placeholder="검색어를 입력하세요"
                  :value.sync="searchForm.searchKeyword"
                  @keyup.enter="clickSearchButton"
                />
                <button
                  type="button"
                  class="btn_medium btn_primary"
                  @click.prevent="clickSearchButton"
                >
                  검색
                </button>
              </div>
            </li>
          </ul>
        </div>
        <!-- 법인카드 사용내역 리스트 -->
        <div class="tbl_comm tbl_list tbl_list_sm8 tbl_scroll">
          <div
            class="inner_scroll"
            style="height: calc(100vh - 544px); max-height: 294px; min-height: 49px"
          >
            <table>
              <colgroup>
                <col style="width: 52px" />
                <!-- 선택 -->
                <col />
                <!-- 자산번호 -->
                <col />
                <!-- 자산명 -->
              </colgroup>
              <thead>
                <tr>
                  <th style="width: 52px" />
                  <th style="width: 174px">자산번호</th>
                  <th style="width: 174px">자산명</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="assetsDataList.length < 1 && isSearch">
                  <td colspan="3">
                    <!--                    {{isSearch ? '조회된 자산코드가 없습니다' : ''}}-->
                    조회된 자산코드가 없습니다
                  </td>
                </tr>
                <tr
                  v-for="(item, index) in assetsDataList"
                  :key="index"
                  :class="{ tr_select: isSelected(item) }"
                >
                  <td>
                    <div class="box_choice type_alone">
                      <div class="item_choice">
                        <!-- disabled 상태시 disabled 클래스 추가 -->
                        <input
                          :id="'asset_type_' + item.assetNo"
                          v-model="selectedAssets"
                          type="radio"
                          class="inp_choice"
                          name="assetSubClassification"
                          :value="item"
                        />
                        <label class="lab_choice" :for="'asset_type_' + item.assetNo">
                          <span class="ico_account ico_rdo" />
                        </label>
                      </div>
                    </div>
                  </td>
                  <td class="td_ellip">
                    {{ item.assetNo }}
                  </td>
                  <td class="td_ellip">
                    {{ item.assetSubClassification }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="layer_foot">
        <div class="wrap_btn">
          <button
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="$emit('onClickClose')"
          >
            취소
          </button>
          <button
            type="button"
            class="btn_medium btn_secondary"
            :disabled="isDisabledMoveBtn"
            @click.prevent="onClickSelected"
          >
            선택
          </button>
          <!-- <button v-if="isBillSeleted" type="button" class="btn_medium btn_primary" :disabled="isDisabledSeletedBtn" @click.prevent="onClickSelected">선택</button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Input from "@/_approval/components/common/input/Input";
import ApiService from "@/services/ApiService";

export default {
  name: "PopAssets",
  components: {
    Input,
  },
  data() {
    return {
      searchForm: {
        searchKeyword: "",
      },
      assetsDataList: [],
      selectedAssets: {}, // 선택한 구분 데이터
      isSearch: false,
    };
  },
  computed: {
    isDisabledMoveBtn() {
      return !this.selectedAssets.assetNo;
    },
  },
  methods: {
    async clickSearchButton() {
      this.isSearch = false;
      // if(this.searchForm.searchKeyword === '') {
      //   const layer = {}
      //   layer.type = 'alert'
      //   layer.order = 2
      //   layer.content = '검색어를 입력해주세요'
      //   this._showLayer(layer);
      //
      //   return;
      // }

      let path = `${this.$apiPath.VOUCHER_ASSETS}?assetName=${this.searchForm.searchKeyword}`;

      const result = await ApiService.shared.getData(path);

      if (result.data) {
        this.assetsDataList = result.data;
        this.isSearch = true;
      }
    },
    isSelected(item) {
      return this.selectedAssets === item;
    },
    onClickSelected() {
      this.$emit("onClickComplete", this.selectedAssets);
    },
  },
};
</script>
